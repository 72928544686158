import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';

const StepNavigator = ({ currentStep, onStepChange }) => {
  const intl = useIntl();
  const steps = [
    { title: `${intl.formatMessage({ id: 'Checkin.Step.GuestsForm' })}`, className: '' },
    { title: `${intl.formatMessage({ id: 'Checkin.Step.AdditionalFields' })}`, className: '' },
    { title: `${intl.formatMessage({ id: 'Checkin.Step.ExtraServices' })}`, className: styles.hiddenStep },
    { title: `${intl.formatMessage({ id: 'Checkin.Step.LateCheckin' })}`, className: styles.hiddenStep },
    { title: `${intl.formatMessage({ id: 'Checkin.Step.WelcomeSignature' })}`, className: '' },
  ];

  return (
  <div className={styles.stepNavigator}>
    <Steps
      direction="vertical"
      current={currentStep}
      items={steps}
      onChange={onStepChange}
    />
  </div>
)};

StepNavigator.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onStepChange: PropTypes.func,
};

StepNavigator.defaultProps = {
  onStepChange: () => {},
};

export default StepNavigator;