import { gql } from '@apollo/client';

const campings = gql`
query GetCampingsOnPlaces($filters: [String], $zone: Int, $lang: I18NLocaleCode!) {
  campings (pagination: { limit: -1 }, filters: { ulysesId: { in: $filters}, region: {zone: {internalID: { eq: $zone}}}, showBookingEngine: { eq: true } }, locale: $lang) {
    data{
      id,
      attributes {
        ModalidadAgencia,
        Zona,
        campingName,
        ulysesId,
        fee,
        freeParking,
        kampaohService,
        labels,
        location,
        mainLabel,
        openFrom,
        openTo,
        order,
        propertyName,
        score,
        latitude,
        longitude,
        reviews,
        score,
        nature,
        party,
        relax,
        family,
        youtubeVideoId,
        timeLimitForBooking,
        confirmationUrl,
        featuredImage{
          data{
            id,
            attributes{
              url,
              formats
            }
          }
        },
        tents (pagination: { limit: -1 }){
          data{
            id,
            attributes{
              name,
              pets,
              roomTypeId,
            }
          }
        },
        photos (pagination: { limit: -1 }){
          data{
            id,
            attributes{
              name,
              url,
              ext,
              formats,
              hash
            }
          }
        },
        region{
          data{
            id,
            attributes{
              name,
              zone{
                data{
                  id,
                  attributes{
                    internalID,
                  }
                }
              }
            }
          }
        },
        features (pagination: { limit: -1 }){
          data{
            id,
            attributes{
              name,
              filterable,
              category,
              icon{
                data{
                  id,
                  attributes{
                    name,
                    ext,
                    hash,
                    url,
                  }
                }
              }
            }
          }
        },
        description{
          data{
            id,
            attributes{
              name,
              description,
            }
          }
        },
        campings_related{
          data{
            id,
            attributes{
              ulysesId,
              propertyName,
              campingName
            }
          }
        },
        condition{
          data{
            id,
            attributes{
              name,
              description,
              PDF{
                data{
                  attributes{
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default campings;
