import React from "react";
import PropTypes from "prop-types";
import { Select, Checkbox } from "antd";
import { useIntl } from "react-intl";
import styles from "../../index.module.scss";

const { Option } = Select;

const CancelReasonSelection = ({ selectedReason, termsAccepted, amount, dispatch, reasons }) => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <div className={styles.reasonContainer}>
        <span>{intl.formatMessage({ id: "Reservation.Cancel.Question" })}</span>
        <Select
          id="reason"
          placeholder={intl.formatMessage({ id: "Reservation.Cancel.SelectPlaceholder" })}
          onChange={(value) => dispatch({ type: "SET_SELECTED_REASON", payload: value })}
          className={styles.select}
          value={selectedReason}
        >
          {reasons?.map((reason) => (
            <Option key={reason?.id} value={reason?.id}>{reason?.name}</Option>
          ))}
        </Select>
      </div>

      <div className={styles.termsContainer}>
        <Checkbox checked={termsAccepted} onChange={(e) => dispatch({ type: "TOGGLE_TERMS_ACCEPTED", payload: e.target.checked })}>
          {intl.formatMessage({ id: "Reservation.Cancel.Checkbox" }, { amount })}
        </Checkbox>
      </div>
    </div>
  );
};

CancelReasonSelection.propTypes = {
  selectedReason: PropTypes.string.isRequired,
  termsAccepted: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CancelReasonSelection;