/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import PropTypes from 'prop-types';
import moment from 'moment';
import CalendarMonth from '../CalendarMonth';
import * as s from '../../index.module.scss';
import LoadMoreButton from '../LoadMoreButton';

const CalendarVirtualizedList = ({
  monthKeys,
  referenceRates,
  startDate,
  endDate,
  handleOnDayClick,
  messagesContainerRef,
  getClasses,
  loading,
  isLoadingReferenceRates,
  virtualizerHeight,
  virtualizerItemSize,
  currentLanguage,
  showLoadMore,
  handleLoadMoreMonths,
  selectedUnit,
}) => {
  const listRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);

  /**
   * Encuentra el índice del mes en el que se encuentra una fecha dada
   */
  const findMonthIndex = useCallback((date) => monthKeys.findIndex(month => moment(date).format('YYYY-MM') === month), [monthKeys]);

  /**
   * Realiza un scroll automático al mes correspondiente a la fecha dada
   */
  const scrollToMonth = useCallback((date) => {
    if (!listRef.current || !date) return;
    
    const monthIndex = findMonthIndex(date);
    if (monthIndex !== -1) {
      listRef.current.scrollToItem(monthIndex, 'center');
    }
  }, [findMonthIndex]);

  /**
   * Efecto: Al montar el componente, comprobar si `startDate` tiene disponibilidad.
   * - Si tiene disponibilidad, hace scroll hasta el mes de `startDate`
   * - Si no tiene, busca la primera fecha disponible después de `startDate` y hace scroll a ese mes.
   */
  useEffect(() => {
    if (!listRef.current || !startDate || !referenceRates.length || !endDate) return;

    const isStartDateAvailable = referenceRates.some(rate => rate.date === startDate);

    if (isStartDateAvailable) {
      scrollToMonth(startDate);
      
    } else {
      const firstAvailableDate = referenceRates
        .map(rate => moment(rate.date))
        .filter(date => date.isAfter(moment(startDate))) // Solo fechas futuras
        .sort((a, b) => a.diff(b))[0]; // Tomar la más cercana

      if (firstAvailableDate) {
        scrollToMonth(firstAvailableDate);
      }
    }
    // eslint-disable-next-line
  }, [startDate, referenceRates, scrollToMonth]);

  /**
   * Efecto: Detectar si el usuario está al final del scroll
   */
  useEffect(() => {
    const handleScroll = () => {
      if (!listRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = listRef.current._outerRef;
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
    };

    const container = listRef.current?._outerRef;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      <List
        ref={listRef}
        height={virtualizerHeight}
        itemCount={monthKeys.length}
        itemSize={virtualizerItemSize}
        width="100%"
        className={s.virtualizer}
      >
        {({ index, style }) => {
          const monthKey = monthKeys[index];
          return (
            <div style={style} key={monthKey}>
              <CalendarMonth
                monthKey={monthKey}
                currentLanguage={currentLanguage}
                referenceRates={referenceRates}
                startDate={startDate}
                endDate={endDate}
                handleOnDayClick={handleOnDayClick}
                messagesContainerRef={messagesContainerRef}
                getClasses={getClasses}
                loading={loading}
                isLoadingReferenceRates={isLoadingReferenceRates}
              />
            </div>
          );
        }}
      </List>
      
      {/* Mostrar botón solo si el usuario está al final del scroll */}
      {isAtBottom && showLoadMore && (
        <LoadMoreButton
          showLoadMore={showLoadMore}
          handleLoadMoreMonths={handleLoadMoreMonths}
          selectedUnit={selectedUnit}
        />
      )}
    </>
  );
};

CalendarVirtualizedList.propTypes = {
  monthKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  referenceRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      rateProductOccupancyId: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      amountAfterTax: PropTypes.number.isRequired,
      closed: PropTypes.bool.isRequired,
      minStay: PropTypes.number.isRequired,
      maxStay: PropTypes.number.isRequired,
      closedToArrival: PropTypes.bool.isRequired,
      closedToDeparture: PropTypes.bool.isRequired,
      percentDiscount: PropTypes.number,
      amountDiscount: PropTypes.number,
      quantityAvailable: PropTypes.number.isRequired,
      productId: PropTypes.number.isRequired,
      productName: PropTypes.string.isRequired,
      productCode: PropTypes.string.isRequired,
    })
  ).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  handleOnDayClick: PropTypes.func.isRequired,
  messagesContainerRef: PropTypes.shape({}).isRequired,
  getClasses: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isLoadingReferenceRates: PropTypes.bool.isRequired,
  virtualizerHeight: PropTypes.number.isRequired,
  virtualizerItemSize: PropTypes.number.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  showLoadMore: PropTypes.bool.isRequired,
  handleLoadMoreMonths: PropTypes.func.isRequired,
  selectedUnit: PropTypes.string.isRequired,
};

export default CalendarVirtualizedList;