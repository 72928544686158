/* eslint-disable max-len */
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import moment from 'moment';
import {
  wrapper,
  footer,
  footerNotDateFound,
} from './index.module.scss';
import CustomDrawer from '../CustomDrawer';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import UnitSelector from './components/UnitSelector';
import AvailabilityPanel from './components/AvailabilityPanel';
import AvailabilityContext, {
  AvailabilityProvider,
} from './contexts/AvailabilityContext';
import {
  useReservationDispatch,
  useReservationState,
} from '../../services/Reservation';
import {
  datesAreValid,
  peopleAreValid,
  reservationValidationErrorMessages,
} from '../../services/CheckValidReservation';
import calcDaysBetweenCheckInAndCheckOut from '../../utils/daysBetweenCheckInAndCheckOut';
import Services from '../../constants/services';
import ActionButton from '../ActionButton';
import DatePickerDrawer from '../DatePickerDrawer';
import { SearchContext } from '../../contexts/SearchContext';
import Status from '../../constants/status';
import useCampings from '../../services/Campings/useCampings';
import { usePrevious } from '../../services/usePrevious';

export const AvailabilitySelector = ({
  customButton,
  singleMode,
  formatOpenDate,
  formatEndDate,
  onClose,
  openFrom,
  onOk,
  detailsPage,
  unitObj,
}) => {
  const intl = useIntl();
  const {
    fetchingUnits,
    areAnyError,
    currentDates: { startDate, endDate },
    selectedUnit,
    selectedUnitRatePlan,
    checkedErrors,
    dispatch,
    availableUnits,
    filteredReferences,
    filteredRates,
    showCalendarUnavailable,
    startDateAvailable,
    setStartDateAvailable,
    endDateAvailable,
    setEndDateAvailable,
    isFirstRender
  } = useContext(AvailabilityContext);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const reservation = useReservationState();
  const dispatchReservation = useReservationDispatch();

  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(endDate),
    startDate: moment.utc(startDate),
  });

  const firstDayAvailable = useMemo(
    () => moment(filteredRates?.date),
    [filteredRates],
  );

  // eslint-disable-next-line
  const endDateFirstDayAvailable = useMemo(() => {
    return moment(firstDayAvailable).add(daysBetweenCheckInAndCheckOut, 'days');
  }, [firstDayAvailable, daysBetweenCheckInAndCheckOut]);

  const [referenceRates, setReferenceRates] = useState();

  const prevFirstDayAvailable = usePrevious(firstDayAvailable);
  const prevEndDateFirstDayAvailable = usePrevious(endDateFirstDayAvailable);

  useEffect(() => {
    if (
      !moment(prevFirstDayAvailable).isSame(firstDayAvailable) ||
      !moment(prevEndDateFirstDayAvailable).isSame(endDateFirstDayAvailable)
    ) {
      setStartDateAvailable(firstDayAvailable);
      setEndDateAvailable(endDateFirstDayAvailable);
    }
  }, [
    firstDayAvailable,
    endDateFirstDayAvailable,
    prevFirstDayAvailable,
    prevEndDateFirstDayAvailable,
    setStartDateAvailable,
    setEndDateAvailable,
  ]);

  useEffect(() => {
    if ((selectedUnit || unitObj) && filteredReferences) {
      setReferenceRates(filteredReferences);
    } else {
      setReferenceRates(filteredReferences);
    }
  }, [selectedUnit, unitObj, filteredReferences]);

  const getDatesBetween = (startDateString, endDateString) => {
    const newStartDate = moment(startDateString);
    const newEndDate = moment(endDateString);
    const datesBetween = [];
    const currentDate = newStartDate;
    while (currentDate.isSameOrBefore(newEndDate)) {
      datesBetween.push(currentDate.format('YYYY-MM-DD').toString());
      currentDate.add(1, 'day');
    }
    return datesBetween;
  };
  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  const updateQueryParams = useCallback(
    (key, value) => {
      searchParams.set(key, value);
      const url = new window.URL(window.location);
      const urlSearchParams = searchParams.toString();
      const currentParams = new URLSearchParams(url.search).toString();
      if (currentParams !== urlSearchParams) {
        updateUrlWithoutNavigation(
          `${url.pathname}?${searchParams.toString()}`,
        );
      }
    },
    [searchParams],
  );


  
  const datesBetween = getDatesBetween(
    isFirstRender ? startDate : startDateAvailable,
    isFirstRender ? endDate : endDateAvailable
  );
    
  const filteredReference = referenceRates?.filter((item) =>
    datesBetween.includes(item.date),
  );

  const startDateReference = filteredReference
    ? filteredReference?.find(
        (rate) =>
          rate.date ===
          moment(startDateAvailable).format('YYYY-MM-DD').toString(),
      )
    : filteredReference;
  const endDateReference = filteredReference
    ? filteredReference?.find(
        (rate) =>
          rate.date ===
          moment(endDateAvailable).format('YYYY-MM-DD').toString(),
      )
    : filteredReference;
  const referenceWithoutEndDate = filteredReference
    ? filteredReference?.filter(
        (rate) =>
          rate.date !==
          moment(endDateAvailable).format('YYYY-MM-DD').toString(),
      )
    : filteredReference;
  const areThereAvailableRooms = () =>
    referenceWithoutEndDate &&
    referenceWithoutEndDate?.every((element) => element.quantityAvailable > 0);
  const isClosedToArrival = () =>
    startDateReference && startDateReference?.closedToArrival === true;
  const isClosedToDeparture = () =>
    endDateReference && endDateReference.closedToArrival === true;
  const isClosedRate = () =>
    referenceWithoutEndDate &&
    referenceWithoutEndDate?.some((element) => element.closed === true);
  const amountIsGreaterThanZero = () =>
    referenceWithoutEndDate &&
    referenceWithoutEndDate?.every((element) => element.amountAfterTax > 0);
  const minNightsRoom = () =>
    filteredReference?.slice(0, -1)?.every(
      (element) => element.minStay <= filteredReference.length - 1,
    );

  const isDisabled =
    fetchingUnits ||
    !startDateAvailable ||
    !endDateAvailable ||
    areAnyError ||
    !checkedErrors ||
    availableUnits.length < 1 ||
    !filteredReference ||
    !areThereAvailableRooms() ||
    isClosedToArrival() ||
    isClosedToDeparture() ||
    isClosedRate() ||
    !amountIsGreaterThanZero() ||
    !minNightsRoom();

  return (
    <div className={wrapper}>
      <>
          {!detailsPage && <UnitSelector singleMode={singleMode} />}
          <AvailabilityPanel
            formatOpenDate={formatOpenDate}
            formatEndDate={formatEndDate}
            startDateAvailable={startDateAvailable}
            endDateAvailable={endDateAvailable}
            setStartDateAvailable={setStartDateAvailable}
            setEndDateAvailable={setEndDateAvailable}
            openFrom={openFrom}
          />
          <div
            className={!showCalendarUnavailable ? footer : footerNotDateFound}
          >
            <div>
              {
                (customButton ? (
                  customButton({
                    disabled: isDisabled,
                    currentDates: { startDate, endDate },
                    selectedUnitRatePlan,
                  })
                ) : (
                  <ActionButton
                    onClick={async () => {
                      if (
                        datesAreValid(reservation) &&
                        peopleAreValid(reservation) &&
                        !showCalendarUnavailable
                      ) {
                        dispatchReservation({ type: 'setItAsReadyToBeUsed' });
                        dispatchReservation({
                          type: 'setRatePlansAsNotFetched',
                        });
                        dispatch({ type: 'resetHotelsInitialState' });
                        updateQueryParams(
                          'checkin',
                          moment(startDateAvailable).format('YYYY-MM-DD'),
                        );
                        updateQueryParams(
                          'checkout',
                          moment(endDateAvailable).format('YYYY-MM-DD'),
                        );
                        dispatchReservation({
                          type: 'setStartDate',
                          payload: startDateAvailable,
                        });
                        dispatchReservation({
                          type: 'setEndDate',
                          payload: endDateAvailable,
                        });
                        // window.open(url, '_blank');
                        if (onOk) {
                          onOk({startDate: startDateAvailable, endDate: endDateAvailable});
                        }
                      } else {
                        console.log('error')
                        if (startDateAvailable && endDateAvailable ) {
                          dispatchReservation({ type: 'setItAsReadyToBeUsed' });
                          dispatchReservation({
                            type: 'setRatePlansAsNotFetched',
                          });
                          dispatch({ type: 'resetHotelsInitialState' });
                          updateQueryParams(
                            'checkin',
                            moment(startDateAvailable).format('YYYY-MM-DD'),
                          );
                          updateQueryParams(
                            'checkout',
                            moment(endDateAvailable).format('YYYY-MM-DD'),
                          );
                          dispatchReservation({
                            type: 'setStartDate',
                            payload: startDateAvailable,
                          });
                          dispatchReservation({
                            type: 'setEndDate',
                            payload: endDateAvailable,
                          });
                        } else {
                          reservationValidationErrorMessages(reservation).map(
                            (errorMessage) =>
                              message.error(
                                intl.formatMessage({
                                  id: errorMessage,
                                  defaultMessage:
                                    'Ha ocurrido algo con su reserva, revise los datos',
                                }),
                              ),
                          );
                        }
                        
                      }
                      if (showCalendarUnavailable) {
                        onClose();
                      }
                    }}
                    disabled={!showCalendarUnavailable ? isDisabled : false}
                    size="sm"
                  >

                    {!showCalendarUnavailable
                      ? 'OK'
                      : intl.formatMessage({ id: 'Card.Footer.Close' })}
                  </ActionButton>
                ))}
            </div>
          </div>
        </>
    </div>
  );
};

AvailabilitySelector.propTypes = {
  customButton: PropTypes.func,
  singleMode: PropTypes.bool,
  formatOpenDate: PropTypes.string.isRequired,
  formatEndDate: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  openFrom: PropTypes.instanceOf(moment).isRequired,
  onOk: PropTypes.func,
  detailsPage: PropTypes.bool,
  unitObj: PropTypes.oneOfType(),
};

AvailabilitySelector.defaultProps = {
  customButton: undefined,
  singleMode: false,
  onClose: () => {},
  onOk: () => {},
  detailsPage: false,
  unitObj: undefined,
};

const InnerDrawer = ({
  visible,
  onClose,
  service,
  customButton,
  singleMode,
  property,
  propertyID,
  onOk,
  detailsPage,
  unitObj
}) => {
  const intl = useIntl();
  const dispatch = useReservationDispatch();
  const { endDate, startDate } = useReservationState();

  const {
    reservationInitialDates: { startDate: _startDate, endDate: _endDate },
    getReferenceRate,
    setCurrentDates,
    setReservationInitialDates,
    setLoadingRatePlans,
    showMoreDatesDrawer,
    setShowMoreDatesDrawer,
    setRelaunchGetReference,
  } = useContext(AvailabilityContext);

  const {
    filteredStatus: { [propertyID]: status },
  } = useContext(SearchContext);

  const propertyId = property?.ulysesId;
  const {
    state: {
      campings: { [propertyId]: camping },
    },
  } = useCampings();

  
  const openFrom = moment(camping?.openFrom).format('YYYY-MM-DD');
  const openFromDate = moment(property.openFrom).add(2, 'days');
  const formatOpenDate = moment(property.openFrom).format('YYYY-MM-DD');
  const formatEndDate = openFromDate.format('YYYY-MM-DD');

  useEffect(() => {
    dispatch({ type: 'setItAsUpdatingAvailability', payload: true });
    return () =>
      dispatch({ type: 'setItAsUpdatingAvailability', payload: false });
  }, [dispatch]);

  return (
    <>
      <CustomDrawer
        visible={visible}
        height="100vh"
        title={intl.formatMessage({
          id: `#AvailabilityDrawer.camping.Title`,
        })}
        subTitle={intl.formatMessage({
          id: `#AvailabilityDrawer.camping.SubTitle`,
        })}
        icon={<CloseIcon />}
        onClose={onClose}
        mask
      >
        <AvailabilitySelector
          customButton={customButton}
          singleMode={singleMode}
          formatOpenDate={formatOpenDate}
          formatEndDate={formatEndDate}
          onClose={onClose}
          openFrom={openFrom}
          onOk={onOk}
          detailsPage={detailsPage}
          unitObj={unitObj}
        />
      </CustomDrawer>
      <DatePickerDrawer
        initialEndDate={
          status === Status.CLOSED ? moment(formatEndDate) : _endDate
        }
        initialStartDate={
          status === Status.CLOSED ? moment(formatOpenDate) : _startDate
        }
        visible={showMoreDatesDrawer}
        onClose={() => {
          if (startDate === null || endDate === null) {
            setCurrentDates({
              startDate:
                status === Status.CLOSED ? moment(formatEndDate) : _endDate,
              endDate:
                status === Status.CLOSED ? moment(formatOpenDate) : _startDate,
            });
          }

          setShowMoreDatesDrawer(false);
        }}
        onOk={(dates) => {
          setLoadingRatePlans(true);
          setReservationInitialDates(dates);
          setCurrentDates(dates);
          getReferenceRate(dates);
          setShowMoreDatesDrawer(false);
        }}
        setRelaunchGetReference={setRelaunchGetReference}
        openFrom={openFrom}
      />
    </>
  );
};

InnerDrawer.propTypes = {
  visible: PropTypes.bool,
  singleMode: PropTypes.bool,
  onClose: PropTypes.func,
  customButton: PropTypes.element,
  service: PropTypes.oneOf(Object.values(Services)).isRequired,
  property: PropTypes.objectOf(PropTypes.shape({ property: PropTypes.func }))
    .isRequired,
  propertyID: PropTypes.string,
  onOk: PropTypes.func,
  detailsPage: PropTypes.bool,
  unitObj: PropTypes.oneOfType(),
};

InnerDrawer.defaultProps = {
  visible: false,
  singleMode: false,
  onClose: () => {},
  customButton: undefined,
  propertyID: '',
  onOk: () => {},
  detailsPage: false,
  unitObj: undefined,
};

const AdvancedAvailabilityDrawer = ({
  visible,
  onClose,
  ratePlans,
  property,
  rateId,
  kampaohService: service,
  unitId,
  unitObj,
  singleMode,
  customButton,
  propertyID,
  onOk,
  detailsPage,
}) => {
  const dispatch = useReservationDispatch();
  const { kampaohService } = useReservationState();

  useEffect(() => {
    if (service !== kampaohService) {
      dispatch({ type: 'setKampaohService', payload: service });
    }
  }, [dispatch, kampaohService, service]);

  const { tentState: { tents } } = useCampings();

  
  const unit = Object.values(tents).find((tent) => parseInt(tent?.roomTypeId, 10) === parseInt(unitId, 10));


  return (
    <AvailabilityProvider
      property={property}
      ratePlans={ratePlans}
      rateId={rateId}
      unitId={unitId}
      unitObj={unitObj || unit}
      visibility={visible}
    >
      <InnerDrawer
        visible={visible}
        onClose={onClose}
        service={service}
        customButton={customButton}
        singleMode={singleMode}
        property={property}
        propertyID={propertyID}
        onOk={onOk}
        detailsPage={detailsPage}
        unitObj={unitObj || unit}
      />
    </AvailabilityProvider>
  );
};

AdvancedAvailabilityDrawer.propTypes = {
  visible: PropTypes.bool,
  singleMode: PropTypes.bool,
  onClose: PropTypes.func,
  ratePlans: PropTypes.objectOf(PropTypes.shape({ ratePlans: PropTypes.func })),
  property: PropTypes.objectOf(
    PropTypes.shape({
      property: PropTypes.func,
      openFrom: PropTypes.string,
      openTo: PropTypes.string,
    }),
  ).isRequired,
  rateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitId: PropTypes.string,
  unitObj: PropTypes.oneOfType(),
  kampaohService: PropTypes.oneOf(Object.values(Services)).isRequired,
  customButton: PropTypes.element,
  propertyID: PropTypes.string,
  onOk: PropTypes.func,
  detailsPage: PropTypes.bool,
};

AdvancedAvailabilityDrawer.defaultProps = {
  visible: false,
  singleMode: false,
  onClose: () => {},
  ratePlans: [],
  rateId: undefined,
  unitId: undefined,
  unitObj: undefined,
  customButton: undefined,
  propertyID: '',
  onOk: () => {},
  detailsPage: false,
};
export default AdvancedAvailabilityDrawer;
