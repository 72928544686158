/* eslint-disable max-len */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { navigate } from '@reach/router';
import {
  wrapper,
  body,
  dates,
  prices,
  total,
  notRatePlan,
  cancellationPolicy,
} from './index.module.scss';
import Services from '../../../../constants/services';
import UnitResumeTable from '../../../../components/UnitResumeTable';
// import { ReactComponent as ArrowIcon } from '../../../../assets/icons/left-arrow.svg';
// import { ReactComponent as LocationIcon } from '../../../../assets/icons/location.svg';
import validatePropertyRatePlansV2 from '../../../../services/validatePropertyRatePlansV2';
import {
  useReservationDispatch,
  useReservationState,
} from '../../../../services/Reservation';
import useCampings from '../../../../services/Campings/useCampings';
import Status from '../../../../constants/status';
import PersonSelectorDrawer from '../../../../components/PersonSelectorDrawer';
import calcDaysBetweenCheckInAndCheckOut from '../../../../utils/daysBetweenCheckInAndCheckOut';
import RatesSelector from '../../../../components/RatesSelector';
import AdvancedAvailabilityDrawer from '../../../../components/AdvancedAvailabilityDrawer';

const UnitSelector = ({
  reservation,
  propertyID,
  rateId,
  unitId,
  service,
  maxPax,
  filteredRatePlans,
  fpaFilter,
  setFpaFilter,
  setSelectedRate,
  selectedRate,
  setIsFirstLoad,
  petsAllowed,
}) => {
  const intl = useIntl();
  const { children, adults, babies, startDate, endDate } =
    useReservationState();
  const [showDatePickerDrawer, setShowDatePickerDrawer] = useState(false);
  const [showPersonSelectorDrawer, setShowPersonSelectorDrawer] =
    useState(false);
  const dispatch = useReservationDispatch();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpa = searchParams.get('fpa');
  const checkinFromURL = searchParams.get('checkin');

  const {
    state: { campings },
    // tentState: { tents: { [unitId]: tent } },
    askForOneCampingRatePlans,
  } = useCampings();
  // const isATent = service === Services.CAMP;
  const propertiesList = campings;
  const property = propertiesList[propertyID] ?? {};
  const askForRatePlans = askForOneCampingRatePlans;
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(endDate),
    startDate: moment.utc(startDate),
  });

  const getRatePlan = () => {
    const rates =
      property &&
      property.ratePlans &&
      property.ratePlans.filter((ratePlan) =>
        typeof rateId === 'string'
          ? `${ratePlan.rateID}` === rateId
          : `${ratePlan.roomTypeID}` === unitId,
      );
    if (rates && rates.length > 0) {
      const validatedRates =
        rates && validatePropertyRatePlansV2(rates, property);

      if (validatedRates.ratePlans.length === 0) {
        return undefined;
      }

      if (
        validatedRates.ratePlans.length === 1 &&
        validatedRates.status === Status.AVAILABLE
      ) {
        return validatedRates.ratePlans[0];
      }

      return (
        validatedRates.status === Status.AVAILABLE &&
        validatedRates.ratePlans.find((rate) => !rate.derivedType)
      );
    }
    return undefined;
  };

  const ratePlan = getRatePlan();

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };
  const updateQueryParams = () => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    params.set('fpa', fpaFilter);
    url.search = params.toString();
    updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
  };

  return (
    <>
      <div className={wrapper}>
        {!ratePlan && !startDate && !endDate && (
          <p className={notRatePlan}>
            <FormattedMessage id="UnitSelector.NoRatePlans" />
          </p>
        )}
        <div className={body}>
          <div className={dates}>
            <p>
              <FormattedMessage id="UnitSelector.Dates.Title" />
            </p>
            <UnitResumeTable
              data={reservation}
              type={service}
              onDatePickerClick={() => setShowDatePickerDrawer(true)}
              onPersonSelectorClick={() => setShowPersonSelectorDrawer(true)}
              fpa={fpa}
            />
          </div>
          <div className={cancellationPolicy}>
            <RatesSelector
              propertyID={propertyID}
              filteredRatePlans={filteredRatePlans}
              setSelectedRate={setSelectedRate}
              setIsFirstLoad={setIsFirstLoad}
              checkinFromURL={checkinFromURL}
            />
          </div>
          {ratePlan && (
            <div className={prices}>
              <p>
                <FormattedMessage id="UnitSelector.PricesDetails.Title" />
              </p>
              <div>
                <div>
                  <p>{`${intl.formatNumber(
                    (
                      selectedRate?.totalPrice / daysBetweenCheckInAndCheckOut
                    ).toFixed(2),
                    { style: 'currency', currency: 'EUR' },
                  )} x ${intl.formatMessage(
                    { id: 'UnitSelector.Nights' },
                    { count: daysBetweenCheckInAndCheckOut },
                  )}`}</p>
                  <p>{`${intl.formatNumber(selectedRate?.totalPrice, {
                    style: 'currency',
                    currency: 'EUR',
                  })}`}</p>
                </div>
                <hr />
                <div className={total}>
                  <p>
                    <FormattedMessage id="UnitSelector.Total.Text" />
                  </p>
                  <p>{`${intl.formatNumber(selectedRate?.totalPrice, {
                    style: 'currency',
                    currency: 'EUR',
                  })}`}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showDatePickerDrawer && (
        

        <AdvancedAvailabilityDrawer
          visible={showDatePickerDrawer}
          onClose={() => {
            setShowDatePickerDrawer(false);
          }}
          property={property}
          rateId={rateId}
          unitId={unitId}
          onOk={({ startDate: start, endDate: end }) => {
            if (start.isBefore(end)) {
              dispatch({ type: 'setStartDate', payload: start });
              dispatch({ type: 'setEndDate', payload: end });
              const path = window.location.href.split('?')[0];
              navigate(
                `${path}?checkin=${moment(start).format(
                  'YYYY-MM-DD',
                )}&checkout=${moment(end).format(
                  'YYYY-MM-DD',
                )}&adults=${adults}&children=${children}&babies=${babies}`,
              );
              askForRatePlans(
                { ...reservation, startDate: start, endDate: end },
                propertyID,
              );
              dispatch({ type: 'setItAsReadyToBeUsed' });
              setShowDatePickerDrawer(false);
            }
          }}
          
        />
      )}
      {showPersonSelectorDrawer && (
        <PersonSelectorDrawer
          initCountersValues={{ children, adults, babies }}
          maxPax={maxPax}
          visible={showPersonSelectorDrawer}
          onClose={() => {
            setShowPersonSelectorDrawer(false);
          }}
          onOk={(counter) => {
            const url = new URL(window.location);
            const params = new URLSearchParams(url.search);
            if (startDate && endDate) {
              Object.keys(counter).forEach((key) => {
                dispatch({ type: `set${key}`, payload: counter[key] });
                params.set('fpa', fpaFilter);
              });
              const path = window.location.href.split('?')[0];
              navigate(
                `${path}?checkin=${moment(startDate).format(
                  'YYYY-MM-DD',
                )}&checkout=${moment(endDate).format('YYYY-MM-DD')}&adults=${
                  counter.adults
                }&children=${counter.children}&babies=${
                  counter.babies
                }&fpa=${fpa}`,
              );
              askForRatePlans({ ...reservation, ...counter }, propertyID);
              dispatch({ type: 'setItAsReadyToBeUsed' });
            }
            setShowPersonSelectorDrawer(false);
            updateQueryParams();
          }}
          fpaFilter={fpaFilter}
          setFpaFilter={setFpaFilter}
          petsAllowed={petsAllowed}
        />
      )}
    </>
  );
};

UnitSelector.propTypes = {
  reservation: PropTypes.shape({
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
    adults: PropTypes.number,
    readyToBeUsed: PropTypes.bool,
  }).isRequired,
  propertyID: PropTypes.string.isRequired,
  rateId: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  unitId: PropTypes.string,
  service: PropTypes.oneOf(Object.values(Services)).isRequired,
  maxPax: PropTypes.number,
  filteredRatePlans: PropTypes.shape({}).isRequired,
  camping: PropTypes.shape({}).isRequired,
  fpaFilter: PropTypes.string,
  setFpaFilter: PropTypes.func,
  setSelectedRate: PropTypes.func,
  selectedRate: PropTypes.shape({
    totalPrice: PropTypes.number,
  }).isRequired,
  setIsFirstLoad: PropTypes.func,
  petsAllowed: PropTypes.bool,
};

UnitSelector.defaultProps = {
  unitId: undefined,
  maxPax: undefined,
  fpaFilter: 'none',
  setFpaFilter: () => {},
  setSelectedRate: () => {},
  setIsFirstLoad: () => {},
  petsAllowed: undefined,
};
export default UnitSelector;
