/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as s from './index.module.scss';
import HeaderNamesOfTheWeek from './components/HeaderNamesOfTheWeek';
import AvailabilityContext from '../../../../../../contexts/AvailabilityContext';
import { getClasses } from './utils/calendarClasses';
import groupDaysByMonth from './utils/groupDaysByMonth';
import useLoadMoreMonths from './hooks/useLoadMore';
import useCalendarRestrictions from './hooks/useCalendarRestrictions';
import useHandleDayClick from './hooks/useHandleDayClick';
import useCalendarState from './hooks/useCalendarState';
import CalendarVirtualizedList from './components/CalendarVirtualizedList';

const Calendar = ({
  loading,
  days,
  setEndDateAvailable,
  setStartDateAvailable,
  openFrom,
  startDateSelected,
  endDateSelected,
}) => {

  const {
    filteredReferences,
    getReferenceRate,
    isLoadingReferenceRates,
    selectedUnit,
    setIsFirstRender
  } = useContext(AvailabilityContext);
  const {
    messagesContainerRef,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startCalendarDate,
    setStartCalendarDate,
    daysArray,
    setDays,
    isSelectingStartDate,
    setIsSelectingStartDate,
    virtualizerHeight,
    virtualizerItemSize,
    referenceRates,
    currentLanguage,
  } = useCalendarState(startDateSelected, endDateSelected, days);

  const { loadMoreMonths, showLoadMore } = useLoadMoreMonths(getReferenceRate, filteredReferences);

  const applyRestrictions = useCalendarRestrictions({
    setStartDate,
    setStartDateAvailable,
    setEndDate,
    setEndDateAvailable,
    openFrom,
  });

  const handleOnDayClick = useHandleDayClick({
    startCalendarDate,
    setStartCalendarDate,
    setStartDate,
    setStartDateAvailable,
    setEndDate,
    setEndDateAvailable,
    startDate,
    endDate,
    applyRestrictions,
    isSelectingStartDate,
    setIsSelectingStartDate,
    setIsFirstRender
  });

  const handleLoadMoreMonths = () => {
    loadMoreMonths({ daysArray, setDays, endDateSelected });
  };

  const daysByMonth = groupDaysByMonth(daysArray);
  const monthKeys = Object.keys(daysByMonth).sort();
  return referenceRates ? (
    <>
      <div ref={messagesContainerRef} />
      <HeaderNamesOfTheWeek />
      <div className={s.allCalendars}>
        <CalendarVirtualizedList
          monthKeys={monthKeys}
          currentLanguage={currentLanguage}
          referenceRates={referenceRates}
          startDate={startDate}
          endDate={endDate}
          handleOnDayClick={handleOnDayClick}
          messagesContainerRef={messagesContainerRef}
          getClasses={getClasses}
          loading={loading}
          isLoadingReferenceRates={isLoadingReferenceRates}
          virtualizerHeight={virtualizerHeight}
          virtualizerItemSize={virtualizerItemSize}
          showLoadMore={showLoadMore}
          handleLoadMoreMonths={handleLoadMoreMonths}
          selectedUnit={selectedUnit}
        />

{/**
 * 
 {isAtBottom && showLoadMore && (
          <LoadMoreButton
            showLoadMore={showLoadMore}
            handleLoadMoreMonths={handleLoadMoreMonths}
          />
        )}
 */}
        

      </div>
    </>
  ) : null;
};

Calendar.propTypes = {
  loading: PropTypes.bool,
  days: PropTypes.arrayOf(
    PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
    })
  ),
  setEndDateAvailable: PropTypes.func.isRequired,
  setStartDateAvailable: PropTypes.func.isRequired,
  openFrom: PropTypes.instanceOf(moment).isRequired,
  startDateSelected: PropTypes.instanceOf(moment).isRequired,
  endDateSelected: PropTypes.instanceOf(moment).isRequired,
};

Calendar.defaultProps = {
  loading: false,
  days: [],
};

export default Calendar;