import React, { useContext, useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';
import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';
import ptPt from 'antd/es/locale/pt_PT';
import itIT from 'antd/es/locale/it_IT';
import frFR from 'antd/es/locale/fr_FR';
import { ApolloProvider } from '@apollo/client';
import moment from 'moment';
import Routes from './routes';
import './App.scss';
import { ReservationProvider } from './services/Reservation';
import { FilterProvider } from './services/Filter';
import LanguageProvider, {
  LanguageContext,
  localesWithFirstDay,
} from './locale/contexts/Language';
import CustomIntlProvider from './locale/contexts/CustomIntl';
import ConditionsProvider from './services/Conditions';
import TopBannerProvider from './services/TopBanner/Provider';
import { AuthProvider } from './services/Auth/DataProvider';
import { AuthCheckinProvider } from './services/CheckinAuth/DataProvider';
import { UserProvider } from './services/UserDetails/DataProvider';
import client from './services/ApolloClient';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/en-gb'; // This is for British English
import 'moment/locale/it';
import 'moment/locale/es';
import Maintenance from './components/Maintenance';
import useScript from './utils/useScript';

function App() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  // Check whether the maintenance mode is enabled
  // You can replace this with your logic, such as checking an environment variable.
  const checkMaintenanceMode = false;

  useEffect(() => {
    if (checkMaintenanceMode) {
      setIsMaintenanceMode(true);
    }
  }, [checkMaintenanceMode]);

  return (
    <>
      {isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <LanguageProvider>
          <ApolloProvider client={client}>
            <MidComponent />
          </ApolloProvider>
        </LanguageProvider>
      )}
    </>
  );
}

const languageMap = {
  it: itIT,
  es: esES,
  pt: ptPt,
  fr: frFR,
  en: enUS,
};

const MidComponent = () => {
  useScript(
    // eslint-disable-next-line no-underscore-dangle
    `${window._env_.REACT_APP_GOOGLE_CAPTCHA_ENDPOINT}?render=${window._env_.REACT_APP_GOOGLE_CAPTCHA_ID}`,
  );
  const { currentLanguage } = useContext(LanguageContext);

  const [languageSelected, setLanguageSelected] = useState(esES);

  useEffect(() => {
    const selectedLanguage = languageMap[currentLanguage] || languageMap.es;
    setLanguageSelected(selectedLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    async function setLocale() {
      try {
        // Set the locale and week configuration
        moment.locale(currentLanguage, {
          week: {
            dow: localesWithFirstDay[currentLanguage],
          },
        });
      } catch (error) {
        console.error(
          `Error loading Moment.js locale for ${currentLanguage}:`,
          error,
        );
      }
    }

    setLocale();
  }, [currentLanguage]);
  return (
    // eslint-disable-next-line no-underscore-dangle
    <GoogleOAuthProvider clientId={window._env_.REACT_APP_GOOGLE_CLIENTID}>
      <ConfigProvider locale={languageSelected}>
        <CustomIntlProvider>
          <AuthProvider>
            <AuthCheckinProvider>
              <UserProvider>
                <ConditionsProvider>
                  <ReservationProvider>
                    <TopBannerProvider>
                      <FilterProvider>
                        <Routes />
                      </FilterProvider>
                    </TopBannerProvider>
                  </ReservationProvider>
                </ConditionsProvider>
              </UserProvider>
            </AuthCheckinProvider>
          </AuthProvider>
        </CustomIntlProvider>
      </ConfigProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
