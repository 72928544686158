import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { getMainLocale } from '../translations';
import allowedLocales from '../../constants/locales';

const LANGUAGE = localStorage.getItem('countryCode'); // If the user has previously logged in to the application

const LanguageContext = React.createContext();

export const localesWithFirstDay = {
  fr: 1, // European locales start the week on Monday
  pt: 1, // European locales start the week on Monday
  en: 1, // European locales start the week on Monday (British English)
  it: 1, // European locales start the week on Monday
  es: 1, // European locales start the week on Monday
  // Add more locales as needed
};

const LanguageProvider = ({ children }) => {
  const navigatorLanguage =
    navigator.language?.substring(0, 2) ||
    navigator.userLanguage?.substring(0, 2);
  const { referrer } = document;
  const referrerFromKampaohLanding = allowedLocales?.find((locale) =>
    referrer?.includes(`${locale}.kampaoh`),
  );
  const searchParams = new URLSearchParams(window.location.search);
  const languageFromURL = searchParams.get('lang')?.substring(0, 2);
  const [currentLanguage, setCurrentLanguage] = useState(
    languageFromURL ||
      referrerFromKampaohLanding ||
      LANGUAGE ||
      navigatorLanguage,
  );
  /* eslint-disable */
  useEffect(() => {
    if (languageFromURL) {
      setCurrentLanguage(languageFromURL);
    } else if (
      referrerFromKampaohLanding &&
      referrerFromKampaohLanding !== currentLanguage
    ) {
      setCurrentLanguage(referrerFromKampaohLanding);
    } else if (LANGUAGE && getMainLocale(LANGUAGE) !== currentLanguage) {
      setCurrentLanguage(getMainLocale(LANGUAGE));
    } else if (
      navigatorLanguage &&
      getMainLocale(navigatorLanguage) !== currentLanguage
    ) {
      setCurrentLanguage(getMainLocale(navigatorLanguage));
    }
  }, [
    languageFromURL,
    referrerFromKampaohLanding,
    LANGUAGE,
    navigatorLanguage,
  ]);
  /* eslint-enable */

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        setCurrentLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export { LanguageContext };
export default LanguageProvider;
