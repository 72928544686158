/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layout } from 'antd';
import { layout, container, content } from './index.module.scss';

const { Content } = Layout;

const CheckinBooks = (Component) =>
  ({ ...props }) => (
  <Layout className={layout}>
    <div className={container}>
      <Content className={content}>
        <Component {...props} />
      </Content>
    </div>
  </Layout>
);

export default CheckinBooks;