import moment from 'moment';
import { defaultTimeLimitForTodaysBooking } from '../../../../../../../../../constants/config';

/**
 * Calcula la hora límite (p.ej. "14:00") y regresa un objeto moment()
 */
const calcTimeLimitForTodaysBooking = () => {
  const time = defaultTimeLimitForTodaysBooking;
  const [hh, mm] = time.split(':');
  return moment().tz('Europe/Madrid').hour(hh).minute(mm);
};

/**
 * Retorna `true` si aún NO se ha alcanzado la hora límite para hoy
 */
export const hasReachedTimeLimit = () => {
  const duration = moment.duration(calcTimeLimitForTodaysBooking().diff(moment()));
  return duration.asHours() > 0;
};

/**
 * Corta un array en “trozos” (filas) de tamaño "size"
 */
export function chunkArray(array, size = 7) {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

/**
 * Genera placeholders (null) para “rellenar” la rejilla
 */
export function createPlaceholders(length) {
  return Array.from({ length }, () => null);
}

// Constantes para virtualización
export const VIRTUALIZER_HEIGHT_XS_MOBILE = 375;  // Móviles muy pequeños (ej: iPhone SE)
export const VIRTUALIZER_SIZE_XS_MOBILE = 325;

export const VIRTUALIZER_HEIGHT_MOBILE = 365;  // Móviles estándar (ej: iPhone, Android medianos)
export const VIRTUALIZER_SIZE_MOBILE = 490;

export const VIRTUALIZER_HEIGHT_LG_MOBILE = 475;  // Móviles grandes (ej: iPhone Pro Max, Galaxy S Ultra)
export const VIRTUALIZER_SIZE_LG_MOBILE = 550;

export const VIRTUALIZER_HEIGHT_SM_TABLET = 525;  // Tablets pequeñas (ej: iPad Mini)
export const VIRTUALIZER_SIZE_SM_TABLET = 550;

export const VIRTUALIZER_HEIGHT_LG_TABLET = 600;  // Tablets grandes (ej: iPad Pro 11”)
export const VIRTUALIZER_SIZE_LG_TABLET = 500;

export const VIRTUALIZER_HEIGHT_LAPTOP = 675;  // Laptops pequeñas (ej: MacBook Air 13”)
export const VIRTUALIZER_SIZE_LAPTOP = 450;

export const VIRTUALIZER_HEIGHT_DESKTOP = 750;  // Escritorio estándar (ej: 1080p)
export const VIRTUALIZER_SIZE_DESKTOP = 500;

export const VIRTUALIZER_HEIGHT_LG_DESKTOP = 850;  // Monitores grandes (ej: 1440p)
export const VIRTUALIZER_SIZE_LG_DESKTOP = 550;

/**
 * Construye semanas (array de objetos { days: [...] }) desde un rango de fechas.
 */
export function buildWeeksFromRange(start, end) {
  const daysList = [];
  const cursor = start.clone();
  while (cursor.isSameOrBefore(end, 'day')) {
    daysList.push(cursor.clone());
    cursor.add(1, 'day');
  }
  const result = [];
  for (let i = 0; i < daysList.length; i += 7) {
    result.push({ days: daysList.slice(i, i + 7) });
  }
  return result;
}

/**
 * Verifica si un rango de fechas es válido basado en tarifas disponibles.
 */
export const isDateRangeValid = (startDay, endDay, referenceRates) => {
  if (!referenceRates) return false;
  const currentDate = startDay.clone();
  while (!currentDate.isAfter(endDay, 'day')) {
    const plan = referenceRates.find((r) =>
      moment(r.date).isSame(currentDate, 'day')
    );
    if (
      !plan ||
      plan.quantityAvailable <= 0 ||
      plan.amountAfterTax <= 0 ||
      plan.closed ||
      plan.closedToArrival ||
      plan.closedToDeparture
    ) {
      return false;
    }
    currentDate.add(1, 'day');
  }
  return true;
};