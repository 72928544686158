import React, { useState } from 'react';
import { useLocation, navigate } from '@reach/router';
import moment from 'moment';
import { Spin } from 'antd';
import CheckinForm from './components/CheckinForm';
import styles from './index.module.scss';
import ROUTES from '../../routes/routes';
import makeRequestToApCheckinServer from '../../services/ApServer/askCheckinToApServer';
import { allowedMethods } from '../../constants/ApServer/apServer';
import { updateCheckinToken } from '../../utils/tokenHelper';
import openNotification from '../../components/NotificacionMessage';
import routes from '../../constants/ApServer/apRoutes';

const validateReservationData = (locator, checkin, checkout) => {
  if (!locator || !checkin || !checkout || !moment(checkout).isAfter(moment(checkin))) {
    return false;
  }
  return true;
};

const parseDate = (dateString) => {
  if (!dateString) return null;

  // Intentar con YYYY-MM-DD
  let parsedDate = moment(dateString, 'YYYY-MM-DD', true);
  
  // Si no es válido, intentar con DD-MM-YYYY
  if (!parsedDate.isValid()) {
    parsedDate = moment(dateString, 'DD-MM-YYYY', true);
  }

  return parsedDate.isValid() ? parsedDate : null;
};

const AuthPage = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [requestMade, setRequestMade] = useState(false);

  // Obtener parámetros de la URL
  const searchParams = new URLSearchParams(search);
  const locator = searchParams.get('locator') || null;
  const checkin = parseDate(searchParams.get('checkin'));
  const checkout = parseDate(searchParams.get('checkout'));
  const checkinParam = searchParams.get('checkinOnline') || false;

  const initialValues = { locator, checkin, checkout };

  const handleRequest = (values) => {
    setLoading(true);
    makeRequestToApCheckinServer(
      allowedMethods.get,
      `${routes.bookings}`,
      values,
      (response) => {
        setLoading(false);
        const { data } = response;
        updateCheckinToken(data);
        if(checkinParam === 'true') {
          navigate(
            `${ROUTES.bookingsCheckin}?locator=${values.locator}&checkin=${values.checkin}&checkout=${values.checkout}`
          );
        } else {
          navigate(
            `${ROUTES.bookingsLocator}?locator=${values.locator}&checkin=${values.checkin}&checkout=${values.checkout}`
          );
        }
      },
      (err) => {
        setLoading(false);
        openNotification(
          'error',
          'Error',
          err?.response?.data?.message,
          () => {},
          null
        );
      }
    );
  };

  if (!requestMade && locator && checkin && checkout) {
    const isValid = validateReservationData(locator, checkin, checkout);

    if (isValid) {
      setRequestMade(true);
      handleRequest({
        locator,
        checkin: checkin.format('YYYY-MM-DD'),
        checkout: checkout.format('YYYY-MM-DD'),
      });
    } else {
      openNotification(
        'error',
        'Datos Inválidos',
        'Los parámetros de la URL no son válidos. Por favor, verifica e intenta de nuevo.',
        () => {},
        null
      );
    }
  }

  const handleFinish = (values) => {
    const isValid = validateReservationData(
      values.locator,
      values.dateRange[0],
      values.dateRange[1]
    );

    if (isValid) {
      const valuesData = {
        locator: values.locator,
        checkin: values.dateRange[0].format('YYYY-MM-DD'),
        checkout: values.dateRange[1].format('YYYY-MM-DD'),
      };
      handleRequest(valuesData);
    } else {
      openNotification(
        'error',
        'Error',
        'Los datos de la reserva no son válidos. Por favor, verifica e intenta de nuevo.',
        () => {},
        null
      );
    }
  };

  return (
    <div className={styles.layout}>
      {loading && (
        <div className={styles.fullscreenSpinner}>
          <Spin size="large" />
        </div>
      )}
      <CheckinForm initialValues={initialValues} onSubmit={handleFinish} />
    </div>
  );
};

export default AuthPage;

