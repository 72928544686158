import React from "react";
import PropTypes from "prop-types";
import { Button, Spin } from "antd";
import { useIntl } from "react-intl";
import styles from "../../index.module.scss";

const RefundableCancelButton = ({ showInfo, handleCancelReservation, isCancelling, showCodeModal, selectedReason, termsAccepted, dispatch }) => {
  const intl = useIntl();

  return (
    <Button
      className={!showInfo ? styles.cancelButton : styles.showCancelButton}
      disabled={!showInfo ? (!selectedReason || !termsAccepted || isCancelling) : false}
      onClick={() => {
        if (showInfo) {
          dispatch({ type: "TOGGLE_SHOW_INFO", payload: false })
        } else {
          handleCancelReservation();
        }
      }}
      style={{ display: showCodeModal ? "none" : "inline-block" }}
    >
      {isCancelling ? <Spin size="small" /> : intl.formatMessage({ id: 'Reservation.Cancel.Button.Finish' })}
    </Button>
  );
};

RefundableCancelButton.propTypes = {
  showInfo: PropTypes.bool.isRequired,
  handleCancelReservation: PropTypes.func.isRequired,
  isCancelling: PropTypes.bool.isRequired,
  showCodeModal: PropTypes.bool.isRequired,
  selectedReason: PropTypes.string.isRequired,
  termsAccepted: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default RefundableCancelButton;