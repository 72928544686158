/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import Status from '../../../../constants/status';
import { SearchContext } from '../../../../contexts/SearchContext';
import { LanguageContext } from '../../../../locale/contexts/Language';
import makeRequestToServer, {
  makeRequestToServerV3,
} from '../../../../services/API';
import useCampings from '../../../../services/Campings/useCampings';
import { useReservationState } from '../../../../services/Reservation';
import calcDaysBetweenCheckInAndCheckOut from '../../../../utils/daysBetweenCheckInAndCheckOut';

const AvailabilityContext = React.createContext({});

const AvailabilityProvider = ({ children, property, unitId, unitObj }) => {
  const initialCharge = useRef(false);
  const [ratePlans, setRatePlans] = useState([]);
  const { currentLanguage } = useContext(LanguageContext);
  const {
    filteredStatus: { [property?.ulysesId]: status },
    campings: { [property?.ulysesId]: camping },
  } = useContext(SearchContext);
  const {
    tentState: { tents },
    dispatch: dispatchCampings,
  } = useCampings();
  const [areAnyError, setAreAnyError] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(unitObj || null);
  const [checkedErrors, setCheckedErrors] = useState(false);
  const [filteredReferences, setFilteredReferences] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]);
  const { startDate, endDate } = useReservationState();
  const [reservationInitialDates, setReservationInitialDates] = useState({
    startDate,
    endDate,
  });
  const [loadingRatePlans, setLoadingRatePlans] = useState(false);
  const reservation = useReservationState();
  const [currentDates, setCurrentDates] = useState({ startDate, endDate });
  const [error, setError] = useState(null);
  const [showMoreDatesDrawer, setShowMoreDatesDrawer] = useState(false);
  const units = tents;
  const dispatch = dispatchCampings;
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(currentDates.endDate),
    startDate: moment.utc(currentDates.startDate),
  });
  const openFrom = camping?.openFrom;
  const isCampingClosed = status === Status.CLOSED;
  const [additionalRequestsCount, setAdditionalRequestsCount] = useState(0);
  const [showCalendarUnavailable, setShowCalendarUnavailable] = useState(false);
  const [relaunchGetReference, setRelaunchGetReference] = useState(true);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [isLoadingReferenceRates, setIsLoadingReferenceRates] = useState(true);
  const maxCallGetReference = 4;
  const maxCallDays = 90;
  const maxLoop = 3;

  // ------------------------------------
  // Ref para no repetir la misma petición NUNCA
  // ------------------------------------
  const requestsCacheRef = useRef(new Set());
  const [startDateAvailable, setStartDateAvailable] = useState();
  const [endDateAvailable, setEndDateAvailable] = useState();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const availableUnits = useMemo(() => {
    if (camping && camping.tents && camping.tents > 0) {
      return camping.tents
        .map(({ roomTypeId }) => ({
          ...units[roomTypeId],
        }))
        .filter((unit) => unit.id);
    }
    if (tents && Object.keys(tents).length > 0) {
      return Object.values(tents)
        .map(({ roomTypeId }) => ({
          ...units[roomTypeId],
        }))
        .filter((unit) => unit.roomTypeId);
    }
    return [];
  }, [tents, units, camping]);

  const selectedUnitRatePlan = useMemo(() => {
    if (filteredReferences && filteredReferences.length > 0) {
      if (selectedUnit) {
        setSelectedUnit(selectedUnit);
        return filteredReferences.find(
          (rate) => rate.productId === parseInt(selectedUnit.roomTypeId, 10),
        );
      }
    }
    return null;
  }, [filteredReferences, selectedUnit]);

  const getDays = useCallback(
    ({ startDate: start, endDate: end } = { startDate, endDate }) => {
      const calendar = [];
      const startDay = start?.clone()?.subtract(1, 'weeks').startOf('week');
      const endDay = end?.clone()?.add(1, 'weeks').endOf('week');

      const date = startDay?.clone()?.subtract(1, 'day');

      while (date?.isBefore(endDay, 'day')) {
        calendar.push({
          days: Array(7)
            .fill(0)
            ?.map(() => date?.add(1, 'day')?.clone()),
        });
      }
      return calendar;
    },
    [startDate, endDate],
  );

  const haveDatesChanged = async (
    currentStartDate,
    currentEndDate,
    newStart,
    newEnd,
  ) => {
    if (currentStartDate !== newStart && currentEndDate !== newEnd) return true;
    return false;
  };

  const askForOnePropertyRatePlan = useCallback(
    async ({ startDate: start, endDate: end } = { startDate, endDate }) => {
      const dates = getDays({ startDate: start, endDate: end });
      const { adults, children: boys } = reservation;
      const findFirstValidDate = () =>
        dates.reduce((acc, { days }) => {
          const foundDay =
            days && days?.find((d) => d.isSameOrAfter(moment(), 'day'));
          if (Object.keys(acc).length === 0 && foundDay) {
            return foundDay;
          }
          return acc;
        }, {});
      const findLastValidDate = () =>
        dates[dates.length - 1]?.days[
          dates[dates.length - 1]?.days?.length - 1
        ];

      const datesChanged = await haveDatesChanged(
        startDate?.format('YYYY-MM-DD'),
        endDate?.format('YYYY-MM-DD'),
        start?.format('YYYY-MM-DD'),
        end?.format('YYYY-MM-DD'),
      );

      if (datesChanged) {
        const { data } = await makeRequestToServerV3(
          `/properties/${
            property.ulysesId
          }/rooms/boards/plans?startDate=${moment(findFirstValidDate()).format(
            'YYYY-MM-DD',
          )}&endDate=${moment(findLastValidDate().clone().add(1, 'day')).format(
            'YYYY-MM-DD',
          )}&adults=${adults}&children=${boys}&lang=${currentLanguage}`,
        );
        setRatePlans(data);
        setLoadingRatePlans(false);
        initialCharge.current = true;
      } else {
        const { data } = await makeRequestToServerV3(
          `/properties/${property.ulysesId}/rooms/boards/plans?startDate=${
            isCampingClosed
              ? moment(openFrom).format('YYYY-MM-DD')
              : moment(findFirstValidDate()).format('YYYY-MM-DD')
          }&endDate=${
            isCampingClosed
              ? moment(openFrom).add(2, 'day').format('YYYY-MM-DD')
              : moment(findLastValidDate().clone().add(1, 'day')).format(
                  'YYYY-MM-DD',
                )
          }&adults=${adults}&children=${boys}&lang=${currentLanguage}`,
        );
        setRatePlans(data);
        setLoadingRatePlans(false);
        initialCharge.current = true;
      }
    },
    [
      reservation,
      property,
      currentLanguage,
      getDays,
      startDate,
      endDate,
      openFrom,
      isCampingClosed,
    ],
  );

  const createDatesArray = useCallback(async (startDateParam, endDateParam) => {
    const datesArray = [];
    const currentDate = moment(startDateParam);
    const lastDate = moment(endDateParam);

    while (currentDate <= lastDate) {
      datesArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }

    return datesArray;
  }, []);

  const getReferenceRate = useCallback(
    async ({ startDate: _start, endDate: _end } = {}, selectedRoom) => {
      
      setIsLoadingReferenceRates(true);
  
      const effectiveStart = moment().startOf('day'); // Siempre hoy
  
      // Función para calcular finalEnd con bloques de 2 meses, siempre el primer día del mes
      const computeFinalEnd = (currentStart) => currentStart.clone().add(2, 'months').startOf('month');
  
      const finalEnd = _end
        ? moment(_end).startOf('month') // Si _end es proporcionado, usamos su mes de inicio
        : computeFinalEnd(effectiveStart);
  
      let hasAggregatedRates = false;
  
      // Función recursiva para acumular tarifas en bloques de 2 meses
      const fetchRatesRecursively = async (currentStart, aggregatedRates = []) => {
        if (!currentStart.isBefore(finalEnd, 'day')) {
          return aggregatedRates;
        }
  
        const currentEnd = computeFinalEnd(currentStart); // Siempre el primer día del mes en 2 meses

          // C) Construimos la clave con nextCount
        const requestKey = `start:${currentStart.format(
          'YYYY-MM-DD',
        )};end:${currentEnd.format('YYYY-MM-DD')};room:${
              selectedRoom || (selectedUnit && selectedUnit.roomTypeId)
                ? `&productID=${selectedRoom || selectedUnit.roomTypeId}`
                : ''
            }`;

        if (requestsCacheRef.current.has(requestKey)) {
          return fetchRatesRecursively(currentEnd.clone(), aggregatedRates);
        }

        // Agregamos la clave y lanzamos la petición
        requestsCacheRef.current.add(requestKey);
  
        const response = await makeRequestToServer(
          `/getReferenceRate?propertyID=${property.ulysesId}` +
            `&startDate=${currentStart.format('YYYY-MM-DD')}` +
            `&endDate=${currentEnd.format('YYYY-MM-DD')}` +
            `&adults=${reservation.adults}` +
            `&children=${reservation.children}` +
            `${
              selectedRoom || (selectedUnit && selectedUnit.roomTypeId)
                ? `&productID=${selectedRoom || selectedUnit.roomTypeId}`
                : ''
            }`
        );
  
        let filteredRate;
        if (selectedRoom || (selectedUnit && selectedUnit.roomTypeId)) {
          filteredRate =
            response[0]?.productOccupancySet[0]?.rateProductOccupancySet[0]
              ?.rateProductOccupancyAvailabilitySet.filter(
                (rate) =>
                  !rate.closed &&
                  !rate.closedToArrival &&
                  !rate.closedToDeparture &&
                  rate.quantityAvailable > 0 &&
                  rate.amountAfterTax > 0
              );
        } else {
          filteredRate = response
            .flatMap((product) =>
              product.productOccupancySet.flatMap((occupancy) =>
                occupancy.rateProductOccupancySet.flatMap((rate) =>
                  rate.rateProductOccupancyAvailabilitySet.map((rateItem) => ({
                    ...rateItem,
                    productId: product.productId,
                    productName: product.productName,
                    productCode: product.chainProductCode,
                  }))
                )
              )
            )
            .filter(
              (rate) =>
                !rate.closed &&
                !rate.closedToArrival &&
                !rate.closedToDeparture &&
                rate.quantityAvailable > 0 &&
                rate.amountAfterTax > 0
            )
            .reduce((acc, rate) => {
              const existing = acc.find((r) => r.date === rate.date);
              if (!existing) {
                acc.push(rate);
              } else if (
                rate.amountAfterTax < existing.amountAfterTax ||
                (rate.amountAfterTax === existing.amountAfterTax &&
                  rate.minStay < existing.minStay)
              ) {
                const index = acc.indexOf(existing);
                acc[index] = rate;
              }
              return acc;
            }, []);
        }
  
        const sortedRate = filteredRate?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
  
        const lastDateInResponse = sortedRate?.length && sortedRate?.length > 7
          ? moment(sortedRate[sortedRate.length - 1].date)
          : currentStart.clone().add(2, 'months').startOf('month');
  
        aggregatedRates = aggregatedRates.concat(sortedRate || []);
        

        // Comprobar array de longitud 7 para que mínimo contenga 7 días disponibles o volvre a pedir
        if (!lastDateInResponse.isBefore(finalEnd, 'day') && sortedRate?.length > 7) {
          return aggregatedRates;
        }
  
        return fetchRatesRecursively(lastDateInResponse.clone(), aggregatedRates);
      };
  
      try {
        const aggregatedRates = await fetchRatesRecursively(effectiveStart, []);
  
        setFilteredReferences((prev) => {
          const merged = [...prev, ...aggregatedRates];
          const map = new Map();
          merged.forEach((item) => map.set(item.date, item));
          hasAggregatedRates = [...map.values()].length > 0;
          return [...map.values()];
        });
  
        if (aggregatedRates.length > 0) {
          const earliestTimestamp = Math.min(
            ...aggregatedRates.map((rate) => moment(rate.date).valueOf())
          );
          const firstDateInResponse = moment(earliestTimestamp);
          setStartDateAvailable(firstDateInResponse);
          setShowCalendarUnavailable(false);
        }
      } catch (error2) {
        console.error('Error fetching rates:', error2);
      } finally {
        if (!hasAggregatedRates) {
          setShowCalendarUnavailable(true);
        }
        if (filteredReferences.length > 0) {
          setIsLoadingReferenceRates(false);
        }
      }
  
      initialCharge.current = true;
    },
    [property, reservation, selectedUnit, filteredReferences.length]
  );

  
  useEffect(() => {
    if (error && !areAnyError) {
      setAreAnyError(true);
    } else if (!error) {
      setAreAnyError(false);
    }
  }, [error, areAnyError]);

   // ------------------------------------
  // Efecto: al cambiar filteredReferences, evaluamos si relanzar peticiones
  // ------------------------------------
  useEffect(() => {
    // ¿Hay tarifas disponibles?
    const ratesAvailable = filteredReferences.filter(
      (r) =>
        r.closed === false &&
        r.closedToArrival === false &&
        r.quantityAvailable > 0,
    );
    const rateAvailable = filteredReferences.find(
      (r) =>
        r.closed === false &&
        r.closedToArrival === false &&
        r.quantityAvailable > 0,
    );

    // 1) Tenemos tarifa y seguimos "relanzando"
    if (rateAvailable && ratesAvailable.length > 7 && relaunchGetReference) {
      setFilteredRates(rateAvailable);
      setIsLoadingPlans(false);
      return;
    }

    // 2) Si paramos de relanzar, tomamos la que coincida con la fecha de start
    if (!relaunchGetReference) {
      const matchingRate = filteredReferences.find(
        (r) => r.date === currentDates.startDate.format('YYYY-MM-DD'),
      );
      setFilteredRates(matchingRate || null);
      setIsLoadingPlans(false);
      return;
    }

    // 3) No hay tarifa y relaunchGetReference sigue true => pedimos más
    if (additionalRequestsCount < maxCallGetReference) {
      // A) Calculamos la cuenta local ANTES de setear el estado
      const nextCount = additionalRequestsCount + 1;

      // B) Armamos las fechas con nextCount
      const newStartDate = moment(startDate).add(maxCallDays * nextCount, 'days');
      const newEndDate = moment(endDate).add(maxCallDays * nextCount, 'days');

      // C) Construimos la clave con nextCount
      const requestKey = `start:${newStartDate.format(
        'YYYY-MM-DD',
      )};end:${newEndDate.format('YYYY-MM-DD')};room:${unitId}`;

      if (requestsCacheRef.current.has(requestKey)) {
        return;
      }

      // Agregamos la clave y lanzamos la petición
      requestsCacheRef.current.add(requestKey);
      setIsLoadingPlans(true);
      
      getReferenceRate(
        {
          startDate: newStartDate,
          endDate: newEndDate,
        },
        parseInt(unitId, 10),
      );

      // D) Recién ahora seteamos additionalRequestsCount
      setAdditionalRequestsCount(nextCount);
    }

    // 4) Si superamos maxLoop, paramos
    if (additionalRequestsCount > maxLoop) {
      setIsLoadingPlans(false);
    }
    // eslint-disable-next-line
  }, [filteredReferences]);

  useEffect(() => {
    if (endDate === null) {
      return;
    }
    const newStartDate = moment(property?.openFrom);
    const numberNights = endDate.diff(startDate, 'days');
    const newEndDate = newStartDate.clone().add(numberNights, 'days');
    const formattedNewEndDate = moment(newEndDate.format('YYYY-MM-DD'));
    const fetchData = async () => {
      try {
        if (filteredReferences) {
          
          /* In other words: there are not invalid dates beetween start and end dates */
          const isDateRangeValid = (startDay, endDay) => {
            let currentDate = startDay.clone();
            let ocurrency = false;
            while (
              !currentDate.isSame(endDay) &&
              currentDate.isBefore(endDay.clone().add(1, 'day'))
            ) {
              /* eslint-disable */
              const roomRateDetail =
                selectedUnitRatePlan?.roomRateDetailed?.find(
                  (rate) => rate.date === currentDate.format('YYYY-MM-DD'),
                );
              /* eslint-enable */
              if (roomRateDetail && roomRateDetail.roomsAvailable < 1) {
                ocurrency = true;
              }
              currentDate = currentDate.clone().add(1, 'days');
            }
            return !ocurrency;
          };
          const { startDate: startDay, endDate: endDay } = currentDates;
          if (startDay && endDay) {   
            setAreAnyError(false);
            let startRoomRateDetail;
            let endRoomRateDetail;
            if (isCampingClosed) {
              startRoomRateDetail = filteredReferences.find(
                (rate) => rate.date === moment(openFrom).format('YYYY-MM-DD'),
              );
              endRoomRateDetail = filteredReferences.find(
                (rate) =>
                  rate.date ===
                  moment(openFrom).add(14, 'days').format('YYYY-MM-DD'),
              );
            } else {
              startRoomRateDetail = filteredReferences.find(
                (rate) => rate.date === startDay.format('YYYY-MM-DD'),
              );
              endRoomRateDetail = filteredReferences.find(
                (rate) => rate.date === endDay.format('YYYY-MM-DD'),
              );
            }

            let startDateRates;
            let endDateRates;

            if (status === Status.CLOSED) {
              startDateRates = newStartDate && startRoomRateDetail;
              endDateRates = formattedNewEndDate && endRoomRateDetail;
            } else {
              startDateRates = startDay && startRoomRateDetail;
              endDateRates = endDay && endRoomRateDetail;
            }
            let areThereAnyError = false;

            if (!startDay || !endDay) {
              areThereAnyError = true;
            }

            if (startDateRates && startDateRates.closedToArrival) {
              setError(
                <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckin" />,
              );
              areThereAnyError = true;
            }

            if (endDateRates && endDateRates.closedToDeparture) {
              setError(
                <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckout" />,
              );
              areThereAnyError = true;
            }

            const arrayDates = await createDatesArray(
              isFirstRender ? startDay : startDateAvailable,
              isFirstRender ? endDay : endDateAvailable,
            );

            const isClosed = arrayDates.some((date) => {
              const rateForDate = filteredReferences.find(
                (item) => item.date === date,
              );
              return rateForDate ? rateForDate.closed : true;
            }); 
            if (isClosed) {
              setError(
                <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidRange" />,
              );
              areThereAnyError = true;
            } else {
              const maxMinStay = arrayDates
                .map((date) =>
                  filteredReferences.find((item) => item.date === date),
                )
                .filter((rateDetail) => rateDetail && !rateDetail.closed) // Asegurarse de excluir tasas cerradas en este paso también
                .slice(0, -1) // Eliminar ultimo día para no obtener su minimo de noches
                .reduce(
                  (maxStay, rateDetail) =>
                    Math.max(maxStay, rateDetail.minStay),
                  0,
                );

              const daysBetween = calcDaysBetweenCheckInAndCheckOut({
                startDate: isFirstRender ? moment.utc(startDay) : moment.utc(startDateAvailable),
                endDate: isFirstRender ? moment.utc(endDay) : moment.utc(endDateAvailable),
              });
              if (maxMinStay > daysBetween && daysBetween > 0) {
                setError(
                  <FormattedMessage
                    id="AvailabilityDrawer.AvailabilityCalendar.MinNight"
                    values={{ count: maxMinStay, br: ' ' }}
                  />,
                );
                areThereAnyError = true;
              } else {
                setError(null);
                areThereAnyError = false;
              }
            }
            if (startDay && endDay && !isDateRangeValid(startDay, endDay)) {
              setError(
                <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidRange" />,
              );
              areThereAnyError = true;
            }

            setCheckedErrors(true);
            setAreAnyError(areThereAnyError);
          }
        }
      } catch (error2) {
        // eslint-disable-next-line no-console
        console.error(error2);
      }
    };

    fetchData();
  }, [
    reservation,
    startDate,
    property,
    currentDates,
    selectedUnit,
    setAreAnyError,
    selectedUnitRatePlan,
    daysBetweenCheckInAndCheckOut,
    property.openFrom,
    status,
    isCampingClosed,
    endDate,
    openFrom,
    createDatesArray,
    filteredReferences,
    startDateAvailable,
    endDateAvailable,
    isFirstRender
  ]);

  return (
    <AvailabilityContext.Provider
      value={{
        areAnyError,
        askForOnePropertyRatePlan,
        getReferenceRate,
        availableUnits,
        checkedErrors,
        currentDates,
        dispatch,
        error,
        fetchingUnits:
          !initialCharge.current &&
          filteredReferences &&
          filteredReferences.length === 0,
        initialCharge,
        loadingRatePlans,
        onError: setError,
        property,
        ratePlans,
        reservationInitialDates,
        resetErrors: () => {
          setAreAnyError(false);
          setError(null);
        },
        selectedUnit,
        selectedUnitRatePlan,
        filteredReferences,
        setAreAnyError,
        setCurrentDates,
        setLoadingRatePlans,
        setReservationInitialDates,
        setSelectedUnit,
        setShowMoreDatesDrawer,
        showMoreDatesDrawer,
        filteredRates,
        showCalendarUnavailable,
        relaunchGetReference,
        setRelaunchGetReference,
        isLoadingPlans,
        startDateAvailable,
        setStartDateAvailable,
        endDateAvailable,
        setEndDateAvailable,
        isLoadingReferenceRates,
        setIsLoadingReferenceRates,
        setIsFirstRender,
        isFirstRender,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  );
};

AvailabilityProvider.propTypes = {
  children: PropTypes.element.isRequired,
  property: PropTypes.oneOfType([PropTypes.object]).isRequired,
  unitId: PropTypes.string,
  unitObj: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

AvailabilityProvider.defaultProps = {
  unitId: undefined,
};

export { AvailabilityProvider };

export default AvailabilityContext;
