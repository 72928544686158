import actionsTypes from "./actionsTypes";

const reducer = (state, action) => {
  switch (action.type) {
    case actionsTypes.SET_TOKENS:
      return {
        ...state,
        accessTokenCheckin: action.payload.accessTokenCheckin,
      };
    case actionsTypes.SET_ACCESSTOKEN:
      return {
        ...state,
        accessTokenCheckin: action.payload.accessTokenCheckin,
      };
    case actionsTypes.LOGOUT:
      localStorage.removeItem("accessTokenCheckin");
      return {
        ...state,
        accessTokenCheckin: null,
      };
    default:
      return state;
  }
};

export default reducer;
