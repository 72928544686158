import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import styles from './index.module.scss';
import { ReactComponent as TentIcon } from '../../../../assets/icons/icon_tent.svg';
import { ReactComponent as AdultIcon } from '../../../../assets/icons/icon_adult.svg';
import { ReactComponent as ChildIcon } from '../../../../assets/icons/icon_children.svg';
import { ReactComponent as BabyIcon } from '../../../../assets/icons/icon_babie.svg';

const AccommodationCard = ({ reservationDetails = {} }) => {
  const intl = useIntl();
  const details = reservationDetails?.details || [];

  return (
    <div className={styles.accommodationCard}>
      {details.map((tent) => {
        const {
          reservationRoomStayId,
          tent: tentName,
          adult,
          child,
          junior,
          arrival,
          departure,
        } = tent || {};

        return (
          <div className={styles.accommodation} key={reservationRoomStayId}>
            <h4>
              <TentIcon className={styles.icon} /> {tentName}
            </h4>
            <div className={styles.details}>
              {adult > 0 && (
                <div>
                  <AdultIcon className={styles.detailIcon} /> {adult} {intl.formatMessage({ id: 'typeOfPerson.adults' })} 
                </div>
              )}
              {child > 0 && (
                <div>
                  <ChildIcon className={styles.detailIcon} /> {child} {intl.formatMessage({ id: 'typeOfPerson.children' })}
                </div>
              )}
              {junior > 0 && (
                <div>
                  <BabyIcon className={styles.detailIcon} /> {junior} {intl.formatMessage({ id: 'typeOfPerson.babies' })}
                </div>
              )}
            </div>
            <div className={styles.dates}>
              <div>
                <b>{intl.formatMessage({ id: 'Checkin.Summary.Arrival' })}</b>
                <p>{moment(arrival).format('DD-MM-YYYY')}</p>
              </div>
              <div>
                <b>{intl.formatMessage({ id: 'Checkin.Summary.Departure' })}</b>
                <p>{moment(departure).format('DD-MM-YYYY')}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

AccommodationCard.propTypes = {
  reservationDetails: PropTypes.shape({
    details: PropTypes.arrayOf(
      PropTypes.shape({
        reservationRoomStayId: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        tent: PropTypes.string,
        adult: PropTypes.number,
        child: PropTypes.number,
        junior: PropTypes.number,
        arrival: PropTypes.string,
        departure: PropTypes.string,
      })
    ),
  }),
};

AccommodationCard.defaultProps = {
  reservationDetails: {
    details: [],
  },
};

export default AccommodationCard;