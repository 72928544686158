import moment from 'moment';
import { hasReachedTimeLimit } from './calendarUtils';
import * as s from '../index.module.scss'

export const getClasses = (day, filteredReference, startDate, endDate) => {
    const today = moment();
    const isClosedDates = () => startDate && endDate;
    const isToday = () => day.isSame(today, 'date');
    const isStartDate = () => startDate && day.isSame(startDate, 'day');
    const isEndDate = () => endDate && day.isSame(endDate, 'day');
    const isBetweenDates = () => startDate && endDate && day.isBetween(startDate, endDate, 'day');
    const isInThePast = () => day.isBefore(moment(), 'day');
    const hasRooms = filteredReference && filteredReference.quantityAvailable > 0;
    const isClosedToArrival = filteredReference && filteredReference.closedToArrival === true;
    const isClosedToDeparture = filteredReference && filteredReference.closedToDeparture === true;
    const isClosedRate = filteredReference && filteredReference.closed === true;
    const hasAmount = filteredReference && filteredReference.amountAfterTax > 0;
  
    const isRatePlanAvailable = !isClosedRate &&
      !isClosedToArrival &&
      !isClosedToDeparture &&
      hasRooms &&
      hasAmount;
  
    let classes = s.day;
    if (isRatePlanAvailable) {
      classes += `${s.closedDates} `;
    } else {
      classes += `${s.availableToArriveOrDeparture} `;
    }
    if (isStartDate()) {
      classes += `${s.startDay} `;
      if (isClosedDates()) {
        classes += `${s.closedDates} `;
      }

      if (!isRatePlanAvailable || !filteredReference) {
        classes += `${s.notAvailable} `;
      }
      classes += `${s.startDay} `;
    } else if (isEndDate()) {
      classes += `${s.endDay} `;
      if (isClosedDates()) {
        classes += `${s.closedDates} `;
      }
      if (!isRatePlanAvailable || !filteredReference) {
        classes += `${s.notAvailable} `;
      }
    } else {
      if (startDate && endDate && isBetweenDates()) {
        classes += `${s.dayBetween} `;
      }
      if (isToday() && !hasReachedTimeLimit()) {
        classes += `${s.todayDay} `;
        if (!isRatePlanAvailable || !filteredReference) {
          classes += `${s.notAvailable} `;
        }
      }

      if (!isRatePlanAvailable || !filteredReference) {
        classes += `${s.notAvailable} `;
      }

      if (isInThePast() && !filteredReference) {
        classes += `${s.notSelectable} `;
      }
    }
    return classes.trim();
};

export default getClasses;