/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import { showRoomWrapper, wrapper, title, body } from './index.module.scss';
import { useAuthState } from '../../../../../services/Auth/useAuth';
import CardShowRoom from '../../../../Places/components/CardShowRoom';
import { ReactComponent as TentIcon } from '../../../../../assets/icons/tent.svg';
import Services from '../../../../../constants/services';
import useCampings from '../../../../../services/Campings/useCampings';
import UnitResumeTable from '../../../../../components/UnitResumeTable';
import {
  useReservationDispatch,
  useReservationState,
} from '../../../../../services/Reservation';
import PersonSelectorDrawer from '../../../../../components/PersonSelectorDrawer';
// import DatePickerDrawer from '../../../../../components/DatePickerDrawer';
import AdvancedAvailabilityDrawer from '../../../../../components/AdvancedAvailabilityDrawer';

const TentSelector = ({
  reservation,
  propertyID,
  type,
  setFpaFilter,
  fpaFilter,
}) => {
  const firstTry = useRef(false);
  const [showDatePickerDrawer, setShowDatePickerDrawer] = useState(false);
  const [showPersonSelectorDrawer, setShowPersonSelectorDrawer] =
    useState(false);
  const [askingForNewRatePlans, setAskingForNewRatePlans] = useState(false);
  const {
    state: {
      campings,
      ratePlansAlreadyFetched: campingsRatePlansAlreadyFetched,
    },
    tentState: { tents },
    askForCampingsRatePlans,
    dispatch: campingsDispatch,
  } = useCampings();
  const { children, adults, babies } = useReservationState();
  const dispatchReservation = useReservationDispatch();

  const propertiesList = campings;
  const ratePlansAlreadyFletched = campingsRatePlansAlreadyFetched;
  const dispatch = campingsDispatch;
  const askForRatePlans = askForCampingsRatePlans;
  const property = propertiesList[propertyID];
  const filteredRatePlans =
    property && property.ratePlans ? property.ratePlans : [];
  const { accessToken } = useAuthState();
  const [isAccesTokenNull, setIsAccesTokenNull] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const ratePlans = useMemo(() => {
    const roomTypeIdExistsInTentsAndRatePlans = (ratePlan) =>
      Object.keys(tents)
        .map((tentId) => parseInt(tentId, 10))
        .indexOf(ratePlan.id) >= 0;

    return filteredRatePlans
      .filter(roomTypeIdExistsInTentsAndRatePlans)
      .sort((a, b) => {
        if (
          Object.keys(tents).find(
            (tentId) => parseInt(tents[tentId].roomTypeId, 10) === a.roomTypeID,
          ) &&
          Object.keys(tents).find(
            (tentId) => parseInt(tents[tentId].roomTypeId, 10) === b.roomTypeID,
          )
        ) {
          return (
            tents[
              Object.keys(tents).find(
                (tentId) =>
                  parseInt(tents[tentId].roomTypeId, 10) === a.roomTypeID,
              )
            ].order -
            tents[
              Object.keys(tents).find(
                (tentId) =>
                  parseInt(tents[tentId].roomTypeId, 10) === b.roomTypeID,
              )
            ].order
          );
        }
        return 0;
      });
  }, [filteredRatePlans, tents]);

  useEffect(() => {
    if (
      (!filteredRatePlans || filteredRatePlans.length < 1) &&
      propertiesList &&
      !firstTry.current
    ) {
      firstTry.current = true;
      askForRatePlans(reservation, propertyID);
      setIsAccesTokenNull(accessToken === null);
      setIsLoaded(true);
    }
  }, [
    filteredRatePlans,
    askForRatePlans,
    propertiesList,
    reservation,
    propertyID,
    accessToken,
  ]);

  useEffect(() => {
    if (Object.keys(propertiesList).length > 0 && ratePlansAlreadyFletched) {
      dispatch({ type: 'setRatePlansAsFetched' });
    }
  }, [dispatch, propertiesList, ratePlansAlreadyFletched]);

  useEffect(() => {
    if (askingForNewRatePlans) {
      setAskingForNewRatePlans(false);
      askForRatePlans(reservation, propertyID);
    }
  }, [askingForNewRatePlans, reservation, askForRatePlans, propertyID]);

  useEffect(() => {
    if (isLoaded) {
      if (accessToken !== null && isAccesTokenNull === true) {
        askForRatePlans(reservation, propertyID);
        setIsAccesTokenNull(accessToken === null);
      }
      if (accessToken === null && isAccesTokenNull === false) {
        askForRatePlans(reservation, propertyID);
        setIsAccesTokenNull(accessToken === null);
      }
    }
    // eslint-disable-next-line
  }, [accessToken, askForRatePlans, isAccesTokenNull]);

  return (
    <>
      <div className={wrapper}>
        <div className={title}>
          <TentIcon />
          <FormattedMessage id={`#DetailsPage.TentSelector.${type}.Title`} />
        </div>
        <div className={body}>
          <UnitResumeTable
            data={reservation}
            type={type}
            onDatePickerClick={() => setShowDatePickerDrawer(true)}
            onPersonSelectorClick={() => setShowPersonSelectorDrawer(true)}
            fpa={fpaFilter}
          />
        </div>
      </div>
      <div className={showRoomWrapper}>
        <CardShowRoom
          ratePlans={ratePlans}
          propertyID={propertyID}
          property={propertiesList[propertyID]}
          priceFormat="byPricePerNightAndTotal"
          type={type}
          askingForNewRatePlans={() => setAskingForNewRatePlans(true)}
        />
      </div>

        <AdvancedAvailabilityDrawer
          visible={showDatePickerDrawer}
          onClose={() => {
            setShowDatePickerDrawer(false);
          }}
          property={property}
          rateId={undefined}
          unitId={undefined}
          kampaohService={type}
          onOk={({ startDate: start, endDate: end }) => {
            if (start.isBefore(end)) {
              dispatchReservation({ type: 'setStartDate', payload: start });
              dispatchReservation({ type: 'setEndDate', payload: end });
              dispatch({ type: 'resetHotelsInitialState' });
              const path = window.location.href.split('?')[0];
              navigate(
                `${path}?checkin=${moment(start).format(
                  'YYYY-MM-DD',
                )}&checkout=${moment(end).format(
                  'YYYY-MM-DD',
                )}&adults=${adults}&children=${children}&babies=${babies}`,
              );
              setShowDatePickerDrawer(false);
              setAskingForNewRatePlans(true);
            }
          }}
          detailsPage
        />
      {showPersonSelectorDrawer && (
        <PersonSelectorDrawer
          initCountersValues={{ children, adults, babies }}
          visible={showPersonSelectorDrawer}
          onClose={() => {
            setShowPersonSelectorDrawer(false);
          }}
          onOk={(counter) => {
            const url = new URL(window.location);
            const params = new URLSearchParams(url.search);
            Object.keys(counter).forEach((key) => {
              dispatchReservation({ type: `set${key}`, payload: counter[key] });
              params.set(key, counter[key]);
              params.set('fpa', fpaFilter);
            });
            dispatch({ type: 'resetHotelsInitialState' });
            setShowPersonSelectorDrawer(false);
            setAskingForNewRatePlans(true);
            const path = window.location.href.split('?')[0];
            navigate(
              `${path}?checkin=${moment(reservation.startDate).format(
                'YYYY-MM-DD',
              )}&checkout=${moment(reservation.endDate).format(
                'YYYY-MM-DD',
              )}&adults=${counter.adults}&children=${counter.children}&babies=${
                counter.babies
              }&fpa=${fpaFilter}`,
            );
          }}
          fpaFilter={fpaFilter}
          setFpaFilter={setFpaFilter}
        />
      )}
    </>
  );
};

TentSelector.propTypes = {
  reservation: PropTypes.shape({
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
    adults: PropTypes.number,
    children: PropTypes.number,
    babies: PropTypes.number,
  }).isRequired,
  propertyID: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Services)),
  fpaFilter: PropTypes.string,
  setFpaFilter: PropTypes.func,
};

TentSelector.defaultProps = {
  type: Services.CAMP,
  fpaFilter: 'none',
  setFpaFilter: () => {},
};
export default TentSelector;
