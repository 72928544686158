/* eslint-disable import/no-extraneous-dependencies */
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

const isValidSpanishDocument = (doc) => {
  if (!doc) return false;

  const dniRegex = /^[0-9]{8}[A-Z]$/;
  if (dniRegex.test(doc)) {
    const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const number = parseInt(doc.slice(0, 8), 10);
    const letter = doc[8];
    return letters[number % 23] === letter;
  }

  const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
  if (nieRegex.test(doc)) {
    const niePrefixes = { X: 0, Y: 1, Z: 2 };
    const nieNumber = doc.replace(/^[XYZ]/, (match) => niePrefixes[match]);
    const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const number = parseInt(nieNumber.slice(0, 8), 10);
    const letter = doc[8];
    return letters[number % 23] === letter;
  }

  return false;
};

export const validationSchema = (intl) => Yup.object().shape({
    documentType: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Document.Required' })),
    documentNumber: Yup.string()
    .required(intl.formatMessage({ id: 'Checkin.GuestForm.Document.Required' }))
    .when('documentType', (documentType, schema) => {
      if (documentType === 'D' || documentType[0] === 'D') {
        return schema.test(
          'isValidDNI',
          intl.formatMessage({ id: 'Checkin.GuestForm.Document.DNI.Invalid' }),
          (value) => isValidSpanishDocument(value.toUpperCase())
        );

        
      }
      return schema;
    }),
    countryOfIssuance: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Document.Country.Required' })),
    birthDate: Yup.date().required(intl.formatMessage({ id: 'Checkin.GuestForm.Document.BirthDate.Required' })),
    expireDate: Yup.date().required(intl.formatMessage({ id: 'Checkin.GuestForm.Document.ExpireDate.Required' })),
    documentSupport: Yup.string()
    .nullable()
    .when('documentType', (documentType, schema) => {
      if (documentType === 'D' || documentType[0] === 'D') {
        return schema.required(intl.formatMessage({ id: 'Checkin.GuestForm.Document.SupportNumber.Required' }));
      }
      return schema.nullable();
    }),
    familyRelationship: Yup.string()
      .nullable()
      .when('$hasChildrenOrBabies', {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({ id: 'Checkin.GuestForm.Document.RelationShip.Required' })
        ),
      }),
    givenName: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Name.Required' })),
    surName: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.SurName.Required' })),
    secondSurName: Yup.string()
    .nullable()
    .when('documentType', (documentType, schema) => {
      if (documentType === 'D' || documentType[0] === 'D') {
        return schema.required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.SecondSurName.Required' }));
      }
      return schema.nullable();
    }),
    language: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Country.Required' })),
    nationalityCountry: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Nationality.Required' })),
    genderType: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Genre.Required' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Email.Invalid' }))
      .test(
        'email-or-phone',
        intl.formatMessage({ id: 'Checkin.GuestForm.PhoneNumerOrEmail.Required' }),
        function (value) {
          const { phoneNumber } = this.parent;
          return value || phoneNumber;
        }
      ),
      phoneNumber: Yup.string()
      .test(
        'isValidPhoneNumber',
        intl.formatMessage({ id: 'Checkin.GuestForm.PhoneNumber.Invalid' }),
        (value) => {
          if (!value) return true;
          return isValidPhoneNumber(value);
        }
      )
      .test(
        'phone-or-email',
        intl.formatMessage({ id: 'Checkin.GuestForm.PhoneNumerOrEmail.Required' }),
        function (value) {
          const { email } = this.parent;
          return value || email; // Requiere al menos uno: teléfono o email
        }
      ),
    street: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Address.Required' })),
    country: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.ResidenceCountry.Required' })),
    stateProv: Yup.string(),
    stateProvName: Yup.string(),
    city: Yup.string(),
    municipality: Yup.string(),
    municipalityName: Yup.string(),
    postalCode: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.PostalCode.Required' })),
    terms: Yup.boolean()
      .oneOf([true], intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Terms.Required' }))
      .required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Terms.Required' })),
    signature: Yup.string().required(intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Signature.Required' })),
  })

export default validationSchema;

