/* eslint-disable */
import { navigate } from '@reach/router';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { notification } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import ActionButton from '../../../../components/ActionButton';
import { LanguageContext } from '../../../../locale/contexts/Language';
import ROUTES from '../../../../routes/routes';
import useCampings from '../../../../services/Campings/useCampings';
import sendEmail from '../../../../services/EmailAPI';
import registerPayment from '../../../../services/PaymentAPI';
import {
  useReservationDispatch,
  useReservationState,
} from '../../../../services/Reservation';
import daysBetweenCheckInAndCheckOut from '../../../../utils/daysBetweenCheckInAndCheckOut';
import {
  calcTimeLimitForTodaysBooking,
  showNotReservationAllowedConfirmModal,
} from '../../../../utils/timeLimitForTodaysBooking';
import { showModal } from '../../utils';
import {
  proxyServerError402,
  proxyServerError404,
  proxyServerError500,
} from './errors/proxyServerError';
import stripeTokenError from './errors/stripeTokenError';
import { paymentElementWrapper } from './index.module.scss';

const CheckoutPage = injectIntl(
  ({
    intl,
    reservationFee,
    setPostingReservation,
    children,
    onFinish,
    filteredRatePlans,
  }) => {
    const stripe = useStripe();
    const elements = useElements();
    const reservation = useReservationState();
    const dispatch = useReservationDispatch();
    const { currentLanguage } = useContext(LanguageContext);
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const propertyFromURL = searchParams.get('property');
    const [processing, setProcessing] = useState(false);
    const [checkingReservationUpdates, setCheckingReservationUpdates] =
      useState(false);

    const reservationFeeDecimal = reservationFee / 100;

    const planInfo = filteredRatePlans && filteredRatePlans[0]?.plans[0];

    const {
      state: {
        campings: { [reservation.selectedHotelId || propertyFromURL]: camping },
      },
      askForOneCampingRatePlans,
    } = useCampings();

    const property = camping;
    const askForOneRatePlan = askForOneCampingRatePlans;

    const cleanUpAndShowError = async (msg) => {
      notification.error({
        message: msg,
        duration: 0,
      });

      setPostingReservation(false);
    };

    const processPayment = async () => {
      setPostingReservation(true);

      try {
        // Create the PaymentMethod using the details collected by the Payment Element
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          elements,
          params: {},
        });

        if (error) {
          // This point is only reached if there's an immediate error when
          // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
          handleError(error);

          return;
        } else {
          try {
            // Register the payment on backend
            const secret = await registerPayment('/api/v3/reservation', {
              email: reservation?.guestEmail,
              paymentMethodId: paymentMethod.id,
              reservation: {
                ...reservation,
                startDate: moment(reservation?.startDate).format('YYYY-MM-DD'),
                endDate: moment(reservation?.endDate).format('YYYY-MM-DD'),
                lang: currentLanguage,
              },
            });
            handleServerResponse(secret);
          } catch (error) {
            if (error && error.response.data.error.statusCode === 402) {
              cleanUpAndShowError(
                intl.formatMessage({
                  id: 'Error.PaymentCard2',
                  defaultMessage:
                    'Revisa los datos de tu tarjeta, algo ha ido mal realizando el pago.',
                }),
              );
              proxyServerError500(reservation, error.response.data.error);
            } else {
              cleanUpAndShowError(
                intl.formatMessage({
                  id: 'Error.Generic',
                  defaultMessage:
                    'No hemos podido realizar tu reserva. Llama al 955 25 24 40',
                }),
              );
              proxyServerError500(reservation, error);
            }
          }
        }
      } catch (error) {
        cleanUpAndShowError(
          intl.formatMessage({
            id: 'Error.Generic',
            defaultMessage:
              'No hemos podido realizar tu reserva. Llama al 955 25 24 40',
          }),
        );
        proxyServerError500(reservation, error);
      }
    };

    useEffect(() => {
      if (checkingReservationUpdates) {
        askForOneRatePlan(reservation, property?.ulysesId);
      }
    }, [checkingReservationUpdates]);

    useEffect(() => {
      if (checkingReservationUpdates) {
        if (!planInfo) {
          showModal({
            title: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Title',
            }),
            content: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Content',
            }),
            handleOk: () => {
              navigate(ROUTES.home);
            },
            handleCancel: () => {
              navigate(ROUTES.home);
            },
          });
        } else if (
          planInfo &&
          planInfo?.totalPrice &&
          reservation?.totalPrice !== planInfo.totalPrice
        ) {
          dispatch({ type: 'setTotalPrice', payload: planInfo?.totalPrice });
          processPayment();
        } else if (
          planInfo &&
          planInfo?.roomRateDetailed?.every(
            (element) => element?.available !== true,
          )
        ) {
          showModal({
            title: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Title',
            }),
            content: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Content',
            }),
            handleOk: () => {
              navigate(ROUTES?.home);
            },
            handleCancel: () => {
              navigate(ROUTES?.home);
            },
          });
        } else {
          processPayment();
        }
        setCheckingReservationUpdates(false);
      }
    }, [property, reservation, planInfo]);

    const handleError = (error) => {
      setProcessing(false);
      cleanUpAndShowError(error.message);
    };

    const handleServerResponse = async (response) => {
      if (response.error) {
        setProcessing(false);
        if (response.error && response.message === 404) {
          cleanUpAndShowError(
            intl.formatMessage({
              id: 'Error.RoomNotAvailable',
              defaultMessage:
                'Esa tienda ya no se encuentra disponible y ha ocurrido un error en su reserva, contacte con incidencias@kampaoh.com para más información o llama al 955 25 24 40',
            }),
          );
          proxyServerError404(reservation);
        } else if (response.error && response.message === 400) {
          response.body.message = intl.formatMessage({
            id: 'Error.PaymentCard',
            defaultMessage: 'Hay un error en tu tarjeta, revisa los datos',
          });
          setProcessing(false);
          cleanUpAndShowError(
            intl.formatMessage({
              id: 'Error.PaymentCard2',
              defaultMessage:
                'Revisa los datos de tu tarjeta, algo ha ido mal realizando el pago.',
            }),
          );
          stripeTokenError(reservation, response.body);
        } else if (response?.body?.response?.status === 402) {
          if (response?.body?.response?.data.code === 'incorrect_cvc') {
            cleanUpAndShowError(
              intl.formatMessage({
                id: 'Error.Reservation.cvc',
                defaultMessage:
                  'El código de seguridad de la tarjeta es incorrecto. Compruebe el código de seguridad de la tarjeta o utilice una tarjeta diferente.',
              }),
            );
            proxyServerError402(reservation, response);
          } else if (
            response?.body?.response?.data.code === 'processing_error'
          ) {
            cleanUpAndShowError(
              intl.formatMessage({
                id: 'Error.Reservation.processing_error',
                defaultMessage:
                  'Se ha producido un error al procesar la tarjeta. Vuelve a intentarlo más tarde o con otro método de pago.',
              }),
            );
            proxyServerError402(reservation, response);
          } else if (
            response?.body?.response?.data.code === 'incorrect_number'
          ) {
            cleanUpAndShowError(
              intl.formatMessage({
                id: 'Error.Reservation.incorrect_number',
                defaultMessage:
                  'El número de la tarjeta es incorrecto. Compruebe el número de la tarjeta o utilice una tarjeta diferente.',
              }),
            );
            proxyServerError402(reservation, response);
          } else if (response?.body?.response?.data.code === 'card_declined') {
            cleanUpAndShowError(
              intl.formatMessage({
                id: 'Error.Reservation.card_declined',
                defaultMessage:
                  'La tarjeta ha sido rechazada. Vuelva a intentarlo',
              }),
            );
            proxyServerError402(reservation, response);
          } else if (response?.body?.response?.data.code === 'expired_card') {
            cleanUpAndShowError(
              intl.formatMessage({
                id: 'Error.Reservation.expired_card',
                defaultMessage:
                  'La tarjeta ha caducado. Comprueba la fecha de caducidad o utiliza otra tarjeta.',
              }),
            );
            proxyServerError402(reservation, response);
          } else {
            cleanUpAndShowError(
              intl.formatMessage({
                id: 'Error.Reservation.not_reservation',
                defaultMessage:
                  'No hemos podido realizar tu reserva. Intenta realizar de nuevo la reserva o prueba con otra tarjeta.',
              }),
            );
            proxyServerError402(reservation, response);
          }
        } else {
          cleanUpAndShowError(
            intl.formatMessage({
              id: 'Error.Reservation',
              defaultMessage:
                'No hemos podido realizar tu reserva. Llama al 955 25 24 40',
            }),
          );
          proxyServerError500(reservation, response);
        }
      } else if (
        response.paymentIntent &&
        (response.paymentIntent.status === 'requires_action' ||
          response.paymentIntent.status === 'requires_source_action')
      ) {
        // Use Stripe.js to handle the required next action
        const { error } = await stripe.handleNextAction({
          clientSecret: response.paymentIntent.client_secret,
        });

        if (error) {
          cleanUpAndShowError(
            intl.formatMessage({
              id: 'Error.Reservation',
              defaultMessage:
                'No hemos podido realizar tu reserva. Llama al 955 25 24 40',
            }),
          );
          proxyServerError500(reservation, error);
        } else {
          const { reservationId } = response.paymentIntent.metadata;
          if (reservation) {
            window.dataLayer.push({
              event: 'purchaseBE',
              reservationID: reservationId,
              checkinDate: reservation.startDate,
              checkinOut: reservation.endDate,
              totalPrice: reservation.totalPrice,
              adults: reservation.adults,
              children: reservation.children,
              dayCount: daysBetweenCheckInAndCheckOut({
                endDate: moment.utc(reservation.endDate),
                startDate: moment.utc(reservation.startDate),
              }),
            });
          }
          dispatch({ type: 'setReservationId', payload: reservationId });
          onFinish();
        }
      } else {
        const { reservationId } = response.paymentIntent.metadata;
        if (reservation) {
          window.dataLayer.push({
            event: 'purchaseBE',
            reservationID: reservationId,
            checkinDate: reservation?.startDate,
            checkinOut: reservation?.endDate,
            totalPrice: reservation?.totalPrice,
            adults: reservation?.adults,
            children: reservation?.children,
            dayCount: daysBetweenCheckInAndCheckOut({
              endDate: moment.utc(reservation?.endDate),
              startDate: moment.utc(reservation?.startDate),
            }),
            propertyID: reservation?.selectedHotelId,
          });
        }
        dispatch({ type: 'setReservationId', payload: reservationId });
        onFinish();
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      sendEmail(
        '$0001.5$'.concat(reservation.guestEmail),
        'RESERVATION: \n'.concat(JSON.stringify(reservation, null, 4), '\n'),
      );

      if (!stripe) {
        // Handle error: Stripe.js has not yet loaded.
        // Show a message to your customer or log an error.
        return;
      }

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();

      if (submitError) {
        handleError(submitError);
        return;
      }

      const limitHour = calcTimeLimitForTodaysBooking(property);
      if (
        limitHour &&
        moment(reservation.startDate)
          .tz('Europe/Madrid')
          .isSame(moment().tz('Europe/Madrid'), 'day') &&
        moment().tz('Europe/Madrid').isAfter(limitHour)
      ) {
        showNotReservationAllowedConfirmModal(-3, property, intl);
      } else {
        setCheckingReservationUpdates(true);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <PaymentElement className={paymentElementWrapper} />
        {children}
        <ActionButton loading={processing} disabled={!stripe} htmlType="submit" data-cy="checkout-step4-submit">
          {intl
            .formatMessage({
              id: 'Payment.CheckoutForm.PayButton',
              defaultMessage: 'Pagar',
            })
            .concat(
              ' ',
              intl.formatNumber(reservation?.totalPrice * reservationFeeDecimal, {
                style: 'currency',
                currency: 'EUR',
              }),
            )}
        </ActionButton>
      </form>
    );
  },
);

export default CheckoutPage;
