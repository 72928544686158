/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Input from 'antd/lib/input/Input';
import styles from './index.module.scss';

const LocatorInput = ({ value, onChange, name, id, ...props }) => (
  <Input
    type="text"
    value={value || ''}
    onChange={onChange}
    name={name}
    id={id}
    className={styles.input}
    size="large" 
    {...props}
  />
);

LocatorInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

LocatorInput.defaultProps = {
  value: '',
  id: '',
};

export default LocatorInput;