/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { PulseLoader } from 'react-spinners';
import { chunkArray, createPlaceholders } from '../../utils/calendarUtils';
import NightTotalNumberTooltip from '../NightTotalNumberTooltip';
import calcDaysBetweenCheckInAndCheckOut from '../../../../../../../../../../utils/daysBetweenCheckInAndCheckOut';
import * as s from '../../index.module.scss';

const CalendarMonth = ({
  monthKey,
  referenceRates,
  startDate,
  endDate,
  handleOnDayClick,
  messagesContainerRef,
  getClasses,
  loading,
  isLoadingReferenceRates,
}) => {
  const [year, month] = monthKey.split('-');
  const monthMoment = moment().year(year).month(Number(month) - 1);
  const monthLabel = monthMoment.format('MMMM YYYY');
  const firstDayOfMonth = monthMoment.clone().startOf('month');
  const lastDayOfMonth = monthMoment.clone().endOf('month');
  const totalMonthDays = [];
  for (let d = firstDayOfMonth.clone(); d.isSameOrBefore(lastDayOfMonth, 'day'); d.add(1, 'day')) {
    totalMonthDays.push(d.clone());
  }

  const startDayOfWeek = firstDayOfMonth.isoWeekday();
  const emptyDaysBefore = startDayOfWeek - 1;
  const leading = createPlaceholders(emptyDaysBefore);
  const totalSize = leading.length + totalMonthDays.length;
  const leftover = totalSize % 7;
  const emptyDaysAfter = leftover > 0 ? 7 - leftover : 0;
  const trailing = createPlaceholders(emptyDaysAfter);
  const fullMonth = [...leading, ...totalMonthDays, ...trailing];
  const weeksInMonth = chunkArray(fullMonth, 7);
  return (
    <div className={s.monthCalendar}>
      <div className={s.calendarTitle}>{monthLabel}</div>
      <div className={s.calendarScrollContainer}>
        {weeksInMonth.map((weekRow, rowIdx) => (
          <div className={s.week} key={`${monthKey}-${rowIdx}`}>
            {weekRow.map((maybeDay, cellIdx) => {
              if (maybeDay === null) {
                return <div key={`placeholder-${cellIdx}`} className={`${s.day} ${s.emptyDay}`} />;
              }
              const day = maybeDay;
              const filteredReference = referenceRates.find(
                (r) => moment(r.date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
              );
              const startDateRatePlan = startDate
                ? referenceRates.find((r) => moment(r.date).format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD'))
                : null;
              const dayClass = getClasses(day, filteredReference, startDate, endDate);

              return (
                <div
                  className={dayClass}
                  key={day.toString()}
                  role="button"
                  tabIndex={0}
                  onClick={() => (loading || isLoadingReferenceRates) ? null : handleOnDayClick(day, filteredReference)}
                  onKeyPress={() => (loading || isLoadingReferenceRates) ? null : handleOnDayClick(day, filteredReference)}
                >
                  <NightTotalNumberTooltip
                    visible={!loading && endDate && day.isSame(endDate, 'day')}
                    daysBetweenCheckInAndCheckOut={calcDaysBetweenCheckInAndCheckOut({
                      endDate: endDate ? moment.utc(endDate) : null,
                      startDate: startDate ? moment.utc(startDate) : null,
                    })}
                    startDateRatePlan={startDateRatePlan}
                    width={
                      messagesContainerRef.current ? messagesContainerRef.current.offsetWidth / 7 : undefined
                    }
                  >
                    <div className={loading ? s.loading : s.day} data-date={moment(day).format('YYYY-MM-DD')}>
                      <div className={s.dayNumber}>{day.format('D')}</div>
                      {(loading || isLoadingReferenceRates) && <PulseLoader size={4} />}
                      {!loading && !isLoadingReferenceRates && (
                        <div className={s.dayRate}>
                          <FormattedNumber
                            value={filteredReference ? filteredReference.amountAfterTax : ''}
                            style="currency"
                            currency="EUR"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        </div>
                      )}
                    </div>
                  </NightTotalNumberTooltip>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

CalendarMonth.propTypes = {
  monthKey: PropTypes.string.isRequired,
  referenceRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      rateProductOccupancyId: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      amountAfterTax: PropTypes.number.isRequired,
      closed: PropTypes.bool.isRequired,
      minStay: PropTypes.number.isRequired,
      maxStay: PropTypes.number.isRequired,
      closedToArrival: PropTypes.bool.isRequired,
      closedToDeparture: PropTypes.bool.isRequired,
      percentDiscount: PropTypes.number,
      amountDiscount: PropTypes.number,
      quantityAvailable: PropTypes.number.isRequired,
      productId: PropTypes.number.isRequired,
      productName: PropTypes.string.isRequired,
      productCode: PropTypes.string.isRequired,
    })
  ).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  handleOnDayClick: PropTypes.func.isRequired,
  messagesContainerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  getClasses: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isLoadingReferenceRates: PropTypes.bool.isRequired,
};

export default CalendarMonth;