/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {memo} from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import LocatorInput from '../LocatorInput';
import DateRangePicker from '../DateRangePicker';
import SubmitButton from '../SubmitButton';
import styles from './index.module.scss';

const { Title } = Typography;


const CheckinForm = memo(({ initialValues, onSubmit, className }) => {
  const intl = useIntl();
  const defaultValues = {
    locator: initialValues?.locator || '',
    dateRange:
      initialValues?.checkin && initialValues?.checkout
        ? [initialValues.checkin, initialValues.checkout]
        : [],
  };

  // Validación con Yup
  const validationSchema = Yup.object().shape({
    locator: Yup.string().required(`${intl.formatMessage({ id: 'Checkin.Auth.Locator.Placeholder' })}`),
    dateRange: Yup.array()
      .of(Yup.date().required(`${intl.formatMessage({ id: 'Checkin.Auth.Dates.Required' })}`))
      .min(2, `${intl.formatMessage({ id: 'Checkin.Auth.Dates.Required' })}`)
      .required(`${intl.formatMessage({ id: 'Checkin.Auth.Dates.Required' })}`),
  });

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className={`${styles.form} ${className}`}>
          <Title level={3} className={styles.title}>
            <span className={styles.normal}>{intl.formatMessage({ id: 'Checkin.Auth.Title1' })} </span> {intl.formatMessage({ id: 'Checkin.Auth.Title2' })} <br />
            <span className={styles.normal}>{intl.formatMessage({ id: 'Checkin.Auth.Title3' })}</span> {intl.formatMessage({ id: 'Checkin.Auth.Title4' })}
          </Title>

          {/* Campo Locator */}
          <div className={styles.formItem}>
            <label htmlFor="locator">{intl.formatMessage({ id: 'Checkin.Auth.Locator' })}</label>
            <Field name="locator">
              {({ field }) => (
                <LocatorInput
                  {...field}
                  id="locator"
                  placeholder={intl.formatMessage({ id: 'Checkin.Auth.Locator.Placeholder' })}
                />
              )}
            </Field>
            {errors.locator && touched.locator && (
              <div className={styles.error}>{errors.locator}</div>
            )}
          </div>

          {/* Campo DateRange */}
          <div className={styles.formItem}>
            <label htmlFor="dateRange">{intl.formatMessage({ id: 'Checkin.Auth.Dates' })}</label>
            <Field id="dateRange" name="dateRange">
              {({ field }) => (
                <DateRangePicker
                  value={field.value}
                  onChange={(value) => setFieldValue('dateRange', value)}
                />
              )}
            </Field>
            {errors.dateRange && touched.dateRange && (
              <div className={styles.error}>{errors.dateRange}</div>
            )}
          </div>

          {/* Botón de Enviar */}
          <div className={styles.formItem}>
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
});

CheckinForm.propTypes = {
  initialValues: PropTypes.shape({
    locator: PropTypes.string,
    checkin: PropTypes.instanceOf(Date),
    checkout: PropTypes.instanceOf(Date),
  }),
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CheckinForm.defaultProps = {
  initialValues: {
    locator: '',
    checkin: null,
    checkout: null,
  },
  className: '',
};

export default CheckinForm;