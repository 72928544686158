import React from "react";
import { ReactComponent as FaceSmileIcon } from '../../../../assets/icons/face-smile-regular.svg';
import { ReactComponent as FaceFrownIcon } from '../../../../assets/icons/face-frown-regular.svg';
import styles from "../../index.module.scss";

const RefundStatusIcon = ({ isRefundable, isOutOfRefund }) => {
  if (isRefundable && !isOutOfRefund) return <FaceSmileIcon className={styles.icon} />;
  return <FaceFrownIcon className={styles.icon} />;
};

export default RefundStatusIcon;