import { Form, Select, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import rules from '../validationRules';
import {
  formItemStyle,
  documentFieldWrapper,
  selectDisabled,
  selectStyle,
} from './index.module.scss';
import { documentTypes } from './constants';

const DocumentField = ({ field, disabled, "data-cy": dataCy }) => {
  const intl = useIntl();
  const fieldTitle = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Title`,
    defaultMessage: field,
  });
  const fieldPlaceholder = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Placeholder`,
    defaultMessage: field,
  });

  const fieldLabel = useMemo(
    () => <Typography.Text strong>{fieldTitle}</Typography.Text>,
    [fieldTitle],
  );

  return (
    <div className={documentFieldWrapper}>
      <Form.Item
        className={formItemStyle}
        name={field}
        rules={rules[field]}
        label={fieldLabel}
      >
        <Select
          className={`${selectStyle} ${disabled && selectDisabled}`}
          bordered
          placeholder={fieldPlaceholder}
          disabled={disabled}
          data-cy={dataCy}
        >
          {documentTypes.map((documentType) => (
            <Select.Option 
              key={documentType.value} 
              value={documentType.value} 
              hidden={documentType.value === "23"} // Oculta solo la opción con valor "23"
            >
              {intl.formatMessage({
                id: documentType.label,
                defaultMessage: documentType.defaultMessage,
              })}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

DocumentField.defaultProps = {
  disabled: false,
  "data-cy": undefined
};
DocumentField.propTypes = {
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  "data-cy": PropTypes.string
};

export default DocumentField;
