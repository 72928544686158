import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import moment from "moment";
import styles from "../../index.module.scss";

const RefundMessage = ({ isRefundable, isOutOfRefund, cancelInfo }) => {
  const intl = useIntl();

  const refundMessage = useMemo(() => {
    if (isRefundable && !isOutOfRefund) {
      return intl.formatMessage(
        { id: "Reservation.Cancel.isRefundable" },
        { date: moment(cancelInfo).format("DD MMMM") }
      );
    }
    if (isRefundable && isOutOfRefund) {
      return intl.formatMessage({ id: "Reservation.Cancel.isNotRefundable" });
    }
    return intl.formatMessage({ id: "Reservation.Cancel.isNotRefundable.title" });
  }, [isRefundable, isOutOfRefund, cancelInfo, intl]);

  return <span className={styles.title}>{refundMessage}</span>;
};

RefundMessage.propTypes = {
  isRefundable: PropTypes.bool,
  isOutOfRefund: PropTypes.bool,
  cancelInfo: PropTypes.string,
};

export default RefundMessage;