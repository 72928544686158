export const DOCUMENT_TYPES = [
  {
    DOCUMENT_NAME: "Pasaporte",
    DOCUMENT_VALUE: "Pasaporte"
  },
  {
    DOCUMENT_NAME: "Carnet de conducir",
    DOCUMENT_VALUE: "Carnet de conducir"
  },
  {
    DOCUMENT_NAME: "DNI español",
    DOCUMENT_VALUE: "DNI español"
  },
  {
    DOCUMENT_NAME: "NIE español",
    DOCUMENT_VALUE: "NIE español"
  },
  {
    DOCUMENT_NAME: "Tarjeta de Residente Permanente",
    DOCUMENT_VALUE: "Tarjeta de Residente Permanente"
  },
  {
    DOCUMENT_NAME: "Documento de Identidad",
    DOCUMENT_VALUE: "Documento de Identidad"
  },
  {
    DOCUMENT_NAME: "Permiso de Residencia EU ",
    DOCUMENT_VALUE: "Permiso de Residencia EU "
  },
];

export const RELATIONSHIP_TYPES = [
  {
    DOCUMENT_NAME: "Abuelo/a",
    DOCUMENT_VALUE: "Abuelo/a"
  },
  {
    DOCUMENT_NAME: "Bisabuelo/a",
    DOCUMENT_VALUE: "Bisabuelo/a"
  },
  {
    DOCUMENT_NAME: "Bisnieto/a",
    DOCUMENT_VALUE: "Bisnieto/a"
  },
  {
    DOCUMENT_NAME: "Cuñado/a",
    DOCUMENT_VALUE: "Cuñado/a"
  },
  {
    DOCUMENT_NAME: "Cónyuge",
    DOCUMENT_VALUE: "Cónyuge"
  },
  {
    DOCUMENT_NAME: "Hijo/a",
    DOCUMENT_VALUE: "Hijo/a"
  },
  {
    DOCUMENT_NAME: "Hermano/a",
    DOCUMENT_VALUE: "Hermano/a"
  },
  {
    DOCUMENT_NAME: "Nieto/a",
    DOCUMENT_VALUE: "Nieto/a"
  },
  {
    DOCUMENT_NAME: "Padre o madre",
    DOCUMENT_VALUE: "Padre o madre"
  },
  {
    DOCUMENT_NAME: "Sobrino/a",
    DOCUMENT_VALUE: "Sobrino/a"
  },
  {
    DOCUMENT_NAME: "Tio/a",
    DOCUMENT_VALUE: "Tio/a"
  },
  {
    DOCUMENT_NAME: "Yerno o nuera",
    DOCUMENT_VALUE: "Yerno o nuera"
  },
  {
    DOCUMENT_NAME: "Tutor/a",
    DOCUMENT_VALUE: "Tutor/a"
  },
  {
    DOCUMENT_NAME: "Otros",
    DOCUMENT_VALUE: "Otros"
  },
];

export const GUEST_TYPES = [
  {
    GUEST_NAME: "Adult",
    GUEST_VALUE: "Adult",
  },
  {
    GUEST_NAME: "Child",
    GUEST_VALUE: "Child",
  },
  {
    GUEST_NAME: "Other",
    GUEST_VALUE: "Other",
  },
]

export const CHILDREN_DOCUMENT = ["Child", "Other"];