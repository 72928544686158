import React from "react";
import PropTypes from "prop-types";
import { Button, Input, Spin } from "antd";
import { useIntl } from "react-intl";
import styles from "../../index.module.scss";

const CancelCodeVerification = ({ code, handleConfirmCode, isCancelling, dispatch }) => {
  const intl = useIntl();

  return (
    <div className={styles.enterCode}>
      <p className={styles.description}>
        {intl.formatMessage({ id: "Reservation.Cancel.EnterCode.Description" })}
      </p>
      <Input
        value={code}
        onChange={(e) => dispatch({ type: "SET_CODE", payload: e.target.value })}
        placeholder={intl.formatMessage({ id: "Reservation.Cancel.EnterCode" })}
      />
      <Button
        className={styles.enterCodeButton}
        disabled={!code}
        onClick={handleConfirmCode}
      >
        {isCancelling ? <Spin size="small" /> : intl.formatMessage({ id: "Reservation.Cancel.Button.Finish" })}
      </Button>
    </div>
  );
};

CancelCodeVerification.propTypes = {
  code: PropTypes.string.isRequired,
  handleConfirmCode: PropTypes.func.isRequired,
  isCancelling: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default CancelCodeVerification;