import React from 'react';
import moment from 'moment';
import * as s from '../../index.module.scss';

const HeaderNamesOfTheWeek = () => (
  <div className={s.headerNamesOfTheWeek}>
    {Array.from({ length: 7 }, (_, i) => {
      const dayLabel = moment().startOf('week').add(i, 'days').format('ddd');
      return <div key={dayLabel}>{dayLabel}</div>;
    })}
  </div>
);

export default HeaderNamesOfTheWeek;