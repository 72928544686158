import React from 'react';
import { Button } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import moment from 'moment';
import styles from './index.module.scss';
import { ReactComponent as CarIcon } from '../../../../assets/icons/car.svg';

// Componente principal
const AdditionalFields = ({ fields, onFieldUpdate }) => (
  <div className={styles.container}>
    {fields?.map(({ reservationRoomStayId, customFields, details }) => (
      <div key={reservationRoomStayId} className={styles.reservationSection}>
        {customFields.map((field) => (
          <FormikForm
            key={field.id}
            field={field}
            reservationRoomStayId={reservationRoomStayId}
            onFieldUpdate={onFieldUpdate}
            details={details}
          />
        ))}
      </div>
    ))}
  </div>
);

// Subcomponente para cada campo personalizado
const FormikForm = ({ field, reservationRoomStayId, onFieldUpdate, details }) => {
  const intl = useIntl();
  const formik = useFormik({
    initialValues: {
      value: field.stringValue || '',
    },
    validationSchema: Yup.object({
      value: Yup.string()
        .required(`${intl.formatMessage({ id: 'Checkin.AdditionalFields.Required' })}`)
        .min(3, `${intl.formatMessage({ id: 'Checkin.AdditionalFields.MinLength' })}`),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onFieldUpdate(reservationRoomStayId, field, values.value);
      } catch (error) {
        console.error(`Error actualizando el campo ${field.name}:`, error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className={styles.formRow}>
      <div className={styles.fieldContainer}>
        <div className={styles.tentInfo}>
          <h4>{details?.tent}</h4>
          <div>
            <div>
              <b>{intl.formatMessage({ id: 'Checkin.Summary.Arrival' })}:</b> {moment(details?.arrival).format('DD-MM-YYYY')}
            </div>
            <div>
              <b>{intl.formatMessage({ id: 'Checkin.Summary.Departure' })}:</b> {moment(details?.departure).format('DD-MM-YYYY')}
            </div>
          </div>
        </div>
        <div className={styles.fieldInfo}>
          <div className={styles.icon}>
            <CarIcon />
            <span>{field.name}</span>
          </div>
          <input
            id={`field-${field.id}`}
            name="value"
            type="text"
            placeholder={`Ingrese ${field.name}`}
            value={formik.values.value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`${styles.input} ${formik.touched.value && formik.errors.value ? styles.errorInput : ''}`}
          />
          {formik.touched.value && formik.errors.value && (
            <div className={styles.errorMessage}>{formik.errors.value}</div>
          )}
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveFilled />}
            className={styles.saveButton}
            loading={formik.isSubmitting}
          >
            {intl.formatMessage({ id: 'NameForm.Button.Text' })}
          </Button>
        </div>
      </div>
    </form>
  );
};

// Validación de props para FormikForm
FormikForm.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stringValue: PropTypes.string,
  }).isRequired,
  reservationRoomStayId: PropTypes.number.isRequired,
  onFieldUpdate: PropTypes.func.isRequired,
  details: PropTypes.shape({
    tent: PropTypes.string,
    arrival: PropTypes.string,
    departure: PropTypes.string,
  }).isRequired,
};

// Validación de props para AdditionalFields
AdditionalFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      reservationRoomStayId: PropTypes.number.isRequired,
      customFields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          value: PropTypes.string,
        })
      ).isRequired,
      details: PropTypes.shape({
        tent: PropTypes.string,
        arrival: PropTypes.string,
        departure: PropTypes.string,
      }),
    })
  ).isRequired,
  onFieldUpdate: PropTypes.func.isRequired,
};

export default AdditionalFields;