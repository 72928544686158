/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

const BoxInfo = ({ info, className }) => {
  const { title, texts } = info;
  return (
    <div className={className}>
      <h2>{title}</h2>
      {texts.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </div>
  );
};

BoxInfo.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string.isRequired,
    texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  className: PropTypes.string,
};

BoxInfo.defaultProps = {
  className: "",
};

export default BoxInfo;