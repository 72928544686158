import React, {
  useContext, useMemo,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import NotAvailableScreen from './components/Calendar/components/NotAvailableScreen';
import Calendar from './components/Calendar';
import AvailabilityContext from '../../../../contexts/AvailabilityContext';
import { spinner } from './index.module.scss';
import CustomSpin from '../../../../../CustomSpin';
import calcDaysBetweenCheckInAndCheckOut from '../../../../../../utils/daysBetweenCheckInAndCheckOut';
import { useReservationState } from '../../../../../../services/Reservation';

const getDays = (startDate, endDate, formatOpenDate,
  formatEndDate, firstDayAvailable, endDateFirstDayAvailable) => {
  if (!startDate || !endDate || !formatOpenDate || !formatEndDate
    || !firstDayAvailable || !endDateFirstDayAvailable) {
    return [];
  }

  const calendar = [];
  const now = moment.utc(startDate);
  const formatOpenDateMoment = moment.utc(formatOpenDate);
  const formatEndDateMoment = moment.utc(formatEndDate);

  const isFutureDate = formatOpenDateMoment.isAfter(now);

  const startDay = isFutureDate
    ? formatOpenDateMoment.clone().subtract(1, 'weeks').startOf('week')
    : startDate.clone().subtract(1, 'weeks').startOf('week');

  const endDay = isFutureDate
    ? formatEndDateMoment.clone().add(10, 'weeks').endOf('week')
    : endDate.clone().add(10, 'weeks').endOf('week');

  const date = startDay.clone().subtract(1, 'day');

  while (date.isBefore(endDay, 'day')) {
    calendar.push({
      days: Array(7).fill(0).map(() => date.add(1, 'day').clone()),
    });
  }

  return calendar;
};

const AvailabilityCalendar = ({
  formatOpenDate, formatEndDate,
  startDateAvailable, endDateAvailable,
  setEndDateAvailable, setStartDateAvailable,
  openFrom,
}) => {
  const {
    filteredReferences,
    filteredRates,
    showCalendarUnavailable,
    isLoadingPlans,
  } = useContext(AvailabilityContext);

  const { startDate: _startDate, endDate: _endDate } = useReservationState();

  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(_endDate),
    startDate: moment.utc(_startDate),
  });
  const hasReferenceRates = filteredReferences && filteredReferences.length > 0;
  const firstDayAvailable = moment(filteredRates?.date);
  const endDateFirstDayAvailable = moment(firstDayAvailable).add(daysBetweenCheckInAndCheckOut, 'days');

  const memoizedDays = useMemo(() => getDays(
    firstDayAvailable,
    endDateFirstDayAvailable,
    _startDate,
    _endDate,
    formatOpenDate,
    formatEndDate,
  ), [_startDate, _endDate, formatOpenDate,
    formatEndDate, firstDayAvailable, endDateFirstDayAvailable]);
    

  const renderContent = () => {
    
    if (!showCalendarUnavailable && isLoadingPlans) {
      return <CustomSpin display="block" className={spinner} />;
    }

    if (showCalendarUnavailable && !isLoadingPlans) {
      return <NotAvailableScreen />;
    }

    return (
      <Calendar
        loading={!hasReferenceRates}
        days={memoizedDays}
        startDateAvailable={startDateAvailable}
        endDateAvailable={endDateAvailable}
        setStartDateAvailable={setStartDateAvailable}
        setEndDateAvailable={setEndDateAvailable}
        openFrom={openFrom}
        startDateSelected={_startDate}
        endDateSelected={_endDate}
      />
    );
  };
  return (
    <>
      {renderContent()}
    </>
  );
};

AvailabilityCalendar.propTypes = {
  formatOpenDate: PropTypes.string.isRequired,
  formatEndDate: PropTypes.string.isRequired,
  startDateAvailable: PropTypes.string.isRequired,
  endDateAvailable: PropTypes.string.isRequired,
  setEndDateAvailable: PropTypes.func.isRequired,
  setStartDateAvailable: PropTypes.func.isRequired,
  openFrom: PropTypes.instanceOf(moment).isRequired,
};
AvailabilityCalendar.defaultProps = {};
export default AvailabilityCalendar;
