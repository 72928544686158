import { useCallback } from "react";
import makeRequestToApServer from "../../../services/ApServer/askToApServer";
import { allowedMethods } from "../../../constants/ApServer/apServer";
import routes from "../../../constants/ApServer/apRoutes";
import openNotification from "../../NotificacionMessage";

const useConfirmCode = (locator, propertyId, code, dispatch, intl, onClose) => {
  const handleConfirmCode = useCallback(() => {
    dispatch({ type: "SET_IS_CANCELLING", payload: true });

    makeRequestToApServer(
      allowedMethods.post,
      routes.cancelVerifyCode.replace(":id", locator).replace(":propertyId", propertyId).replace(":code", code),
      {},
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          openNotification(
            "success",
            intl.formatMessage({ id: "Reservation.Cancel.Success" }),
            "",
            () => window.location.reload()
          );
          dispatch({ type: "HIDE_CODE_MODAL" });
          onClose();
        } else {
          openNotification(
            "error",
            data?.message || intl.formatMessage({ id: "Reservation.Cancel.Error" })
          );
        }
        dispatch({ type: "SET_IS_CANCELLING", payload: false });
      },
      (error) => {
        openNotification(
          "error",
          error?.response?.data?.message || error?.message || intl.formatMessage({ id: "Reservation.Cancel.Error" })
        );
        dispatch({ type: "SET_IS_CANCELLING", payload: false });
      }
    );
  }, [locator, propertyId, code, intl, onClose, dispatch]);

  return { handleConfirmCode };
};

export default useConfirmCode;