import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import ROUTES from '../routes/routes';
import { useAuthCheckinState } from './CheckinAuth/useAuth';
import useRefreshToken from '../utils/useRefreshToken';

const CheckBookingsAuthRoute = ({ component: Component, path, location, ...rest }) => {
  const { accessTokenCheckin } = useAuthCheckinState();

  const tokensExpired = useRefreshToken();

  if (tokensExpired) {
    return <Redirect noThrow="true" to={ROUTES.bookingsRegister} />;
  }

  if (accessTokenCheckin == null && path.includes('checkin')) return <Redirect noThrow="true" to={ROUTES.bookingsRegister} />

  return (
    <>
      {accessTokenCheckin != null ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...rest} path={path} location={location} />
      ) : (
        <Redirect noThrow="true" to={ROUTES.bookingsRegister} />
      )}
    </>
  );
};

CheckBookingsAuthRoute.defaultProps = {
  component: null,
  path: '/',
  location: {},
};

CheckBookingsAuthRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  location: PropTypes.shape({
    key: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default CheckBookingsAuthRoute;
