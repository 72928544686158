/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import { Row, Col, Button , Spin } from 'antd';
import { RightOutlined, SaveOutlined, LeftOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import StepNavigator from './components/StepNavigator';
import ReservationSummary from './components/ReservationSummary';
import AccommodationCard from './components/AccommodationCard';
import GuestList from './components/GuestList';
import AdditionalFields from './components/AdditionalFields';
import styles from './index.module.scss';
import StepHeader from './components/StepNavigator/components/StepHeader';
import WelcomeSignature from './components/WelcomeSignature';
import CombinedComponent from './components/CombinedComponent';
import makeRequestToApCheckinServer from '../../services/ApServer/askCheckinToApServer';
import { allowedMethods } from '../../constants/ApServer/apServer';
import routes from '../../constants/ApServer/apRoutes';
import openNotification from '../../components/NotificacionMessage';
import { clearCheckinTokens } from '../../utils/tokenHelper';
import ROUTES from '../../routes/routes';

const CheckinFormPage = () => {
  const intl = useIntl();
  const [currentStep, setCurrentStep] = useState(0);
  const [reservationDetails, setReservationDetais] = useState();
  const [guests, setGuests] = useState([]);
  const [additionalFields, setAdditionalFields] = useState();
  const [countriesList, setCountries] = useState();
  const [documentTypes, setDocumentTypes] = useState();
  const [familyRelationships, setFamilyRelationships] = useState();
  const [genderTypes, setGenderTypes] = useState();
  const [languages, setLanguages] = useState();
  const [municipalities, setMunicipalities] = useState();
  const [stateProvs, setStateProvs] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const stepNames = [
    `${intl.formatMessage({ id: 'Checkin.Step.AdditionalFields' })}`,
    `${intl.formatMessage({ id: 'Checkin.Step.ExtraServices' })}`,
    `${intl.formatMessage({ id: 'Checkin.Step.LateCheckin' })}`,
    `${intl.formatMessage({ id: 'Checkin.Step.WelcomeSignature' })}`,
    `${intl.formatMessage({ id: 'Checkin.Step.Finish' })}`
  ];

  const finishModal = () => {
    openNotification(
      'success',
      reservationDetails?.checkinMessage,
      '',
      () => {
        clearCheckinTokens();
        window.location.href = `${ROUTES.bookingsRegister}`
      }
      ,
      null
    );
  };

  const areAllGuestsCompleted = () => guests.every((guest) => guest.isCheckinOnline);

  const handleGuestUpdate = (updatedGuest) => {
    setGuests((prevGuests) =>
      prevGuests.map((guest) =>
        guest.id === updatedGuest.id
          ? { ...updatedGuest, isCheckinOnline: true }
          : guest
      )
    );
  };

  const handleAdditionalFieldsUpdate = async (reservationRoomStayId, field, newValue) => {
    console.log(`Updating field ${field} for reservation ${reservationRoomStayId} with value: ${newValue}`);
    try {
  
      const response = await makeRequestToApCheckinServer(
        allowedMethods.put,
        routes.bookingsUpdateCustomFields.replace(':id', reservationRoomStayId),
        field,
        ({ statusCode, data }) => {
          if (statusCode === 200) {
            console.log('Campo actualizado exitosamente:', data);
            return data;
          } 
            throw new Error(`Error al actualizar el campo: ${statusCode}`);
          
        },
        (error) => {
          console.error(`Error en la solicitud: ${error}`);
          throw error;
        }
      );
  
      return response;
    } catch (error) {
      console.error('Error al actualizar el campo:', error);
      throw error;
    }
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
  };

  const renderContent = () => {
    let content;
    switch (currentStep) {
      case 0:
        content = (
        <>
          <StepHeader 
            stepNumber={1}
            title={intl.formatMessage({ id: 'Checkin.Step.GuestsForm' })}
            tooltipText={intl.formatMessage({ id: 'Checkin.Step.GuestsForm.tooltip' })}
          />
          <GuestList
            guests={guests}
            onGuestUpdate={handleGuestUpdate}
            countries={countriesList}
            documentTypes={documentTypes}
            familyRelationships={familyRelationships}
            genderTypes={genderTypes}
            languages={languages}
            municipalities={municipalities}
            stateProvs={stateProvs}
            arrivalDate={reservationDetails?.arrival}
          />
        </>
        );
        break;
      case 1:
        if (areAllGuestsCompleted()) {
          content = (
            <>
              <StepHeader
                stepNumber={2}
                title={intl.formatMessage({ id: 'Checkin.Step.AdditionalFields' })}
                tooltipText={intl.formatMessage({ id: 'Checkin.Step.AdditionalFields.tooltip' })}
              />
              <AdditionalFields fields={additionalFields} onFieldUpdate={handleAdditionalFieldsUpdate} />
            </>
            )
        } else {
          setCurrentStep(0);
        }
        
        break;
      case 2:
        content = (
          <>
            <StepHeader stepNumber={3} title={intl.formatMessage({ id: 'Checkin.Step.ExtraServices' })} />
            <div>Contenido de Servicios Extra</div>
          </>
        );
        handleNextStep();
        break;
      case 3:
        content = (
          <>
            <StepHeader stepNumber={3} title={intl.formatMessage({ id: 'Checkin.Step.ExtraServices' })} />
            <div>Contenido de Servicios Extra</div>
          </>
        );
        handleNextStep();
        break;
      case 4:
        if (areAllGuestsCompleted()) {
          const reservationRoomStayIds = Array.from(
            new Set(reservationDetails.details?.map((detail) => detail.reservationRoomStayId))
          );
          content = (
            <>
              <StepHeader
                stepNumber={5}
                title={intl.formatMessage({ id: 'Checkin.Step.WelcomeSignature' })}
                tooltipText={intl.formatMessage({ id: 'Checkin.Step.WelcomeSignature.tooltip' })}
              />
              <WelcomeSignature reservationRoomStays={reservationRoomStayIds} />
            </>
          );
        } else {
          setCurrentStep(0);
        }
        
        break;
      default:
        // setCurrentStep(0);
        break;
    }

    return (
      <div className={styles.renderContent}>
        {content}
        <div className={`${styles.buttonContainer} ${
          currentStep === 0 ? styles.stepZero : ''
        }`}>
          {currentStep > 0 && (
            <Button
              onClick={() =>
                setCurrentStep((prevStep) =>
                  currentStep === stepNames.length - 1 ? 1 : prevStep - 1
                )
              }
              icon={<LeftOutlined />}
              className={styles.backButton}
            >
              {intl.formatMessage({ id: 'PrivateAreaSettings.Button.Text' })}
            </Button>
          )}
          
          <Button
            onClick={currentStep < stepNames.length - 1 ? handleNextStep : finishModal}
            style={{ marginTop: 16 }}
            icon={currentStep < stepNames.length - 1 ? <RightOutlined /> : <SaveOutlined />}
            className={styles.nextButton}
            disabled={currentStep === 0 && !areAllGuestsCompleted()}
          >
            {stepNames[currentStep]}
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const bookingsDetailsPromise = makeRequestToApCheckinServer(
          allowedMethods.get,
          routes.bookingsDetails,
          undefined,
          async ({ statusCode, data }) => {
            if (statusCode === 200) {
              setReservationDetais(data);
              const allGuests = data.details?.flatMap((detail) => {
                const { tent, reservationRoomStayId, arrival, departure, guests: guestDetail, adult, child } = detail;
                return guestDetail.map((guest, index) => ({
                  ...guest,
                  tent,
                  reservationRoomStayId,
                  arrival,
                  departure,
                  role: index < adult ? 'Adult' : index < adult + child ? 'Child' : 'Other',
                }));
              }) || [];
              setGuests(allGuests);

              const reservationRoomStayIds = Array.from(
                new Set(data.details?.map((detail) => detail.reservationRoomStayId))
              );

              try {
                const results = await Promise.all(
                  reservationRoomStayIds?.map((id) => new Promise((resolve, reject) => {
                      makeRequestToApCheckinServer(
                        allowedMethods.get,
                        routes.bookingsCustomFields.replace(':id', id),
                        undefined,
                        ({ statusCode: statusCode2, data: data2 }) => {
                          if (statusCode2 === 200) {
                            resolve({
                              reservationRoomStayId: id,
                              details: data.details?.find((room) => room.reservationRoomStayId === id),
                              
                              customFields: data2,
                            });
                          } else {
                            reject(new Error(`Error fetching custom fields for id: ${id}`));
                          }
                        },
                        (error) => {
                          console.error(`Error in request for id ${id}:`, error);
                          reject(error);
                        }
                      );
                    }))
                );
                setAdditionalFields(results);
              } catch (error) {
                console.error('Error fetching custom fields:', error);
              }
            }
          },
          () => {}
        );

        const bookingsFieldsPromise = makeRequestToApCheckinServer(
          allowedMethods.get,
          routes.bookingsFields,
          { countryId: 199 },
          ({ statusCode, data }) => {
            if (statusCode === 200) {
              const { countries, documentType, familyRelationship, genderType, language, municipality, stateProv } = data;
              setCountries(countries);
              setDocumentTypes(documentType);
              setFamilyRelationships(familyRelationship);
              setGenderTypes(genderType);
              setLanguages(language);
              setMunicipalities(municipality);
              setStateProvs(stateProv);
            }
          },
          () => {}
        );

        await Promise.all([bookingsDetailsPromise, bookingsFieldsPromise]);

        const bookingsCustomFieldsPromise = makeRequestToApCheckinServer(
          allowedMethods.get,
          routes.bookingsFields,
          { countryId: 199 },
          ({ statusCode, data }) => {
            if (statusCode === 200) {
              const { countries, documentType, familyRelationship, genderType, language, municipality, stateProv } = data;
              setCountries(countries);
              setDocumentTypes(documentType);
              setFamilyRelationships(familyRelationship);
              setGenderTypes(genderType);
              setLanguages(language);
              setMunicipalities(municipality);
              setStateProvs(stateProv);
            }
          },
          () => {}
        );

        await Promise.all([bookingsCustomFieldsPromise]);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spin size="large" />
      </div>
    );
  }

  if(reservationDetails && !reservationDetails?.allowCheckinOnline) {
    openNotification(
      'error',
      '',
      `${intl.formatMessage({ id: 'Checkin.NotAllowed' })}`,
      () => {
        clearCheckinTokens();
        window.location.href = `${ROUTES.bookingsRegister}`
      }
      ,
      null
    );
  }

  return (
  <div className={styles.container}>
    {/* Desktop Layout */}
    <div className={styles.desktopLayout}>
      <Row gutter={16} className={styles.row}>
        <Col span={6}>
          <StepNavigator currentStep={currentStep} onStepChange={setCurrentStep} areAllGuestsCompleted={areAllGuestsCompleted}/>
          <ReservationSummary reservationDetails={reservationDetails} />
          <AccommodationCard reservationDetails={reservationDetails}/>
        </Col>
        <Col span={18}>
          {renderContent()}
        </Col>
      </Row>
    </div>

    {/* Tablet Layout */}
    <div className={styles.tabletLayout}>
      <Row gutter={16}>
        <Col span={12}>

          <CombinedComponent currentStep={currentStep} onStepChange={setCurrentStep} reservationDetails={reservationDetails}/>
        </Col>
        <Col span={12}>
          <AccommodationCard reservationDetails={reservationDetails} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {renderContent()}
        </Col>
      </Row>
    </div>

    {/* Mobile Layout */}
    <div className={styles.mobileLayout}>
      <Row gutter={16}>
        <Col span={24}>
          <CombinedComponent currentStep={currentStep} onStepChange={setCurrentStep} reservationDetails={reservationDetails}/>
          {renderContent()}
        </Col>
      </Row>
    </div>
  </div>
)};

export default CheckinFormPage;