const groupDaysByMonth = (daysArray) => {
  const allDays = daysArray.flatMap((week) => week.days);
  return allDays.reduce((acc, dayMoment) => {
    const monthKey = dayMoment.format('YYYY-MM');
    if (!acc[monthKey]) {
      acc[monthKey] = [];
    }
    acc[monthKey].push(dayMoment);
    return acc;
  }, {});
};

export default groupDaysByMonth;