/* eslint-disable no-param-reassign */
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Necesitas instalar este paquete con `npm install jwt-decode`
import { URL } from '../constants/ApServer/apServer';
import routes from '../constants/ApServer/apRoutes';
import { updateTokens, clearTokens, clearCheckinTokens } from '../utils/tokenHelper';
import ROUTES from '../routes/routes';
import {
  isRefreshRequest,
  subscribeTokenRefresh,
  onRefreshed,
  setRefreshing,
  getRefreshing,
  onRefreshFailed,
} from './tokenManager';

const prefix = '/api';

const allowedlang = ['es', 'en', 'fr', 'it', 'pt'];
const defaultLang = 'es';

const excludedRoutes = [
  routes.bookings,
];

const apCheckinAxios = axios.create({
  baseURL: `${URL}${prefix}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const goToRegister = () => {
  const redirect = window.location.pathname; // Solo la ruta base sin query params
  
  clearCheckinTokens(); // Limpia cualquier token existente
  window.location.href = `${ROUTES.bookingsRegister}?redirect=${encodeURIComponent(
    redirect
  )}`;
  
};

const isTokenExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  } catch (e) {
    return true;
  }
};

const requestInterceptorQueue = (config) => {
  if (getRefreshing() && !isRefreshRequest(config, routes.authRefresh)) {
    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        const newConfig = { ...config };
        newConfig.headers.Authorization = `Bearer ${token}`;
        resolve(newConfig);
      });
    });
  }

  if (getRefreshing() && isRefreshRequest(config, routes.authRefresh)) {
    return config;
  }

  if (!getRefreshing() && isRefreshRequest(config, routes.authRefresh)) {
    setRefreshing(true);
    return config;
  }

  return config;
};

const requestInterceptor = async (config) => {
  const accessToken = localStorage.getItem('accessTokenCheckin'); // Obtén el token de acceso
  const isExcludedRoute = excludedRoutes.includes(config.url); // Verifica si la ruta está excluida

  if (!accessToken && !isExcludedRoute) {
    goToRegister(); // Redirige si no existe el token y la ruta no está excluida
    return Promise.reject(new Error('Access token is missing')); // Detén la solicitud
  }

  if (accessToken && isTokenExpired(accessToken)) {
    goToRegister(); // Redirige si el token ha expirado
    return Promise.reject(new Error('Access token has expired')); // Detén la solicitud
  }

  // Si el token es válido, añádelo al encabezado de autorización
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  const countryCode = localStorage.getItem('countryCode');
  // Agrega el parámetro `lang` basado en el código de país
  config.params = {
    ...config.params,
    lang: allowedlang.includes(countryCode) ? countryCode : defaultLang,
  };

  return config;
};

const responseInterceptor = async (response) => {
  if (
    getRefreshing() &&
    isRefreshRequest(response.config, routes.authRefresh)
  ) {
    setRefreshing(false);

    // eslint-disable-next-line camelcase
    const { access_token, refresh_token } = response.data.data;
    updateTokens(access_token, refresh_token);
    onRefreshed(access_token);
  }
  return response;
};

const errorInterceptor = async (error) => {
  const originalRequest = error?.config;
  if (originalRequest && !originalRequest?.retryCount) {
    originalRequest.retryCount = 0;
  }

  const isExcludedRoute = excludedRoutes.includes(originalRequest?.url);

  if (
    (error?.response?.status === 401 || error?.response?.status === 403) &&
    !isExcludedRoute
  ) {
    if (!getRefreshing()) {
      setRefreshing(true);
      const refresh = localStorage.getItem('refreshToken');

      try {
        const response = await apCheckinAxios.post(routes.authRefresh, {
          refresh_token: refresh,
        });

        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = response.data.data;
        updateTokens(access_token, refresh_token);
        setRefreshing(false);
        onRefreshed(access_token);

        // eslint-disable-next-line camelcase
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return apCheckinAxios(originalRequest);
      } catch (refreshError) {
        clearTokens();
        onRefreshFailed(refreshError);
        goToRegister();
        return Promise.reject(refreshError);
      }
    }

    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(apCheckinAxios(originalRequest));
      });
    });
  }

  if (error?.response?.status >= 500 && originalRequest?.retryCount < 3) {
    originalRequest.retryCount += 1;
    return apCheckinAxios(originalRequest);
  }

  return Promise.reject(error);
};

apCheckinAxios.interceptors.request.use(requestInterceptorQueue, (error) =>
  Promise.reject(error),
);
apCheckinAxios.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error),
);
apCheckinAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

export default apCheckinAxios;