import apCheckinAxios from '../../interceptors/apCheckinAxios';
import { allowedMethods } from '../../constants/ApServer/apServer';

const makeRequestToApCheckinServer = async (
  method,
  path,
  data,
  onSuccess,
  onError,
) => {
  try {
    const methodLowerCase = method.toLowerCase();
    if (
      !Object.prototype.hasOwnProperty.call(allowedMethods, methodLowerCase)
    ) {
      throw new Error(`HTTP method ${method} is not supported.`);
    }
    let response;
    if (methodLowerCase === 'get') {
      response = await apCheckinAxios.get(`${path}`, { params: data });
    } else if (methodLowerCase === 'delete' && data) {
      response = await apCheckinAxios.delete(`${path}`, { data });
    } else {
      response = await apCheckinAxios[methodLowerCase](`${path}`, data);
    }

    if (onSuccess) onSuccess(response.data);
  } catch (error) {
    if (onError) onError(error);
    throw error;
  }
};

export default makeRequestToApCheckinServer;
