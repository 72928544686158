import momentES from 'moment';
import moment from 'moment-timezone';
import Services from '../../constants/services';
import { adults, boys, babies } from '../../constants/typeOfPersons';
import { hourIsAboveOrEqualLimitForToday } from '../hoursBuyingRestrictions';
import { getItemWithExpiryTime } from '../localStorageWithExpiration';

// Moment configuration
require('moment/locale/es');

momentES.locale('es');
// eslint-disable-next-line no-underscore-dangle
moment.defineLocale('es', momentES.localeData()._config);
moment.locale('es');
moment.tz.setDefault('Etc/UTC');

const testingFutureStartDate = moment('2021-10-10T22:30:00.000Z')
  .hour(12)
  .minutes(0)
  .seconds(0);
const tomorrowAtTwelve = moment().add(1, 'days').hour(12).minutes(0);
const todayAtTwelve = moment().hour(12).minutes(0);
const localStoredStartDate = moment(getItemWithExpiryTime('startDate'));

const testingFutureEndDate = moment('2021-10-13T22:30:00.000Z')
  .hour(12)
  .minutes(0)
  .seconds(0);
const threeDaysAfterAtTwelve = moment().add(3, 'days').hour(12).minutes(0);
const twoDaysAfterAtTwelve = moment().add(2, 'days').hour(12).minutes(0);
const localStoredEndDate = moment(getItemWithExpiryTime('endDate'));

const decideStartDate = () => {
  if (process.env.REACT_APP_TESTING_MODE) {
    return testingFutureStartDate;
  }
  if (hourIsAboveOrEqualLimitForToday()) {
    return getItemWithExpiryTime('startDate') &&
      localStoredStartDate < localStoredEndDate &&
      localStoredStartDate > tomorrowAtTwelve
      ? localStoredStartDate
      : tomorrowAtTwelve;
  }
  return getItemWithExpiryTime('startDate') &&
    localStoredStartDate < localStoredEndDate &&
    localStoredStartDate > todayAtTwelve
    ? localStoredStartDate
    : todayAtTwelve;
};

const decideEndDate = () => {
  if (process.env.REACT_APP_TESTING_MODE) {
    return testingFutureEndDate;
  }
  if (hourIsAboveOrEqualLimitForToday()) {
    return getItemWithExpiryTime('endDate') &&
      localStoredEndDate > localStoredStartDate &&
      localStoredEndDate > threeDaysAfterAtTwelve
      ? localStoredEndDate
      : threeDaysAfterAtTwelve;
  }
  return getItemWithExpiryTime('endDate') &&
    localStoredEndDate > localStoredStartDate &&
    localStoredEndDate > twoDaysAfterAtTwelve
    ? localStoredEndDate
    : twoDaysAfterAtTwelve;
};

const initialState = () => ({
  readyToBeUsed: !!process.env.REACT_APP_TESTING_MODE,
  ratePlansFetched: !!process.env.REACT_APP_TESTING_MODE,
  startDate: decideStartDate(),
  endDate: decideEndDate(),
  [adults]: 2,
  [boys]: 0,
  [babies]: 0,
  lastTypeOfPersonIncreased: undefined,
  selectedHotel: process.env.REACT_APP_TESTING_MODE
    ? 'Kampaoh GO (Sevilla)'
    : undefined,
  selectedHotelId: process.env.REACT_APP_TESTING_MODE ? '197280' : undefined,
  selectedCampingName: process.env.REACT_APP_TESTING_MODE
    ? 'Centro Covey Sevilla'
    : undefined,
  selectedRoomTypeId: process.env.REACT_APP_TESTING_MODE ? 329895 : undefined,
  selectedRoomType: process.env.REACT_APP_TESTING_MODE
    ? 'Glamper XL'
    : undefined,
  selectedRateId: process.env.REACT_APP_TESTING_MODE ? 855515 : undefined,
  totalPrice: process.env.REACT_APP_TESTING_MODE ? 1700 : undefined,
  priceByNight: process.env.REACT_APP_TESTING_MODE ? 0 : undefined,
  guestFirstName: process.env.REACT_APP_TESTING_MODE ? 'Test' : undefined,
  guestLastName: process.env.REACT_APP_TESTING_MODE ? 'API' : undefined,
  guestGender: process.env.REACT_APP_TESTING_MODE ? 'M' : undefined,
  guestCountry: process.env.REACT_APP_TESTING_MODE ? '' : undefined,
  guestCity: process.env.REACT_APP_TESTING_MODE ? '' : undefined,
  guestZip: process.env.REACT_APP_TESTING_MODE ? '41930' : undefined,
  guestEmail: process.env.REACT_APP_TESTING_MODE
    ? 'pablo@secmotic.com'
    : undefined,
  guestPhone: process.env.REACT_APP_TESTING_MODE ? '34666777888' : undefined,
  guestPhoneFormField: process.env.REACT_APP_TESTING_MODE
    ? '666777888'
    : undefined,
  guestCompany: process.env.REACT_APP_TESTING_MODE ? 'Otro' : undefined,
  guestMeetUsBy: process.env.REACT_APP_TESTING_MODE ? 'Otro' : undefined,
  guestDocumentType: process.env.REACT_APP_TESTING_MODE ? '5' : undefined,
  guestIdentity: process.env.REACT_APP_TESTING_MODE ? '20020020G' : undefined,
  guestPartyAccepted: process.env.REACT_APP_TESTING_MODE
    ? 'Aceptado en la web (API)'
    : undefined,
  guestClub: !!process.env.REACT_APP_TESTING_MODE,
  acceptPartyPolicy: !!process.env.REACT_APP_TESTING_MODE,
  acceptImpactPolicy: !!process.env.REACT_APP_TESTING_MODE,
  acceptReservationParticularPolicy: !!process.env.REACT_APP_TESTING_MODE,
  acceptReservationPolicy: !!process.env.REACT_APP_TESTING_MODE,
  acceptPrivacyPolicy: !!process.env.REACT_APP_TESTING_MODE,
  KMPolicy: !!process.env.REACT_APP_TESTING_MODE,
  ArrivalPolicy: !!process.env.REACT_APP_TESTING_MODE,
  BailPolicy: !!process.env.REACT_APP_TESTING_MODE,
  ReservationPolicy: !!process.env.REACT_APP_TESTING_MODE,
  PickUpPolicy: !!process.env.REACT_APP_TESTING_MODE,
  UsePolicy: !!process.env.REACT_APP_TESTING_MODE,
  promoCode: '',
  defaultPropertyID: '',
  kampaohService: process.env.REACT_APP_TESTING_MODE
    ? Services.CAMP
    : Services.CAMP,
  preferredLocation: undefined,
  preferredZoneName: undefined,
  preferredZoneId: undefined,
  preferredCampLocation: undefined,
  preferredGoLocation: undefined,
  updatingDates: false,
  ratePlanPromoCode: undefined,
  ratePlanNamePrivate: undefined,
  ratePlanNamePublic: undefined,
  ratePropertyPolicy: undefined,
  ratePropertyPolicyId: undefined,
  pets: false,
  petsOnCamping: false,
  updatingAvailability: false,
  reservationId: '',
});

export default initialState;
