import React, { useContext, useRef } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import AvailabilityContext from '../../../../../../../contexts/AvailabilityContext';
import { isDateRangeValid } from '../utils/calendarUtils';

const useCalendarRestrictions = ({
  setStartDate,
  setStartDateAvailable,
  setEndDate,
  setEndDateAvailable,
  openFrom,
}) => {
  const firstTry = useRef(true);
  const { onError } = useContext(AvailabilityContext);

  const applyRestrictions = (day, rate, startDate, endDate) => {
    const isClosedToArrival = rate && rate.closedToArrival;
    const isClosedToDeparture = rate && rate.closedToDeparture;
    const hasRooms = rate && rate.quantityAvailable > 0;
    const hasAmount = rate && rate.amountAfterTax > 0;
    const openFromDate = moment(openFrom);
  
    if (
      firstTry.current &&
      hasRooms &&
      hasAmount &&
      !day.isBefore(openFromDate, 'day')
    ) {
      setStartDate(day.clone().add(12, 'hours'));
      setStartDateAvailable(day.clone().add(12, 'hours'));
      setEndDate(null);
      setEndDateAvailable(null);
      firstTry.current = false;
      return;
    }
    if (startDate && endDate && hasRooms && hasAmount) {
      if (day.isBefore(startDate, 'day')) {
        setStartDate(day.clone().add(12, 'hours'));
        setStartDateAvailable(day.clone().add(12, 'hours'));
        setEndDate(null);
        setEndDateAvailable(null);
      }
    }
    if (startDate && day.isAfter(startDate, 'day') && hasRooms && hasAmount) {
      if (!isDateRangeValid(startDate, day.clone().add(12, 'hours'))) {
        onError(
          <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidRange" />
        );
      }
      if (isClosedToDeparture) {
        onError(
          <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckout" />
        );
      }
      setEndDate(day.clone().add(12, 'hours'));
      setEndDateAvailable(day.clone().add(12, 'hours'));
    }
    if (
      !startDate &&
      !endDate &&
      hasRooms &&
      hasAmount &&
      !day.isBefore(openFromDate, 'day')
    ) {
      if (isClosedToArrival) {
        onError(
          <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckin" />
        );
      }
      setStartDate(day.clone().add(12, 'hours'));
      setStartDateAvailable(day.clone().add(12, 'hours'));
      setEndDate(null);
      setEndDateAvailable(null);
    }
  };

  return applyRestrictions;
};

export default useCalendarRestrictions;