/* eslint-disable prefer-promise-reject-errors */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const isValidSpanishDocument = (doc) => {
  if (!doc) return false;

  const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
  if (nieRegex.test(doc.toUpperCase())) {
    const niePrefixes = { X: 0, Y: 1, Z: 2 };
    const nieNumber = doc.replace(/^[XYZ]/, (match) => niePrefixes[match]); // Reemplaza la letra inicial
    const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const number = parseInt(nieNumber, 10); // Convierte el NIE en número correctamente
    const letter = doc[8];

    if (letters[number % 23] === letter) {
      return "NIE"; // NIE válido
    }
  }

  const dniRegex = /^[0-9]{8}[A-Z]$/;
  if (dniRegex.test(doc.toUpperCase())) {
    const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const number = parseInt(doc.slice(0, 8), 10);
    const letter = doc[8];

    if (letters[number % 23] === letter) {
      return "DNI"; // DNI válido
    }
  }

  return false; // No es válido ni como NIE ni como DNI
};

const rules = {
  Email: [
    {
      type: 'email',
      message: <FormattedMessage id="UserForm.Validation.Email.Valid" />,
    },
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Email.Required" />,
    },
    {
    // eslint-disable-next-line no-useless-escape
      pattern: /^(.(?!\.con$))+$/i,
      message: <FormattedMessage id="UserForm.Validation.Email.ConEnded" />,
    },
  ],
  Name: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Name.Required" />,
    },
  ],
  Surname: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Surname.Required" />,
    },
  ],
  Phone: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Phone.Required" />,
    },
    {
      // eslint-disable-next-line no-useless-escape
      pattern: /^[0-9]{3}[-\s\.]?[0-9]{3,7}$/im,
      message: <FormattedMessage id="UserForm.Validation.Phone.Valid" />,
    },
  ],
  DocumentType: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.DocumentType.Required" />,
    },
  ],
  DNI: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.DNI.Required" />,
    },
    ({ getFieldValue, setFieldsValue }) => ({
      validator(_, value) {
        const country = getFieldValue('Country');
  
        if (country === 'ES') {
          const documentValidation = isValidSpanishDocument(value.toUpperCase());
          if (!documentValidation) {
            return Promise.reject(
              <FormattedMessage id="UserForm.Validation.DNI.Invalid" />
            );
          }
  
          if (documentValidation === "NIE") {
            setFieldsValue({ DocumentType: '23' }); // Cambia a 23 si es NIE
          }
        } else {
          setFieldsValue({ DocumentType: '23' }); // Si no es España, cambiar a 23
        }
  
        return Promise.resolve();
      },
    }),
  ],
  COMP: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Company.Required" />,
    },
  ],
  FUENTE: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Meet.Required" />,
    },
  ],
  Genre: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Genre.Required" />,
    },
  ],
  Country: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Country.Required" />,
    },
  ],
  PostalCode: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.PostalCode.Required" />,
    },
  ],
};

export default rules;
