const routes = {
  authVerify: '/auth/verify',
  authLogin: '/auth/login',
  authRegister: '/auth/register',
  authMe: '/auth/me',
  authRefresh: '/auth/refresh',
  emailVerify: '/email/verify',
  userSummary: '/me/travels/sumup',
  upconmingTrip: '/me/travels/upcoming',
  pastTrip: '/me/travels/past',
  authEmailVerification: '/auth/email/verification',
  authPasswordReset: '/auth/reset-password',
  authPassword: '/auth/password',
  socialLoginGoogle: '/social-login/google',
  socialLoginFacebook: '/social-login/facebook',
  settingsUnsubscribe: '/settings/unsubscribe',
  settingsUser: '/settings/user',
  bookings: '/bookings',
  bookingsDetails: '/bookings/details',
  bookingsFields: '/bookings/fields',
  bookingsUpdateGuest: '/bookings/:id/checkin/:guestId',
  bookingsCustomFields: '/bookings/:id/customField',
  bookingsUpdateCustomFields: '/bookings/:id/vehicle',
  bookingsUpdateSignature: '/bookings/:id/signature',
  bookingsStatus: '/bookings/:id/property/:propertyId/status',
  cancelBooking: '/bookings/:id/property/:propertyId/cancel',
  cancelVerify: '/bookings/:id/property/:propertyId/cancel/verify',
  cancelVerifyCode: '/bookings/:id/property/:propertyId/cancel/:code/verify',
};

export default routes;
