import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { useIntl } from "react-intl";
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone.svg';
import styles from "../../index.module.scss";

const CancelContactInfo = ({ phoneNumber }) => {
  const intl = useIntl();

  return (
    <div className={styles.infoContainer}>
      <span>{intl.formatMessage({ id: "Reservation.Cancel.Info" })}</span>
      <Button
        className={styles.telButton}
        icon={<PhoneIcon />}
        onClick={() => {}}
      >
        {phoneNumber}
      </Button>
    </div>
  );
};

CancelContactInfo.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default CancelContactInfo;