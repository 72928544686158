/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef } from 'react';
import { Button } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SignaturePad from 'react-signature-canvas';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import makeRequestToApCheckinServer from '../../../../services/ApServer/askCheckinToApServer';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import routes from '../../../../constants/ApServer/apRoutes';
import { useIntl } from 'react-intl';

const createPayloads = (reservationRoomStays, signature) =>
  reservationRoomStays.map((roomStayId) => ({
    reservationRoomStayId: roomStayId,
    signature,
  }));

const sendSignatures = async (payloads) => Promise.all(
    payloads.map((payload) =>
      makeRequestToApCheckinServer(
        allowedMethods.put,
        routes.bookingsUpdateSignature.replace(':id', payload.reservationRoomStayId),
        { signature: payload.signature },
        ({ statusCode, data }) => {
          if (statusCode === 200) {
            console.log(`Firma guardada para roomStay ${payload.reservationRoomStayId}:`, data);
          } else {
            throw new Error(`Error al guardar la firma para roomStay ${payload.reservationRoomStayId}: ${statusCode}`);
          }
        },
        (error) => {
          console.error(`Error en la solicitud para roomStay ${payload.reservationRoomStayId}:`, error);
        }
      )
    )
  );

const WelcomeSignature = ({ reservationRoomStays }) => {
  const intl = useIntl();
  const signaturePadRef = useRef();

  const validationSchema = Yup.object({
    signature: Yup.string().required(`${intl.formatMessage({ id: 'Checkin.Signature.Required' })}`),
  });

  const formik = useFormik({
    initialValues: {
      signature: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const payloads = createPayloads(reservationRoomStays, values.signature);
        await sendSignatures(payloads);
        console.log('Todas las firmas se guardaron correctamente.');
      } catch (error) {
        console.error('Error al guardar las firmas:', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSignatureEnd = () => {
    const signatureData = signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png');
    formik.setFieldValue('signature', signatureData);
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container}>
      <div className={styles.signatureContainer}>
        <SignaturePad
          ref={signaturePadRef}
          canvasProps={{ className: styles.signaturePad }}
          onEnd={handleSignatureEnd}
        />
        {formik.errors.signature && formik.touched.signature && (
          <div className={styles.error}>{formik.errors.signature}</div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          htmlType="submit"
          icon={<SaveFilled />}
          className={styles.submitButton}
          loading={formik.isSubmitting}
        >
          {intl.formatMessage({ id: 'NameForm.Button.Text' })}
        </Button>
      </div>
    </form>
  );
};

WelcomeSignature.propTypes = {
  reservationRoomStays: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default WelcomeSignature;