import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Modal } from 'antd';
import { ReactComponent as LocationIcon } from '../../assets/icons/location3.svg';
import { ReactComponent as KampaohUpconmingTripIcon } from '../../assets/icons/kampaoh-upcoming-trip-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as RingIcon } from '../../assets/icons/icon_ring.svg';
import styles from './index.module.scss';
import decideImageSize from '../../utils/decideImageSize';
import Characteristics from '../../pages/Checkout/steps/OrderResume/components/Characteristics';
import { getCampCharacteristics } from '../../pages/Checkout/steps/OrderResume/data';
import CancelReservation from '../CancelReservation';

const Postal = ({ trip, onClose, custom, showCancel, anonymousCancel }) => {
  const intl = useIntl();
  const [decideImageSizeState, setDecideImageSizeState] = useState(
    decideImageSize(),
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {adults, children, babies, startDate, endDate, selectedRoomType, totalPrice, selectedHotel, selectedCampingName} = trip;

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    const handleOrientationChange = () => {
      setDecideImageSizeState(decideImageSize());
    };
    mediaQuery.addListener(handleOrientationChange);
    if (!custom) document.body.style.overflow = 'hidden';
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
      document.body.style.overflow = 'auto';
    };
  }, [custom]);

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalVisible]);

  const formattedDate = useCallback(
    (date) => {
      const momentDate = moment(date).locale(intl.locale);
      let formatedDate = momentDate.format('ddd, DD MMM');

      if (intl.locale.startsWith('pt')) {
        formatedDate = formatedDate.replace(/\./g, '');
      }

      return formatedDate;
    },
    [intl],
  );

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div>
      <div
        className={!custom ? styles.postalOverlay : styles.postalOverlayCustom}
        onClick={() => onClose()}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        aria-label="Close"
      >
        <div
          className={!custom ? styles.postalContent : styles.customContent}
          role="none"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onKeyDown={() => {}}
        >
          <div className={styles.imageSection}>
            <img
              src={trip?.camping?.featuredImage[decideImageSizeState]?.url || "https://prod-strapi-507053028649-london.s3.eu-west-2.amazonaws.com/medium_Kampaoh_El_Palmar_6_3d7666e6e6.jpg"}
              alt=""
              className={styles.image}
            />
          </div>

          <div className={styles.infoSection}>
            <KampaohUpconmingTripIcon className={styles.kampaohIcon} />
            <div className={styles.reservationCode}>
              <h2>{trip?.reservationLocator}</h2>
            </div>
            <div className={styles.location}>
              <LocationIcon />
              <div>
                <h2>{trip?.camping?.campingName || selectedHotel}</h2>
                <p>{trip?.camping?.location || selectedCampingName}</p>
              </div>
            </div>
            <div className={styles.details}>
            <div className={styles.details}>
                <Characteristics
                  data={getCampCharacteristics(trip?.tent?.name || selectedRoomType, {
                    campingName: trip?.camping?.campingName || selectedHotel,
                    freeParking: trip?.camping?.freeParking,
                  })}
                />
              </div>
              <div className={styles.checkInOutContainer}>
                <div className={styles.datesContainer}>
                  <div>
                    <span>
                      {intl.formatMessage({ id: 'Postal.StartDate.Text' })}
                    </span>
                    <span>{formattedDate(trip?.checkin || startDate)}</span>   
                  </div>
                  <div>
                    <span>
                      {intl.formatMessage({ id: 'Postal.EndDate.Text' })}
                    </span>
                    <span>{formattedDate(trip?.checkout || endDate)}</span>
                  </div>
                </div>
                <div className={styles.datesContainer}>
                  <div>
                    <Button icon={<RingIcon/>} className={styles.lateEarlyCheckinButton}>{intl.formatMessage({ id: 'Reservation.Arrival.Button' })}</Button> 
                    
                  </div>
                  <div>
                    <Button icon={<RingIcon/>} className={styles.lateEarlyCheckinButton}>{intl.formatMessage({ id: 'Reservation.Departure.Button' })}</Button> 
                    
                  </div>
                </div>
                <div className={styles.hostsContainer}>
                  <UserIcon />
                  <div>
                    <span>
                      {intl.formatMessage({ id: 'Postal.Members.Text' })}
                    </span>
                    <div>
                      <FormattedMessage id="typeOfPerson.adults" />
                      {` x${trip?.people?.adult || adults}`}
                      {(trip?.people?.child !== 0 || children !== 0) && (
                        <>
                          {', '}
                          <FormattedMessage id="typeOfPerson.children" />
                          {` x${trip?.people?.child || 0}`}
                        </>
                      )}
                      {(trip?.people?.infant !== 0 || babies !== 0) && (
                        <>
                          {', '}
                          <FormattedMessage id="typeOfPerson.babies" />
                          {` x${trip?.people?.infant || 0}`}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className={styles.totalContainer}>
              {showCancel && <Button className={styles.cancelButton} onClick={showModal}>{intl.formatMessage({ id: 'Reservation.Cancel.Button' })}</Button>}
              
              <span>{intl.formatMessage({ id: 'Postal.Total.Text' })}</span>
              <div className={styles.priceContainer}>
                <span>{`${trip?.price || totalPrice}€`}</span>
                <span>
                  {intl.formatMessage({ id: 'Postal.TaxesIncluded.Text' })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <CancelReservation
          locator={trip?.reservationId}
          propertyId={trip?.camping?.ulysesId}
          amount={trip?.price}
          onClose={handleCancel}
          anonymousCancel={anonymousCancel}
        />
      </Modal>
    </div>
  );
};

Postal.defaultProps = {
  onClose: () => {},
  custom: false,
  showCancel: false,
  trip: null,
  anonymousCancel: false,
};

Postal.propTypes = {
  onClose: PropTypes.func,
  custom: PropTypes.bool,
  showCancel: PropTypes.bool,
  trip: PropTypes.shape({
    reservationLocator: PropTypes.string.isRequired,
    reservationId: PropTypes.string.isRequired,
    checkin: PropTypes.string.isRequired,
    checkout: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    camping: PropTypes.shape({
      ulysesId: PropTypes.number.isRequired,
      campingName: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      featuredImage: PropTypes.objectOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        })
      ),
      freeParking: PropTypes.bool,
    }).isRequired,
    people: PropTypes.shape({
      adult: PropTypes.number.isRequired,
      child: PropTypes.number,
      infant: PropTypes.number,
    }).isRequired,
    adults: PropTypes.number,
    children: PropTypes.number,
    babies: PropTypes.number,
    tent: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    selectedRoomType: PropTypes.string,
    totalPrice: PropTypes.number,
    selectedHotel: PropTypes.string,
    selectedCampingName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  anonymousCancel: PropTypes.bool,
};

export default Postal;
