import { gql } from '@apollo/client';

const tents = gql`
  query GetTents($filters: [String], $lang: I18NLocaleCode!) {
    tents (pagination: { limit: -1 }, filters: {camping: {ulysesId: {in: $filters}}}, locale: $lang) {
      data{
        id,
        attributes {
          name,
          roomTypeId,
          pets,
          privateBathroom,
          camping{
            data{
              id,
              attributes{
                campingName,
                ulysesId,
                propertyName,
              }
            }
          },
          featuredImage{
          data{
            id,
            attributes{
              url,
              formats
            }
          }
        },
          tent_description{
            data{
              id,
              attributes{
                description,
              }
            }
          }
          tent_model{
            data{
              id,
              attributes{
                name,
                area,
                height,
                maxGuests,
              }
            }
          },
          images{
            data{
              id,
              attributes{
                url,
                hash,
                ext,
                formats,
              }
            }
          },
          features (pagination: { limit: -1 }){
            data{
              id,
              attributes{
                name,
                title,
                description,
                icon{
                  data{
                    id,
                    attributes{
                      name,
                      formats,
                      hash,
                      url,
                    }
                  }
                },
              }
            }
          }
        }
      }
    }
  }
`;

export default tents;
