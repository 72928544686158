import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styles from './index.module.scss';

const StepHeader = ({ stepNumber, title, tooltipText }) => (
  <div className={styles.stepHeader}>
    <div className={styles.stepCircle}>{stepNumber}</div>
    <span className={styles.stepTitle}>{title}</span>
    <Tooltip title={tooltipText}>
      <InfoCircleOutlined
        className={styles.infoIcon}
        aria-label="Información adicional"
      />
    </Tooltip>
  </div>
);

StepHeader.propTypes = {
  stepNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string,
  tooltipText: PropTypes.string, // Texto del Tooltip
};

StepHeader.defaultProps = {
  title: 'Paso sin título',
  tooltipText: 'Información adicional', // Texto por defecto para el Tooltip
};

export default StepHeader;