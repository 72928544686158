import { useContext } from 'react';
import { AuthCheckinStateContext, AuthCheckinDispatchContext } from './DataProvider';

const useAuthCheckinState = () => {
  const context = useContext(AuthCheckinStateContext);
  if (context === undefined) {
    throw new Error('useAuthCheckinState must be used within an DataProvider');
  }
  return context;
};

const useAutCheckinhDispatch = () => {
  const context = useContext(AuthCheckinDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an DataProvider');
  }
  return context;
};

export { useAuthCheckinState, useAutCheckinhDispatch };
