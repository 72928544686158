import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';

const SubmitButton = () => {
  const intl = useIntl();
  return (
  <Button className={styles.button} type="primary" size="large" block htmlType="submit">
    {intl.formatMessage({ id: 'Checkin.Auth.Button.Continue' })}
  </Button>
)};

SubmitButton.propTypes = {};

export default SubmitButton;