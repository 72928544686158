/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import UpcomingTripCard from '../../../../components/UpcomingTripCard';
import makeRequestToApServer from '../../../../services/ApServer/askToApServer';
import routes from '../../../../constants/ApServer/apRoutes';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import {
  tripContainer,
  postalContainer,
  postalEnterActive,
  postalExitActive,
} from './index.module.scss';
import Postal from '../../../../components/Postal';

const UpcomingTrip = () => {
  const [trips, setTrips] = useState([]);
  const [selectedTripId, setSelectedTripId] = useState(null);

  useEffect(() => {
    makeRequestToApServer(
      allowedMethods.get,
      routes.upconmingTrip,
      undefined,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          setTrips(data);
          if (data && data.length > 0) {
            setSelectedTripId(data[0].reservationLocator);
          }
        }
      },
      () => {},
    );
  }, []);

  // Función que maneja la selección:
  // Si clicas la misma tarjeta, la cierra (pone null);
  // Si clicas otra, la abre (pone su 'reservationLocator').
  const handleToggle = (locator) => {
    setSelectedTripId((prev) => (prev === locator ? null : locator));
  };

  return (
    <div className={tripContainer}>
      {trips.map((trip, index) => {
        const isExpanded = selectedTripId === trip.reservationLocator;

        return (
          <div key={`${trip.location}-${trip.checkin}-${trip.checkout}-${index}`}>
            <UpcomingTripCard
              location={trip?.camping?.campingName}
              checkin={trip.checkin}
              checkout={trip.checkout}
              locator={trip.reservationLocator}
              isExpanded={isExpanded}
              onToggle={handleToggle}
            />

            {/* Aquí seguimos mostrando el componente "Postal" si el trip está expandido */}
            <div
              className={`${postalContainer} ${
                isExpanded ? postalEnterActive : postalExitActive
              }`}
            >
              {isExpanded && <Postal trip={trip} custom showCancel />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(UpcomingTrip);