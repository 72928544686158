/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Row, Col, Checkbox, Select, Input, DatePicker, Button } from 'antd';
import SignaturePad from 'react-signature-canvas';
import { SaveFilled } from '@ant-design/icons';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { CHILDREN_DOCUMENT } from '../../constants';
import styles from './index.module.scss';
import prefixPhoneNumbers from '../../../../../Checkout/steps/UserForm/components/PersonalInfo/prefixPhoneNumbers';

const { Option } = Select;

const findPrefix = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  const normalizedNumber = phoneNumber.startsWith('+') ? phoneNumber.slice(1) : phoneNumber;

  const matchingPrefix = prefixPhoneNumbers
    .map(String)
    .sort((a, b) => b.length - a.length)
    .find((prefix) => normalizedNumber.startsWith(prefix));

  return matchingPrefix ? `+${matchingPrefix}` : null;
};

const isValidSpanishDNI = (dni) => {
  const dniRegex = /^[0-9]{8}[A-Z]$/; // Formato: 8 números seguidos de una letra mayúscula
  if (!dniRegex.test(dni)) return false;

  const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const number = parseInt(dni.slice(0, 8), 10);
  const letter = dni[8];
  return letters[number % 23] === letter;
};

const FormikForm = ({ guest, validationSchema, guests, onSubmit, countries, documentTypes, familyRelationships, genderTypes, languages, municipalities, stateProvs, error }) => {
  const intl = useIntl();
  const signaturePadRef = useRef();
  const [prefix, setPrefix] = useState(() => {
    if (guest?.phoneNumber?.includes(' ')) {
      return guest.phoneNumber.split(' ')[0];
    }
  
    const extractedPrefix = findPrefix(guest?.phoneNumber || '');
    return extractedPrefix || '+34';
  });


  const hasChildrenOrBabies = guests.some(
    (elm) => elm.role === 'Children' || guest.role === 'Babie'
  );
  const minBirthDate = moment().subtract(18, 'years');
  const disabledExpireDate = (current) => current && current.isSameOrBefore(moment(guest?.arrival));
  const disabledDate = (current) => current && current.isAfter(minBirthDate);

  const formik = useFormik({
    initialValues: {
      guestId: guest?.id,
      reservationId: guest?.reservationRoomStayId,
      documentType: guest?.documentType?.code || '',
      documentNumber: guest.documentNumber || '',
      countryOfIssuance: guest?.countryOfIssuance?.code || '',
      birthDate: guest?.birthDate ? moment(guest?.birthDate) : null,
      expireDate: guest?.expireDate ? moment(guest?.expireDate) : null,
      documentSupport: guest.documentSupport || '',
      familyRelationship: guest?.familyRelationship?.code || '',
      givenName: guest.givenName || '',
      surName: guest.surName || '',
      secondSurName: guest.secondSurName || '',
      language: guest?.language?.code || '',
      nationalityCountry: guest?.nationalityCountry?.code || '',
      genderType: guest?.genderType?.code || '',
      email: guest.email || '',
      phoneNumber: guest.phoneNumber
      ? `${prefix} ${guest.phoneNumber.replace(new RegExp(`^\\+?${prefix.replace('+', '')}`), '').trim()}`
      : '',
      street: guest.street || '',
      country: guest?.country?.code || '',
      stateProv: guest?.municipality?.stateProv?.code || '',
      stateProvName: guest?.stateProvName || '',
      city: guest?.municipality?.name || '',
      municipality: guest?.municipality?.code || '',
      municipalityName: guest?.municipalityName || '',
      postalCode: guest.postalCode || '',
      terms: guest.terms || false,
      allowMarketing: guest.allowMarketing || false,
      allowThirdParty: guest.allowThirdParty || false,
      signature: guest.signature || null,
      noDocument: guest.noDocument || false,
      sharedDocument: guest.sharedDocument || '',
      useAnotherAddress: guest.useAnotherAddress || false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {

        if (!values.email?.trim() && !values.phoneNumber?.trim()) {
          setErrors({
            email: intl.formatMessage({ id: 'Checkin.GuestForm.PhoneNumerOrEmail.Required' }),
            phoneNumber: intl.formatMessage({ id: 'Checkin.GuestForm.PhoneNumerOrEmail.Required' }),
          });
          setSubmitting(false);
          return;
        }

        if (values.documentType === 'DNI' && !isValidSpanishDNI(values.documentNumber)) {
          setErrors({ documentNumber: intl.formatMessage({ id: 'Checkin.GuestForm.DNI.Invalid' }) });
          setSubmitting(false);
          return;
        }

        const dataSources = {
          documentType: documentTypes,
          genderType: genderTypes,
          countryOfIssuance: countries,
          country: countries,
          nationalityCountry: countries,
          municipality: municipalities,
          city: municipalities,
          language: languages,
          familyRelationship: familyRelationships,
          stateProv: stateProvs,
        };
    
        const findCompleteObject = (key, value) => {
          const source = dataSources[key];
          if (!source) return null;
          return source.find((item) => item.code === value || item.id === value) || null;
        };
    
        const formattedValues = {
          ...values,
          birthDate: values.birthDate ? moment(values.birthDate).format('YYYY-MM-DD') : null,
          expireDate: values.expireDate ? moment(values.expireDate).format('YYYY-MM-DD') : null,
          phoneNumber: values.phoneNumber ?`${prefix} ${values.phoneNumber.replace(new RegExp(`^\\+?${prefix.replace('+', '')}`), '').trim()}` : null
        };
    
        const updatedValues = Object.keys(dataSources).reduce((acc, key) => {
          const completeObject = findCompleteObject(key, formattedValues[key]);
          return {
            ...acc,
            [key]: key === 'city' && completeObject ? completeObject.name : completeObject || formattedValues[key],
          };
        }, { ...guest, ...formattedValues });
    
        // Filtrar claves vacías
        const filteredValues = Object.fromEntries(
          Object.entries(updatedValues).filter(
            ([_, value]) => value !== null && value !== undefined && value !== ''
          )
        );
    
        await onSubmit(filteredValues);
      } catch (error2) {
        console.error('Error during submission:', error2);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.useAnotherAddress) {
      const firstGuest = guests.find(
        (g) =>
          g.role === 'Adult' &&
          g.isCheckinOnline &&
          g.street &&
          g.countryOfIssuance &&
          g.municipality &&
          g.postalCode
      );
  
      if (firstGuest) {
        formik.setFieldValue('sharedGuestId', firstGuest.id);
        formik.setFieldValue('street', firstGuest.street || '');
        formik.setFieldValue('country', firstGuest.country?.code || '');
        formik.setFieldValue('stateProv', firstGuest.municipality?.stateProv?.code || '');
        formik.setFieldValue('municipality', firstGuest.municipality?.id || '');
        formik.setFieldValue('city', firstGuest.municipality?.name || '');
        formik.setFieldValue('postalCode', firstGuest.postalCode || '');
      }
    }
    // eslint-disable-next-line
  }, [formik.values.useAnotherAddress, guests]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <h3>{intl.formatMessage({ id: 'Checkin.GuestForm.Document.Identity' })}</h3>
      <Row gutter={[20, 20]}>
        {CHILDREN_DOCUMENT.includes(guest.role) && (
          <Col xs={24}>
            <Checkbox
              name="noDocument"
              checked={formik.values.noDocument}
              onChange={(e) => {
                formik.setFieldValue('noDocument', e.target.checked);
                if (!e.target.checked) {
                  formik.setFieldValue('sharedDocument', '');
                }
              }}
            >
              {intl.formatMessage({ id: 'Checkin.GuestForm.Child.Document' })}
            </Checkbox>
            {formik.values.noDocument && (
            <div>
              <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Child.UseOther' })}</span>
              <Select
                name="sharedDocument"
                value={formik.values.sharedDocument}
                className={styles.formInputSharedDoc}
                onChange={(value) => {
                  formik.setFieldValue('sharedDocument', value);

                  const selectedGuest = guests.find((g) => g.id === value);
                  if (selectedGuest) {
                    formik.setFieldValue('documentType', selectedGuest?.documentType?.code || '');
                    formik.setFieldValue('documentNumber', selectedGuest?.documentNumber || '');
                    formik.setFieldValue('documentSupport', selectedGuest?.documentSupport || '');
                    formik.setFieldValue('countryOfIssuance', selectedGuest?.countryOfIssuance?.code || '');
                    formik.setFieldValue('birthDate', selectedGuest?.birthDate ? moment(selectedGuest?.birthDate) : null);
                    formik.setFieldValue('expireDate', selectedGuest?.expireDate ? moment(selectedGuest?.expireDate) : null);
                  }
                }}
                placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Child.UseOther' })}
              >
                {guests
                  .filter((g) => g.role === 'Adult' && g.isCheckinOnline)
                  .map((adult) => (
                    <Option key={adult.id} value={adult.id}>
                      {adult.givenName} {adult.surName} {adult.secondSurName}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
          </Col>

        )}

        {!formik.values.noDocument  && 
        (
          <>
          <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Document.Title' })}</span>
          <Select
            name="documentType"
            value={formik.values.documentType}
            onChange={(value) => {
              formik.setFieldValue('documentType', value);
              // Limpiar el campo número de soporte si no es DNI español
              if (value !== 'D') {
                formik.setFieldValue('documentSupport', '');
              }
            }}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Document.Placeholder' })}
            className={styles.formInput}
          >
            {documentTypes.map((doc) => (
              <Option key={doc.id} value={doc.code}>
                {doc.code === 'D' ? intl.formatMessage({ id: 'Checkin.GuestForm.Document.DNI' }) : doc.nameI18n}
              </Option>
            ))}
          </Select>
          {formik.errors.documentType && formik.touched.documentType && (
            <div className={styles.error}>{formik.errors.documentType}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Document.Number' })}</span>
          <Input
            name="documentNumber"
            value={formik.values.documentNumber}
            onChange={(e) => formik.setFieldValue('documentNumber', e.target.value)}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Document.Placeholder' })} 
            className={styles.formInput}
          />
          {formik.errors.documentNumber && formik.touched.documentNumber && (
            <div className={styles.error}>{formik.errors.documentNumber}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Country' })}</span>
          <Select
            name="countryOfIssuance"
            value={formik.values.countryOfIssuance}
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Country.Placeholder' })}
            showSearch
            onChange={(value) => {
              formik.setFieldValue('countryOfIssuance', value)
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {countries.map((country) => (
              <Option key={country.id} value={country.code}>
                {country.nameI18n}
              </Option>
            ))}
          </Select>
          {formik.errors.countryOfIssuance && formik.touched.countryOfIssuance && (
            <div className={styles.error}>{formik.errors.countryOfIssuance}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.BirthDate' })}</span>
          <DatePicker
            name="birthDate"
            value={formik.values.birthDate}
            className={styles.formInput}
            disabledDate={guest?.role === 'Adult' && disabledDate}
            defaultPickerValue={guest?.role === 'Adult' && minBirthDate}
            format="DD-MM-YYYY"
            onChange={(value) => formik.setFieldValue('birthDate', value)}
          />
          {formik.errors.birthDate && formik.touched.birthDate && (
            <div className={styles.error}>{formik.errors.birthDate}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.ExpireDate' })}</span>
          <DatePicker
            name="expireDate"
            value={formik.values.expireDate}
            className={styles.formInput}
            disabledDate={disabledExpireDate}
            format="DD-MM-YYYY"
            onChange={(value) => formik.setFieldValue('expireDate', value)}
          />
          {formik.errors.expireDate && formik.touched.expireDate && (
            <div className={styles.error}>{formik.errors.expireDate}</div>
          )}
        </Col>
        {formik.values.documentType === 'D' && (
          <Col xs={24} sm={12} md={8}>
            <span>{intl.formatMessage({ id: 'Checkin.GuestForm.SupportNumber' })}</span>
            <Input
              name="documentSupport"
              value={formik.values.documentSupport}
              onChange={(e) => formik.setFieldValue('documentSupport', e.target.value)}
              placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.SupportNumber' })}
              className={styles.formInput}
            />
            {formik.errors.documentSupport && formik.touched.documentSupport && (
              <div className={styles.error}>{formik.errors.documentSupport}</div>
            )}
          </Col>
        )}
          </>
        )}
        
        {hasChildrenOrBabies && (
          <Col xs={24} sm={12} md={8}>
            <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Relationship' })}</span>
            <Select
              name="familyRelationship"
              placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Relationship.Placeholder' })}
              className={styles.formInput}
              showSearch
              value={formik.values.familyRelationship}
              onChange={(value) => formik.setFieldValue('familyRelationship', value)}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {familyRelationships.map((relationship) => (
                <Option key={relationship.id} value={relationship.code}>
                  {relationship.nameI18n}
                </Option>
              ))}
            </Select>
            {formik.errors.familyRelationship && formik.touched.familyRelationship && (
              <div className={styles.error}>{formik.errors.familyRelationship}</div>
            )}
          </Col>
        )}
      </Row>
      <h3>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Data' })}</h3>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Name' })}</span>
          <Input
            name="givenName"
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Name.Placeholder' })}
            value={formik.values.givenName}
            onChange={(e) => formik.setFieldValue('givenName', e.target.value)}
          />
          {formik.errors.givenName && formik.touched.givenName && (
            <div className={styles.error}>{formik.errors.givenName}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Surname' })}</span>
          <Input
            name="surName"
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Surname' })}
            value={formik.values.surName}
            onChange={(e) => formik.setFieldValue('surName', e.target.value)}
          />
          {formik.errors.surName && formik.touched.surName && (
            <div className={styles.error}>{formik.errors.surName}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.SecondSurname' })}</span>
          <Input
            name="secondSurName"
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.SecondSurname' })}
            value={formik.values.secondSurName}
            onChange={(e) => formik.setFieldValue('secondSurName', e.target.value)}
          />
          {formik.errors.secondSurName && formik.touched.secondSurName && (
            <div className={styles.error}>{formik.errors.secondSurName}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Language' })}</span>
          <Select
            name="language"
            value={formik.values.language}
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Language.Placeholder' })}
            showSearch
            onChange={(value) => formik.setFieldValue('language', value)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {languages.map((language) => (
              <Option key={language.id} value={language.code}>
                {language.nameI18n}
              </Option>
            ))}
          </Select>
          {formik.errors.language && formik.touched.language && (
            <div className={styles.error}>{formik.errors.language}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Nationality' })}</span>
          <Select
            name="nationalityCountry"
            value={formik.values.nationalityCountry}
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Nationality.Placeholder' })} 
            showSearch
            onChange={(value) => formik.setFieldValue('nationalityCountry', value)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {countries.map((country) => (
              <Option key={country.id} value={country.code}>
                {country.nameI18n}
              </Option>
            ))}
          </Select>
          {formik.errors.nationalityCountry && formik.touched.nationalityCountry && (
            <div className={styles.error}>{formik.errors.nationalityCountry}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Genre' })} </span>
          <Select
            name="genderType"
            value={formik.values.genderType}
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Genre.Placeholder' })}  
            showSearch
            onChange={(value) => formik.setFieldValue('genderType', value)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {genderTypes.map((genre) => (
              <Option key={genre.id} value={genre.code}>
                {genre.nameI18n}
              </Option>
            ))}
          </Select>
          {formik.errors.genderType && formik.touched.genderType && (
            <div className={styles.error}>{formik.errors.genderType}</div>
          )}
        </Col>
      </Row>
      <h3>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Contact' })}</h3>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={12} md={12}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Email' })}</span>
          <Input
            name="email"
            className={styles.formInput}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Email.Placeholder' })}
            value={formik.values.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
          />
          {formik.errors.email && formik.touched.email && (
            <div className={styles.error}>{formik.errors.email}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12}>
          <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Phone' })}</span>
          <Input
            name="phoneNumber"
            addonBefore={
              <Select
                className={styles.formInput}
                value={prefix}
                onChange={(value) => {
                    setPrefix(value)
                    formik.setFieldValue(
                      'phoneNumber',
                      `${value} ${formik.values.phoneNumber.replace(prefix, '').trim()}`
                    )
                  }
                }
              >
                {prefixPhoneNumbers.map((number) => (
                  <Option key={number} value={`+${number}`}>
                    +{number}
                  </Option>
                ))}
              </Select>
            }
            className={styles.inputGroup}
            placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Phone.Placeholder' })}
            value={formik.values.phoneNumber.replace(new RegExp(`^\\+?${prefix.replace('+', '')}`), '').trim()}
            onChange={(e) =>
              formik.setFieldValue(
                'phoneNumber',
                `${prefix} ${e.target.value.replace(prefix, '')}`
              )
            }
          />
          {formik.errors.phoneNumber && formik.touched.phoneNumber && (
            <div className={styles.error}>{formik.errors.phoneNumber}</div>
          )}
        </Col>
      </Row>
      <h3>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Address.Title' })}</h3>
      
        {guests.filter((g) => g.role === 'Adult' && g.isCheckinOnline && g.street && g.country && g.municipality && g.postalCode && g.stateProv).length > 0 && (
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Checkbox
                name="useAnotherAddress"
                checked={formik.values.useAnotherAddress}
                onChange={(e) => {
                  formik.setFieldValue('useAnotherAddress', e.target.checked);
                  if (!e.target.checked) {
                    formik.setFieldValue('street', '');
                    formik.setFieldValue('country', '');
                    formik.setFieldValue('stateProv', '');
                    formik.setFieldValue('municipality', '');
                    formik.setFieldValue('postalCode', '');
                  }
                }}
              >
                {intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Address.UseOther' })}
              </Checkbox>
              {formik.values.useAnotherAddress && (
                <div>
                  <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Address.UseOther.Select' })}</span>
                <Select
                  placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Address.UseOther.Placeholder' })}
                  className={styles.formInputSharedDoc}
                  value={guests.find(
                    (g) => g.role === 'Adult' && g.isCheckinOnline && g.street && g.countryOfIssuance && g.municipality && g.postalCode
                  )?.id}
                  onChange={(value) => {
                    const selectedGuest = guests.find((g) => g.id === value);
                    if (selectedGuest) {
                      formik.setFieldValue('street', selectedGuest?.street || '');
                      formik.setFieldValue('country', selectedGuest?.country?.code || '');
                      formik.setFieldValue('stateProv', selectedGuest?.municipality?.stateProv?.code || '');
                      formik.setFieldValue('municipality', selectedGuest?.municipality?.id || '');
                      formik.setFieldValue('city', selectedGuest?.municipality?.name || '');
                      formik.setFieldValue('postalCode', selectedGuest.postalCode || '');
                    }
                  }}
                >
                  {guests
                    .filter((g) => g.role === 'Adult' && g.isCheckinOnline && g.street && g.countryOfIssuance && g.municipality && g.postalCode)
                    .map((g) => (
                      <Option key={g.id} value={g.id}>
                        {g.givenName} {g.surName} {g.secondSurName}
                      </Option>
                    ))}
                </Select>
              </div>
              )}
            </Col>
          </Row>
        )}
        
        
      
      {!formik.values.useAnotherAddress && (
        <>
          <Row gutter={[20, 20]} className={styles.adressRow}>
            <Col xs={24} sm={12} md={16}>
              <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Address' })}</span>
              <Input
                name="street"
                className={styles.formInput}
                placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Address.Placeholder' })}
                value={formik.values.street}
                onChange={(e) => formik.setFieldValue('street', e.target.value)}
                disabled={formik.values.useAnotherAddress}
              />
              {formik.errors.street && formik.touched.street && (
                <div className={styles.error}>{formik.errors.street}</div>
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Country' })}</span>
              <Select
                name="country"
                value={formik.values.country}
                className={styles.formInput}
                placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Country.Placeholder' })}
                showSearch
                onChange={(value) => {
                    formik.setFieldValue('country', value)
                    if(value !== 'ESP') {
                      formik.setFieldValue('stateProv', '');
                      formik.setFieldValue('municipality', '');
                      formik.setFieldValue('city', '');
                    }
                  }
                }
                disabled={formik.values.useAnotherAddress}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((country) => (
                  <Option key={country.id} value={country.code}>
                    {country.nameI18n}
                  </Option>
                ))}
              </Select>
              {formik.errors.country && formik.touched.country && (
                <div className={styles.error}>{formik.errors.country}</div>
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Prov' })}</span>
              {formik.values.country === 'ESP' ? (
                <Select
                  name="stateProv"
                  value={formik.values.stateProv}
                  className={styles.formInput}
                  placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Prov.Placeholder' })}
                  showSearch
                  onChange={(value) => formik.setFieldValue('stateProv', value)}
                  disabled={formik.values.useAnotherAddress}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {stateProvs.map((prov) => (
                    <Option key={prov.id} value={prov.code}>
                      {prov.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input
                  name="stateProvName"
                  value={formik.values.stateProvName}
                  className={styles.formInput}
                  placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Prov.Placeholder' })}
                  onChange={(e) => formik.setFieldValue('stateProvName', e.target.value)}
                />
              )}
              {formik.errors.stateProvName && formik.touched.stateProvName && (
                <div className={styles.error}>{formik.errors.stateProvName}</div>
              )}
            </Col>

            <Col xs={24} sm={12} md={8}>
              <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Municipality' })}</span>
              {formik.values.country === 'ESP' ? (
                <Select
                  name="municipality"
                  value={formik.values.municipality}
                  className={styles.formInput}
                  placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Municipality.Placeholder' })}
                  showSearch
                  onChange={(value) => {
                    formik.setFieldValue('municipality', value);
                    formik.setFieldValue('city', value);
                  }}
                  disabled={formik.values.useAnotherAddress}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {municipalities
                    .filter((municipality) => municipality.stateProv?.code === formik.values.stateProv)
                    .map((municipality) => (
                      <Option key={municipality.id} value={municipality.id}>
                        {municipality.name}
                      </Option>
                    ))}
                </Select>
              ) : (
                <Input
                  name="municipalityName"
                  value={formik.values.municipalityName}
                  className={styles.formInput}
                  placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Municipality.Placeholder' })}
                  onChange={(e) => formik.setFieldValue('municipalityName', e.target.value)}
                />
              )}
              {formik.errors.municipalityName && formik.touched.municipalityName && (
                <div className={styles.error}>{formik.errors.municipalityName}</div>
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              <span>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.PostalCode' })}</span>
              <Input
                name="postalCode"
                className={styles.formInput}
                placeholder={intl.formatMessage({ id: 'Checkin.GuestForm.Guest.PostalCode.Placeholder' })}
                value={formik.values.postalCode}
                onChange={(e) => formik.setFieldValue('postalCode', e.target.value)}
                disabled={formik.values.useAnotherAddress}
              />
              {formik.errors.postalCode && formik.touched.postalCode && (
                <div className={styles.error}>{formik.errors.postalCode}</div>
              )}
            </Col>
          </Row>
        </>
      )}
      <h3>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Signature' })}</h3>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <SignaturePad
            ref={signaturePadRef}
            canvasProps={{ className: styles.signaturePad }}
            onEnd={() => {
              const signatureData = signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png');
              formik.setFieldValue('signature', signatureData);
            }}
          />
          {formik.errors.signature && formik.touched.signature && (
          <div className={styles.error}>{formik.errors.signature}</div>
        )}
        </Col>
      </Row>

      <h3>{intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Terms' })}</h3>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Checkbox
          name="terms"
          checked={formik.values.terms}
          onChange={formik.handleChange}
          >
            {intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Terms.Checkbox' })}
          </Checkbox>
          {formik.errors.terms && formik.touched.terms && (
            <div className={styles.error}>{formik.errors.terms}</div>
          )}
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Checkbox
            name="allowMarketing"
            checked={formik.values.allowMarketing}
            onChange={formik.handleChange}
          >
              {intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Marketing.Checkbox' })}
          </Checkbox>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Checkbox
            name="allowThirdParty"
            checked={formik.values.allowThirdParty}
            onChange={formik.handleChange}
          >
            {intl.formatMessage({ id: 'Checkin.GuestForm.Guest.ThirdParty.Checkbox' })}
          </Checkbox>
        </Col>
      </Row>
         
      <div className={styles.saveButtonContainer}>
        <Button icon={<SaveFilled/>} className={styles.saveButton} htmlType="submit" loading={formik.isSubmitting}>
        {intl.formatMessage({ id: 'Checkin.GuestForm.Guest.Save' })}
        </Button>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </form>
  );
};

FormikForm.propTypes = {
  guest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reservationRoomStayId: PropTypes.number.isRequired,
    givenName: PropTypes.string,
    surName: PropTypes.string,
    secondSurName: PropTypes.string,
    role: PropTypes.oneOf(['Adult', 'Child', 'Other']),
    tent: PropTypes.string,
    isCheckinOnline: PropTypes.bool,
    documentType: PropTypes.shape({
      code: PropTypes.string,
      nameI18n: PropTypes.string,
    }),
    documentNumber: PropTypes.string,
    country: PropTypes.shape({
      code: PropTypes.string,
      nameI18n: PropTypes.string,
    }),
    countryOfIssuance: PropTypes.shape({
      code: PropTypes.string,
      nameI18n: PropTypes.string,
    }),
    
    birthDate: PropTypes.string,
    expireDate: PropTypes.string,
    documentSupport: PropTypes.string,
    familyRelationship: PropTypes.shape({
      code: PropTypes.string,
      nameI18n: PropTypes.string,
    }),
    language: PropTypes.shape({
      code: PropTypes.string,
      nameI18n: PropTypes.string,
    }),
    nationalityCountry: PropTypes.shape({
      code: PropTypes.string,
      nameI18n: PropTypes.string,
    }),
    genderType: PropTypes.shape({
      code: PropTypes.string,
      nameI18n: PropTypes.string,
    }),
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    street: PropTypes.string,
    stateProv: PropTypes.string,
    stateProvName: PropTypes.string,
    municipality: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      stateProv: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    municipalityName: PropTypes.string,
    postalCode: PropTypes.string,
    terms: PropTypes.bool,
    allowMarketing: PropTypes.bool,
    allowThirdParty: PropTypes.bool,
    signature: PropTypes.string,
    noDocument: PropTypes.bool,
    sharedDocument: PropTypes.string,
    useAnotherAddress: PropTypes.bool,
    arrival: PropTypes.string
  }).isRequired,
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      givenName: PropTypes.string,
      surName: PropTypes.string,
      secondSurName: PropTypes.string,
      role: PropTypes.oneOf(['Adult', 'Child', 'Other']),
      isCheckinOnline: PropTypes.bool,
    })
  ).isRequired,
  validationSchema: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string,
    })
  ).isRequired,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string,
    })
  ).isRequired,
  familyRelationships: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string,
    })
  ).isRequired,
  genderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string,
    })
  ).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string,
    })
  ).isRequired,
  municipalities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stateProv: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  stateProvs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.shape().isRequired
};

export default FormikForm;