import React from 'react';
import PropTypes from 'prop-types';

const CheckNoBookingsAuthRoute = ({ component: Component, path = '/', location = {} }) => {
  if (!Component) return null;

  return <Component path={path} location={location} />;
};

CheckNoBookingsAuthRoute.defaultProps = {
  component: null,
  path: '/',
  location: {},
};

CheckNoBookingsAuthRoute.propTypes = {
  component: PropTypes.elementType,
  path: PropTypes.string,
  location: PropTypes.shape({
    key: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default CheckNoBookingsAuthRoute;