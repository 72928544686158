import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import * as s from '../../index.module.scss';

const LoadMoreButton = ({ showLoadMore, handleLoadMoreMonths, selectedUnit }) => (
  showLoadMore && (
    <div className={selectedUnit ? s.loadMoreContainerWithUnit : s.loadMoreContainer}>
      <Button className={s.loadMoreButton} onClick={handleLoadMoreMonths}>
        <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.LoadMore" defaultMessage="Cargar más fechas" />
      </Button>
    </div>
  )
);

LoadMoreButton.propTypes = {
  showLoadMore: PropTypes.bool.isRequired,
  handleLoadMoreMonths: PropTypes.func.isRequired,
  selectedUnit: PropTypes.string.isRequired,
};

export default LoadMoreButton;