import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import styles from './index.module.scss';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/icon_location.svg';

const ReservationSummary = ({ reservationDetails = {} }) => {
  const intl = useIntl();
  const {
    locator = '-',
    propertyCode = '-',
    propertyName = '-',
    details = [],
  } = reservationDetails;

  const arrival =
    details.length > 0
      ? details.reduce(
          (min, item) => (item.arrival && moment(item.arrival).isBefore(min) ? item.arrival : min),
          details[0].arrival
        )
      : '-';

  const departure =
    details.length > 0
      ? details.reduce(
          (max, item) => (item.departure && moment(item.departure).isAfter(max) ? item.departure : max),
          details[0].departure
        )
      : '-';

  return (
    <div className={styles.reservationSummary}>
      <div className={styles.locator}>
        <p>{intl.formatMessage({ id: 'Checkin.Auth.Locator' })}</p>
        <b>{locator}</b>
      </div>
      <div className={styles.location}>
        <div className={styles.locationTitle}>
          <h4>{propertyCode}</h4>
          <LocationIcon />
        </div>
        <p>{propertyName}</p>
      </div>
      <div className={styles.details}>
        <div className={styles.dateTime}>
          <p>
            <strong>{intl.formatMessage({ id: 'Checkin.Summary.Arrival' })}:</strong> <br /> {moment(arrival).format('DD-MM-YYYY')}
          </p>
          <p style={{display: "none"}}>
            <strong>{intl.formatMessage({ id: 'Checkin.Summary.Hour' })}:</strong> <br /> -
          </p>
        </div>
        <div className={styles.dateTime}>
          <p>
            <strong>{intl.formatMessage({ id: 'Checkin.Summary.Departure' })}:</strong> <br /> {moment(departure).format('DD-MM-YYYY')}
          </p>
          <p style={{display: "none"}}>
            <strong>{intl.formatMessage({ id: 'Checkin.Summary.Hour' })}:</strong> <br /> -
          </p>
        </div>
      </div>
    </div>
  );
};

ReservationSummary.propTypes = {
  reservationDetails: PropTypes.shape({
    locator: PropTypes.string,
    propertyCode: PropTypes.string,
    propertyName: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        arrival: PropTypes.string,
        departure: PropTypes.string,
      })
    ),
  }),
};

ReservationSummary.defaultProps = {
  reservationDetails: {
    locator: '-',
    propertyCode: '-',
    propertyName: '-',
    details: [],
  },
};

export default ReservationSummary;