import React from "react";
import { Button } from "antd";
import { useIntl , FormattedMessage } from "react-intl";
import moment from "moment";
import PropTypes from "prop-types";
import styles from './index.module.scss'
import ROUTES from "../../../../../../routes/routes";
import { ReactComponent as RingIcon } from '../../../../../../assets/icons/icon_ring.svg';
import Postal from "../../../../../../components/Postal";
import BoxInfo from "../BoxInfo";
import { clearCheckinTokens } from '../../../../../../utils/tokenHelper';

const ExtraInfo = ({reservation}) => {
  const intl = useIntl();
  const box1 = {
    title: intl.formatMessage({ id: 'ExtraInfo.Box1.Title' }),
    texts: [
      <FormattedMessage
        id="ExtraInfo.Box1.Text1"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />,
      <FormattedMessage
        id="ExtraInfo.Box1.Text2"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />,
      <FormattedMessage
        id="ExtraInfo.Box1.Text3"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />,
      <FormattedMessage
        id="ExtraInfo.Box1.Text4"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />,
    ]
  }
  
  const box2 = {
    title: intl.formatMessage({ id: 'ExtraInfo.Box2.Title' }),
    texts: [
      <FormattedMessage
        id="ExtraInfo.Box2.Text1"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />,
      <FormattedMessage
        id="ExtraInfo.Box2.Text2"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />,
      <FormattedMessage
        id="ExtraInfo.Box2.Text3"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />,
    ]
  }
  const handleClick = () => {
    const {reservationId, startDate, endDate} = reservation;
    if (!reservation || !reservationId || !startDate || !endDate) {
      console.error("Reservation data is missing!");
      return;
    }

    clearCheckinTokens();

    window.open(
      `${ROUTES.bookingsRegister}?locator=${parseInt(reservationId, 10)}&checkin=${moment(startDate).format('YYYY-MM-DD')}&checkout=${moment(endDate).format('YYYY-MM-DD')}&checkinOnline=true`,
      "_blank",
    );
  };
  return (
    <div>
      <div className={styles.buttonContainer}>
        <Button
          icon={<RingIcon/>}
          className={styles.checkinButton}
          onClick={() => handleClick()}
        >
          {intl.formatMessage({ id: 'Checkin.Button.DoIt' })}
        </Button>
      </div>
      <div className={styles.ExtraInfoContainer}>
        {reservation ? <Postal trip={reservation} custom /> : <p>No reservation data</p>}
      </div>
      <div className={styles.faqContainer}>
        <h2>
        <FormattedMessage
          id="ExtraInfo.Title"
          values={{
            b: (chunks) => <strong>{chunks}</strong>
          }}
        />
        </h2>
        <div className={styles.boxContainer}>
          <BoxInfo info={box1} className={styles.box1}/>
          <BoxInfo info={box2} className={styles.box2}/>
        </div>
      </div>
    </div>
  );
};

ExtraInfo.propTypes = {
  reservation: PropTypes.shape({
    reservationId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    people: PropTypes.shape({
      adults: PropTypes.number,
      children: PropTypes.number,
      babies: PropTypes.number,
    }),
    camping: PropTypes.shape({
      campingName: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};

ExtraInfo.defaultProps = {
  reservation: null,
};

export default ExtraInfo;