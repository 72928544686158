import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;

const DateRangePicker = ({ value, onChange }) => {
  const intl = useIntl();
  return (
  <RangePicker 
    className={styles.datePicker} 
    size="large" 
    placeholder={[`${intl.formatMessage({ id: 'Checkin.Auth.StartDate' })}`, `${intl.formatMessage({ id: 'Checkin.Auth.EndDate' })}`]} 
    value={value}
    onChange={onChange}
  />
)};

DateRangePicker.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
};

DateRangePicker.defaultProps = {
  value: null,
};

export default DateRangePicker;