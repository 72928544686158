import { useCallback } from "react";
import makeRequestToApServer from "../../../services/ApServer/askToApServer";
import { allowedMethods } from "../../../constants/ApServer/apServer";
import routes from "../../../constants/ApServer/apRoutes";
import openNotification from "../../NotificacionMessage";

const useCancelReservation = (locator, propertyId, state, dispatch, intl, onClose, anonymousCancel) => {
  const handleCancelReservation = useCallback(() => {
    dispatch({ type: "SET_IS_CANCELLING", payload: true });

    const requestUrl = anonymousCancel
      ? routes.cancelVerify.replace(":id", locator).replace(":propertyId", propertyId)
      : routes.cancelBooking.replace(":id", locator).replace(":propertyId", propertyId);

    const requestData = {};

    if (state.selectedReason !== undefined && state.selectedReason !== null) {
      requestData.reason = state.selectedReason;
    }

    makeRequestToApServer(
      anonymousCancel ? allowedMethods.get : allowedMethods.put,
      requestUrl,
      requestData,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          if (anonymousCancel) {
            dispatch({ type: "SHOW_CODE_MODAL", payload: true });
          } else {
            openNotification(
              "success",
              intl.formatMessage({ id: "Reservation.Cancel.Success" }),
              "",
              () => window.location.reload()
            );
            onClose();
            
          }
        } else {
          openNotification("error", data?.message || intl.formatMessage({ id: "Reservation.Cancel.Error" }));
        }
        dispatch({ type: "SET_IS_CANCELLING", payload: false });
      },
      (error) => {
        openNotification("error", error?.message || intl.formatMessage({ id: "Reservation.Cancel.Error" }));
        dispatch({ type: "SET_IS_CANCELLING", payload: false });
      }
    );
  }, [locator, propertyId, state.selectedReason, intl, onClose, dispatch, anonymousCancel]);

  return { handleCancelReservation };
};

export default useCancelReservation;