import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import moment from 'moment';
import { Spin } from 'antd';
import styles from './index.module.scss';
import UpcomingTripCard from '../../components/UpcomingTripCard';
import Postal from '../../components/Postal';
import routes from '../../constants/ApServer/apRoutes';
import { allowedMethods } from '../../constants/ApServer/apServer';
import makeRequestToApCheckinServer from '../../services/ApServer/askCheckinToApServer';

const CheckinBooksComponent = () => {
  const { search } = useLocation();
  const [selectedTripId, setSelectedTripId] = useState(null);
  const [reservationDetails, setReservationDetais] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchParams = new URLSearchParams(search);
  const locator = searchParams.get('locator') || '';
  const checkin = searchParams.get('checkin') ? moment(searchParams.get('checkin')).format('YYYY-MM-DD') : null;
  const checkout = searchParams.get('checkout') ? moment(searchParams.get('checkout')).format('YYYY-MM-DD') : null;

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        await makeRequestToApCheckinServer(
          allowedMethods.get,
          routes.bookingsDetails,
          undefined,
          async ({ statusCode, data }) => {
            if (statusCode === 200) {
              setReservationDetais(data);
            }
          },
          () => {}
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const handleToggle = (locatorParam) => {
    setSelectedTripId((prev) => (prev === locatorParam ? null : locatorParam));
  };

  const tripCards = useMemo(() => {
    const trip = reservationDetails?.upcoming;

    if (!trip) return null;
    const isExpanded = selectedTripId === trip.reservationLocator;
    return (
      <>
        <UpcomingTripCard
          key={`${trip.location}-${trip.checkin}-${trip.checkout}`}
          location={trip?.camping?.campingName}
          checkin={checkin || trip.checkin}
          checkout={checkout || trip.checkout}
          locator={locator}
          allowCheckinOnline={reservationDetails?.allowCheckinOnline}
          isOtaReservation={reservationDetails?.isOtaResevation}
          onClick={() =>
            setSelectedTripId(selectedTripId === trip.reservationLocator ? null : trip.reservationLocator)
          }
          onToggle={handleToggle}
          isExpanded={isExpanded}
        />
        <div
          className={`${styles.postalContainer} ${
            selectedTripId === trip.reservationLocator ? styles.postalEnterActive : styles.postalExitActive
          }`}
        >
          {selectedTripId === trip.reservationLocator && <Postal trip={trip} custom showCancel anonymousCancel />}
        </div>
      </>
    );
  }, [reservationDetails, checkin, checkout, locator, selectedTripId]);

  return (
    <>
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={styles.layout}>
          {reservationDetails?.upcoming && tripCards}
        </div>
      )}
    </>
  );
};

export default CheckinBooksComponent;