/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Result, Progress } from "antd";
import { navigate } from "@reach/router";
import ROUTES from "../../../../routes/routes";
import { useReservationState } from "../../../../services/Reservation";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/clap.svg";
import {
  iconWrapper,
  titleWrapper,
  subtitleWrapper,
  wrapper,
} from "./index.module.scss";
import ExtraInfo from "./components/ExtraInfo";

const Confirmation = injectIntl(({ intl }) => {
  const reservation = useReservationState();
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((oldPercent) => {
        const newPercent = oldPercent + 5;
        if (newPercent >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newPercent;
      });
    }, 200);
  
    return () => clearInterval(interval);
  }, []);

  if (!reservation.readyToBeUsed) {
    navigate(ROUTES.home);
    return <div className={wrapper} />;
  }
  return (
    <div className={wrapper}>
      <Result
        status="success"
        title={
          <p className={titleWrapper}>
            {percent === 100 
            ? intl.formatMessage(
              {
                id: "Confirmation.Title.Complete",
                defaultMessage: "Reserva realizada",
              },
              { br: <br /> },
            )
            : intl.formatMessage(
              {
                id: "Confirmation.Title",
                defaultMessage: "Reserva realizada",
              },
              { br: <br /> },
            )}
            
          </p>
        }
        subTitle={
          <p className={subtitleWrapper}>
            {percent === 100 
            ? intl
            .formatMessage({
              id: "Confirmation.Subtitle.Complete",
              defaultMessage: "Has realizado un pago para ",
            })
            : 
            intl
              .formatMessage({
                id: "Confirmation.Subtitle",
                defaultMessage: "Has realizado un pago para ",
              }).concat(reservation.selectedHotel)
              }
          </p>
        }
        extra={
          <>
            {percent === 100 ? <ExtraInfo reservation={reservation}/> : <Progress percent={percent} status="active" strokeColor="#ffd100" />} 
          </>
        }
        icon={
          <div className={iconWrapper}>
            <SuccessIcon />
          </div>
        }
      />
    </div>
  );
});

export default Confirmation;
