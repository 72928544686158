/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { useIntl } from 'react-intl';
import moment from "moment";
import useGeoLocation from "react-ipgeolocation";
import RefundProgressBar from "../RefundProgressBar";
import styles from "./index.module.scss";
import RefundStatusIcon from "./components/RefundStatusIcon";
import RefundMessage from "./components/RefundMessage";
import CancelReasonSelection from "./components/CancelReasonSelection";
import CancelContactInfo from "./components/CancelContactInfo";
import RefundableCancelButton from "./components/RefundableCancelButton";
import NonRefundableCancelSection from "./components/NonRefundableCancelSection";
import CancelCodeVerification from "./components/CancelCodeVerification";
import useFetchCancelInfo from "./hooks/useFetchCancelInfo";
import useCancelReservation from "./hooks/useCancelReservation";
import useConfirmCode from "./hooks/useConfirmCode";

const CancelReservation = ({ onClose, locator, amount, propertyId, anonymousCancel}) => {
  const intl = useIntl();
  const location = useGeoLocation();

  const initialState = {
    selectedReason: null,
    termsAccepted: false,
    noRefundAccepted: false,
    chargeValueAccepted: false,
    isCancelling: false,
    showInfo: true,
    showCodeModal: false,
    code: "",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_SELECTED_REASON":
        return { ...state, selectedReason: action.payload };
      case "TOGGLE_TERMS_ACCEPTED":
        return { ...state, termsAccepted: !state.termsAccepted };
      case "TOGGLE_NO_REFUND_ACCEPTED":
        return { ...state, noRefundAccepted: !state.noRefundAccepted };
      case "TOGGLE_CHARGE_VALUE_ACCEPTED":
        return { ...state, chargeValueAccepted: !state.chargeValueAccepted };
      case "SET_IS_CANCELLING":
        return { ...state, isCancelling: action.payload };
      case "TOGGLE_SHOW_INFO":
        return { ...state, showInfo: !state.showInfo };
      case "SET_CODE":
        return { ...state, code: action.payload };
      case "SHOW_CODE_MODAL":
        return { ...state, showCodeModal: true };
      case "HIDE_CODE_MODAL":
        return { ...state, showCodeModal: false };
      default:
        return state;
    }
  };
  
  const [state, dispatch] = useReducer(reducer, initialState);

  const { cancelInfo, isRefundable, isOutOfRefund, isLoading, reasons, totalPaid } = useFetchCancelInfo(locator, propertyId);

  const phoneNumber = useMemo(() => {
    const phoneNumbers = {
      ES: "+34 955252440",
      FR: "+33 978450039",
      PT: "+351 308807627",
      IT: "+39 0707040834"
    };
  
    return phoneNumbers[location.country] || phoneNumbers.ES;
  }, [location.country]);

  const { handleCancelReservation } = useCancelReservation(locator, propertyId, state, dispatch, intl, onClose, anonymousCancel);
  const { handleConfirmCode } = useConfirmCode(locator, propertyId, state.code, dispatch, intl, onClose);

  return (
    <>
      <span className={styles.headerTitle}>{intl.formatMessage({ id: 'Reservation.Cancel.Button' })}</span>
      <div className={styles.separator} />

      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.spinnerContainer}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div className={styles.icon}>
              <RefundStatusIcon isRefundable={isRefundable} isOutOfRefund={isOutOfRefund} />
            </div>
            <RefundMessage isRefundable={isRefundable} isOutOfRefund={isOutOfRefund} cancelInfo={cancelInfo} />

            <RefundProgressBar
              isRefundable={isRefundable}
              isOutOfRefund={isOutOfRefund}
              refundDateLabel={moment(cancelInfo).format('DD MMM')}
            />

            {isRefundable && !isOutOfRefund && !state.showInfo && (
              <CancelReasonSelection
                selectedReason={state.selectedReason}
                termsAccepted={state.termsAccepted}
                amount={totalPaid}
                dispatch={dispatch}
                reasons={reasons}
              />
            )}

            {isRefundable && !isOutOfRefund && state.showInfo && (
              <CancelContactInfo phoneNumber={phoneNumber} />
            )}

            <div className={styles.buttonContainer}>
              {isRefundable && !isOutOfRefund ? (
                <RefundableCancelButton
                  showInfo={state.showInfo}
                  handleCancelReservation={handleCancelReservation}
                  isCancelling={state.isCancelling}
                  showCodeModal={state.showCodeModal}
                  selectedReason={state.selectedReason}
                  termsAccepted={state.termsAccepted}
                  dispatch={dispatch}
                />
              ) : (
                <NonRefundableCancelSection
                  noRefundAccepted={state.noRefundAccepted}
                  chargeValueAccepted={state.chargeValueAccepted}
                  showCodeModal={state.showCodeModal}
                  handleCancelReservation={handleCancelReservation}
                  isCancelling={state.isCancelling}
                  dispatch={dispatch}
                />
              )}
            </div>

            
            {state.showCodeModal && (
              <CancelCodeVerification
                code={state.code}
                handleConfirmCode={handleConfirmCode}
                isCancelling={state.isCancelling}
                dispatch={dispatch}
              />
            )}
          </>
        )}
      </div>

    </>
  );
};

CancelReservation.propTypes = {
  onClose: PropTypes.func.isRequired,
  locator: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  anonymousCancel: PropTypes.bool.isRequired,
};

export default CancelReservation;