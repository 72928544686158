export const documentTypes = [
  {
    value: '5',
    label: 'UserForm.DocumentType.DNI',
    defaultMessage: 'Documento nacional de identidad',
  },
  {
    value: '2',
    label: 'UserForm.DocumentType.Passport',
    defaultMessage: 'Pasaporte',
  },
  {
    value: '23',
    label: 'UserForm.DocumentType.DNI',
    defaultMessage: 'Documento nacional de identidad',
  },
];

export default {documentTypes};