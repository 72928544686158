import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import moment from 'moment';
import styles from './index.module.scss';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/icon_location.svg';

const CombinedComponent = ({ currentStep, onStepChange, reservationDetails }) => {
  const intl = useIntl();

  const steps = [
    {title: `${intl.formatMessage({ id: 'Checkin.Step.GuestsForm' })}`},
    {title: `${intl.formatMessage({ id: 'Checkin.Step.AdditionalFields' })}`},
    {title: `${intl.formatMessage({ id: 'Checkin.Step.ExtraServices' })}`},
    {title: `${intl.formatMessage({ id: 'Checkin.Step.LateCheckin' })}`},
    {title: `${intl.formatMessage({ id: 'Checkin.Step.WelcomeSignature' })}`},
  ];
  
  const { locator = '-', propertyCode = '-', propertyName = '-', details = [] } =
    reservationDetails || {};
  const { arrival = '-', departure = '-' } = details[0] || {};

  return (
    <div className={styles.combinedComponent}>
      {/* Sección de pasos */}
      <div className={styles.stepSection}>
        <div className={styles.sectionHeader}>
          <b className={styles.locatorTitle}>{intl.formatMessage({ id: 'Checkin.Auth.Locator' })}</b>
          <b className={styles.locatorCode}>{locator}</b>
        </div>
        <div className={styles.stepDropdownWrapper}>
          <div className={styles.stepIcon}>{currentStep + 1}</div>
          <Select
            value={currentStep}
            onChange={onStepChange}
            options={steps.map((step, index) => ({
              label: (
                <span hidden={index === 2 || index === 3}>
                  {step.title}
                </span>
              ),
              value: index,
              hidden: index === 2 || index === 3, // Esta línea es clave
            }))}
            className={styles.stepDropdown}
            bordered={false}
            aria-label="Seleccionar paso"
        />
        </div>
      </div>

      <div className={styles.reservationSection}>
        <div className={styles.location}>
          <div className={styles.locationTitle}>
            <h4>{propertyCode}</h4>
            <LocationIcon />
          </div>
          <p>{propertyName}</p>
        </div>
        <div className={styles.details}>
          <div className={styles.dateTime}>
            <p>
              <strong>{intl.formatMessage({ id: 'Checkin.Summary.Arrival' })}:</strong> <br /> {moment(arrival).format('DD-MM-YYYY')}
            </p>
            <p>
              <strong>{intl.formatMessage({ id: 'Checkin.Summary.Hour' })}:</strong> <br /> -
            </p>
          </div>
          <div className={styles.dateTime}>
            <p>
              <strong>{intl.formatMessage({ id: 'Checkin.Summary.Departure' })}:</strong> <br /> {moment(departure).format('DD-MM-YYYY')}
            </p>
            <p>
              <strong>{intl.formatMessage({ id: 'Checkin.Summary.Hour' })}:</strong> <br /> -
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

CombinedComponent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onStepChange: PropTypes.func.isRequired,
  reservationDetails: PropTypes.shape({
    locator: PropTypes.string,
    propertyCode: PropTypes.string,
    propertyName: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        arrival: PropTypes.string,
        departure: PropTypes.string,
      })
    ),
  }),
};

CombinedComponent.defaultProps = {
  reservationDetails: {
    locator: '-',
    propertyCode: '-',
    propertyName: '-',
    details: [],
  },
};

export default CombinedComponent;