/* eslint-disable no-unused-vars */
import { useState, useCallback } from 'react';
import moment from 'moment';
import { buildWeeksFromRange } from '../utils/calendarUtils';

const useLoadMoreMonths = (getReferenceRate, filteredReferences) => {
  const [loadMoreAttempts, setLoadMoreAttempts] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [lastLoadedDate, setLastLoadedDate] = useState(null);

  const loadMoreMonths = useCallback(
    ({ daysArray, setDays, endDateSelected }) => {
      const lastWeekIndex = daysArray.length - 1;
      if (lastWeekIndex < 0) return;
      const lastDayInCalendar = daysArray[lastWeekIndex].days[
        daysArray[lastWeekIndex].days.length - 1
      ];
      // Definimos queryEnd: se basa en endDateSelected.
      // Si existe, usamos su último día (extendido); de lo contrario, agregamos 2 meses al último día actual.
      const queryEnd = endDateSelected
        ? moment(endDateSelected).endOf('month')
        : lastDayInCalendar.clone().add(2, 'months').endOf('month');
    
      if (lastDayInCalendar.clone().add(1, 'day').isAfter(queryEnd)) {
        getReferenceRate({
          startDate: lastDayInCalendar.clone().add(1, 'day'),
          endDate: lastDayInCalendar.clone().add(2, 'months').endOf('month'),
        });
      } else {
        getReferenceRate({
          startDate: lastDayInCalendar.clone().add(1, 'day'),
          endDate: queryEnd.format('YYYY-MM-DD'),
        });
      }
      
    
      if (!filteredReferences.length) return;
    
      const maxRefDate = moment(
        Math.max(...filteredReferences.map((ref) => moment(ref.date).valueOf()))
      );
    
      // Comprobar si la última fecha es la misma que en la carga anterior
      if (lastLoadedDate && maxRefDate.isSame(lastLoadedDate, 'day')) {
        setLoadMoreAttempts((prev) => {
          const newAttempts = prev - 1;
          if (newAttempts <= 0) {
            setShowLoadMore(false); // Ocultar el botón cuando se agotan los intentos
          }
          return newAttempts;
        });
        return;
      }
    
      // Si detectamos una nueva fecha, reiniciamos los intentos y actualizamos la última fecha cargada
      setLoadMoreAttempts(1);
      setLastLoadedDate(maxRefDate);

      const extraWeeks = buildWeeksFromRange(
        lastDayInCalendar.clone().add(1, 'day'),
        maxRefDate
      );

      if (extraWeeks.length !== 0) {
        setDays((prev) => [...prev, ...extraWeeks]);
        setShowLoadMore(true);
      }
    },
    [filteredReferences, lastLoadedDate, getReferenceRate]
  );

  return { loadMoreMonths, showLoadMore };
};

export default useLoadMoreMonths;