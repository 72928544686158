import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import styles from "./index.module.scss";

const RefundProgressBar = ({ isRefundable, refundDateLabel, isOutOfRefund }) => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      {/* Etiquetas superiores */}
      <div className={styles.labels}>
        <>
          {isRefundable && (
            <>
              <span className={`${styles.labelFree} ${!isOutOfRefund ? styles.active : ""}`}>
                {intl.formatMessage({ id: 'Reservation.Cancel.Progressbar.Free' })}
              </span>
              <span className={`${styles.labelNonRefundable} ${isOutOfRefund ? styles.active : ""}`}>
                {intl.formatMessage({ id: 'Reservation.Cancel.Progressbar.NonRefundable' })}
              </span>
            </>
          )}
          {!isRefundable && (
            <>
              <span className={`${styles.labelNonRefundable} ${isOutOfRefund ? styles.active : ""} ${styles.isOutOfRefund}`}>
                {intl.formatMessage({ id: 'Reservation.Cancel.Progressbar.NonRefundable' })}
              </span>
            </>
          )}
        </>
      </div>

      {/* Barra de progreso */}
      <div className={styles.progressBar}>
        <>
          {isRefundable && (
            <>
              <div className={`${styles.track1} ${!isOutOfRefund ? styles.green : styles.gray}`} />
              <div className={`${styles.track} ${!isOutOfRefund ? styles.gray : styles.red}`} />
              <div className={styles.divider} />
              <div className={`${styles.thumb} ${!isOutOfRefund ? styles.left : styles.right}`} />
            </>
          )}

          {!isRefundable && (
            <>
              <div className={`${styles.track1} ${styles.red}`} />
              <div className={`${styles.track} ${styles.red}`} />
              
            </>
          )}
        </>
       
      </div>
      {/* Etiquetas inferiores */}
      <div className={styles.dateLabels}>
        <>
          {isRefundable && (
            <>
              <span className={`${styles.labelFree} ${!isOutOfRefund ? styles.left : styles.rightToday}`}>
                {intl.formatMessage({ id: 'Reservation.Cancel.Progressbar.Today' })}
              </span>
              <span className={`${styles.labelNonRefundableEndDate} ${isOutOfRefund ? styles.active : ""}`}>
                {refundDateLabel}
              </span>
            </>
          )}
        </>
        
      </div>
    </div>
  )};

RefundProgressBar.propTypes = {
  isRefundable: PropTypes.bool.isRequired,
  refundDateLabel: PropTypes.string.isRequired,
  isOutOfRefund: PropTypes.bool.isRequired,
};

export default RefundProgressBar;