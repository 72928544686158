import { useState, useEffect } from "react";
import makeRequestToApServer from "../../../services/ApServer/askToApServer";
import { allowedMethods } from "../../../constants/ApServer/apServer";
import routes from "../../../constants/ApServer/apRoutes";

const useFetchCancelInfo = (locator, propertyId) => {
  const [cancelInfo, setCancelInfo] = useState(null);
  const [isRefundable, setIsRefundable] = useState(null);
  const [isOutOfRefund, setIsOutOfRefund] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reasons, setReasons] = useState(null);
  const [totalPaid, setTotalPaid] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    makeRequestToApServer(
      allowedMethods.get,
      routes.bookingsStatus.replace(":id", locator).replace(":propertyId", propertyId),
      undefined,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          setCancelInfo(data?.lastDay);
          setIsRefundable(data?.refundable);
          setIsOutOfRefund(data?.isOutOfRefund);
          setReasons(data?.reasons);
          setTotalPaid(data?.totalPaid);
        }
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  }, [locator, propertyId]);

  return { cancelInfo, isRefundable, isOutOfRefund, isLoading, reasons, totalPaid };
};

export default useFetchCancelInfo;