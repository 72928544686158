import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import initialState from './initialState';
import actionsTypes from './actionsTypes';
import reducer from './reducer';
import apAxios from '../../interceptors/apAxios';

const AuthCheckinStateContext = createContext();
const AuthCheckinDispatchContext = createContext();

const AuthCheckinProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    const accessTokenCheckin = localStorage.getItem('accessTokenCheckin');
    return {
      ...initialState,
      accessTokenCheckin: accessTokenCheckin || null,
    };
  });

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'accessTokenCheckin') {
        const newAccessTokenCheckin = event.newValue;
        dispatch({
          type: actionsTypes.SET_ACCESSTOKEN,
          payload: {
            accessTokenCheckin: newAccessTokenCheckin,
          },
        });
        apAxios.defaults.headers.common.Authorization = `Bearer ${newAccessTokenCheckin}`;
      }
    };
    const handleTokenUpdate = (event) => {
      dispatch({ type: actionsTypes.SET_TOKENS, payload: { ...event.detail } });
    };

    const handleTokenClear = () => {
      dispatch({ type: actionsTypes.LOGOUT });
    };

    window.addEventListener('token-checkin-updated', handleTokenUpdate);
    window.addEventListener('token-checkin-cleared', handleTokenClear);
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('token-checkin-updated', handleTokenUpdate);
      window.removeEventListener('token-checkin-cleared', handleTokenClear);
      window.removeEventListener('storage-checkin', handleStorageChange);
    };
  }, []);

  return (
    <AuthCheckinStateContext.Provider value={state}>
      <AuthCheckinDispatchContext.Provider value={dispatch}>
        {children}
      </AuthCheckinDispatchContext.Provider>
    </AuthCheckinStateContext.Provider>
  );
};

AuthCheckinProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthCheckinProvider, AuthCheckinStateContext, AuthCheckinDispatchContext };
