import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import routes from '../routes/routes';
import { useAuthState } from './Auth/useAuth';
import useRefreshToken from '../utils/useRefreshToken';

const CheckAuthRoute = ({ component: Component, path, location, ...rest }) => {
  const { accessToken } = useAuthState();
  const tokensExpired = useRefreshToken();

  if (tokensExpired) {
    return <Redirect noThrow="true" to={routes.home} />;
  }

  if (accessToken == null && path.includes('checkin')) return <Redirect noThrow="true" to={routes.home} />

  return (
    <>
      {accessToken != null ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...rest} path={path} location={location} />
      ) : (
        <Redirect noThrow="true" to={routes.home} />
      )}
    </>
  );
};

CheckAuthRoute.defaultProps = {
  component: null,
  path: '/',
  location: {},
};

CheckAuthRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  location: PropTypes.shape({
    key: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default CheckAuthRoute;
