import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Button } from 'antd';
import { ReactComponent as KampaohUpconmingTripIcon } from '../../assets/icons/kampaoh-upcoming-trip-icon.svg';
import { ReactComponent as RingIcon } from '../../assets/icons/icon_ring.svg';
import { ReactComponent as PencilIcon } from '../../assets/icons/icon_pencil.svg';
import { ReactComponent as EyeSlash } from '../../assets/icons/eye-slash-solid.svg';
import { LanguageContext } from '../../locale/contexts/Language';
import { clearCheckinTokens } from '../../utils/tokenHelper';
import {
  tripCard,
  tripInfo,
  destination,
  dates,
  iconContainer,
  icon,
  tripButtons,
  checkInButton,
  editButton
} from './index.module.scss';
import ROUTES from '../../routes/routes';

const UpcomingTripCard = ({
  location,
  checkin,
  checkout,
  locator,
  isExpanded,
  onToggle,
  allowCheckinOnline = true,
  isOtaReservation,
}) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(LanguageContext);

  const dateRange = useMemo(() => {
    moment.locale(currentLanguage);
    const formattedCheckin = moment(checkin);
    const formattedCheckout = moment(checkout);

    if (formattedCheckin.year() === formattedCheckout.year()) {
      if (formattedCheckin.month() === formattedCheckout.month()) {
        return intl.formatMessage(
          { id: 'UpcomingTripCard.SameMonthYear' },
          {
            fromDay: formattedCheckin.format('D'),
            toDay: formattedCheckout.format('D'),
            month: formattedCheckout.format('MMMM'),
            year: formattedCheckout.format('YYYY'),
          }
        );
      }
      return intl.formatMessage(
        { id: 'UpcomingTripCard.SameYearDifferentMonth' },
        {
          fromDay: formattedCheckin.format('D'),
          fromMonth: formattedCheckin.format('MMMM'),
          toDay: formattedCheckout.format('D'),
          toMonth: formattedCheckout.format('MMMM'),
          year: formattedCheckout.format('YYYY'),
        }
      );
    }
    return intl.formatMessage(
      { id: 'UpcomingTripCard.DifferentYear' },
      {
        fromDay: formattedCheckin.format('D'),
        fromMonth: formattedCheckin.format('MMMM'),
        fromYear: formattedCheckin.format('YYYY'),
        toDay: formattedCheckout.format('D'),
        toMonth: formattedCheckout.format('MMMM'),
        toYear: formattedCheckout.format('YYYY'),
      }
    );
  }, [checkin, checkout, currentLanguage, intl]);

  // Botón "Check In"
  const handleCheckIn = () => {
    clearCheckinTokens();
    const url = `${ROUTES.bookingsCheckin}?locator=${locator}&checkin=${checkin}&checkout=${checkout}&checkinOnline=true`;
    window.open(url, '_blank');
  };

  // Al hacer clic, avisamos al padre que este "locator" ha sido togglado.
  const handleToggle = () => {
    onToggle(locator);
  };

  return (
    <div
      className={tripCard}
      role="button"
      tabIndex={-1}
      onKeyDown={() => {}}
    >
      {/* Cabecera (destino, fechas) */}
      <div
        className={tripInfo}
        onClick={handleToggle}
        onKeyPress={handleToggle}
        role="button"
        tabIndex={0}
      >
        <h2 className={destination}>
          {intl.formatMessage(
            { id: 'UpcomingTripCard.TripAnnouncement' },
            { location },
          )}
        </h2>
        <p className={dates}>{dateRange}</p>
      </div>

      <div className={iconContainer}>
        <KampaohUpconmingTripIcon alt="Icon" className={icon} />
      </div>

      <div className={tripButtons}>
        {allowCheckinOnline && (
          <Button
            icon={<RingIcon />}
            onClick={handleCheckIn}
            className={checkInButton}
          >
            {intl.formatMessage({ id: 'Checkin.Button' })}
          </Button>
        )}

        {/* Si NO es una reserva OTA, mostramos el botón de editar/ocultar */}
        {!isOtaReservation && (
          <Button
            icon={isExpanded ? <EyeSlash /> : <PencilIcon />}
            onClick={handleToggle}
            className={editButton}
          >
            {intl.formatMessage({
              id: isExpanded
                ? 'Reservation.Hide.Button'
                : 'Reservation.Update.Button'
            })}
          </Button>
        )}
      </div>
    </div>
  );
};

UpcomingTripCard.propTypes = {
  location: PropTypes.string.isRequired,
  locator: PropTypes.string.isRequired,
  checkin: PropTypes.instanceOf(Date).isRequired,
  checkout: PropTypes.instanceOf(Date).isRequired,
  // Nuevo: estos dos vienen del padre
  isExpanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,

  allowCheckinOnline: PropTypes.bool.isRequired,
  isOtaReservation: PropTypes.bool.isRequired,
};

export default UpcomingTripCard;