import React from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Spin } from "antd";
import { useIntl } from "react-intl";
import styles from "../../index.module.scss";

const NonRefundableCancelSection = ({
  noRefundAccepted,
  chargeValueAccepted,
  showCodeModal,
  handleCancelReservation,
  isCancelling,
  dispatch
}) => {
  const intl = useIntl();

  return (
    <div className={styles.contactContainer}>
      <span>{intl.formatMessage({ id: 'Reservation.Cancel.OutOfRefund' })}</span>
      <div className={styles.checkBoxContainer}>
        <Checkbox
          checked={noRefundAccepted}
          onChange={(e) => dispatch({ type: "TOGGLE_NO_REFUND_ACCEPTED", payload: e.target.checked })}
          disabled={showCodeModal}
        >
          {intl.formatMessage({ id: 'Reservation.Cancel.Checkbox.NoRefund' })}
        </Checkbox>
        <Checkbox
          checked={chargeValueAccepted}
          onChange={(e) => dispatch({ type: "TOGGLE_CHARGE_VALUE_ACCEPTED", payload: e.target.checked })}
          disabled={showCodeModal}
        >
          {intl.formatMessage({ id: 'Reservation.Cancel.Checkbox.ChargeValue' })}
        </Checkbox>
      </div>

      {!showCodeModal && (
        <Button
          className={styles.cancelButton}
          disabled={!chargeValueAccepted || !noRefundAccepted || isCancelling}
          onClick={handleCancelReservation}
        >
          {isCancelling ? <Spin size="small" /> : intl.formatMessage({ id: 'Reservation.Cancel.Button.Finish' })}
        </Button>
      )}
    </div>
  );
};

NonRefundableCancelSection.propTypes = {
  noRefundAccepted: PropTypes.bool.isRequired,
  chargeValueAccepted: PropTypes.bool.isRequired,
  showCodeModal: PropTypes.bool.isRequired,
  handleCancelReservation: PropTypes.func.isRequired,
  isCancelling: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default NonRefundableCancelSection;