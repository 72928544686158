const allowedLocales = ['es', 'en', 'pt', 'it', 'fr'];

export const LANGUAGES = [
  { code: "aa", names: { en: "Afar", es: "Afar", pt: "Afar", it: "Afar", fr: "Afar" } },
  { code: "ab", names: { en: "Abkhazian", es: "Abjasio", pt: "Abecásio", it: "Abcaso", fr: "Abkhaze" } },
  { code: "ae", names: { en: "Avestan", es: "Avéstico", pt: "Avéstico", it: "Avestico", fr: "Avestique" } },
  { code: "af", names: { en: "Afrikaans", es: "Afrikáans", pt: "Africâner", it: "Afrikaans", fr: "Afrikaans" } },
  { code: "ak", names: { en: "Akan", es: "Akan", pt: "Akan", it: "Akan", fr: "Akan" } },
  { code: "am", names: { en: "Amharic", es: "Amárico", pt: "Amárico", it: "Amarico", fr: "Amharique" } },
  { code: "an", names: { en: "Aragonese", es: "Aragonés", pt: "Aragonês", it: "Aragonese", fr: "Aragonais" } },
  { code: "ar", names: { en: "Arabic", es: "Árabe", pt: "Árabe", it: "Arabo", fr: "Arabe" } },
  { code: "as", names: { en: "Assamese", es: "Asamés", pt: "Assamês", it: "Assamese", fr: "Assamais" } },
  { code: "av", names: { en: "Avaric", es: "Avárico", pt: "Avárico", it: "Avarico", fr: "Avar" } },
  { code: "ay", names: { en: "Aymara", es: "Aimara", pt: "Aimará", it: "Aymara", fr: "Aymara" } },
  { code: "az", names: { en: "Azerbaijani", es: "Azerí", pt: "Azeri", it: "Azerbaigiano", fr: "Azéri" } },
  { code: "ba", names: { en: "Bashkir", es: "Baskir", pt: "Baskir", it: "Baschiro", fr: "Bachkir" } },
  { code: "be", names: { en: "Belarusian", es: "Bielorruso", pt: "Bielorrusso", it: "Bielorusso", fr: "Biélorusse" } },
  { code: "bg", names: { en: "Bulgarian", es: "Búlgaro", pt: "Búlgaro", it: "Bulgaro", fr: "Bulgare" } },
  { code: "bn", names: { en: "Bengali", es: "Bengalí", pt: "Bengali", it: "Bengalese", fr: "Bengali" } },
  { code: "bo", names: { en: "Tibetan", es: "Tibetano", pt: "Tibetano", it: "Tibetano", fr: "Tibétain" } },
  { code: "br", names: { en: "Breton", es: "Bretón", pt: "Bretão", it: "Bretone", fr: "Breton" } },
  { code: "bs", names: { en: "Bosnian", es: "Bosnio", pt: "Bósnio", it: "Bosniaco", fr: "Bosniaque" } },
  { code: "ca", names: { en: "Catalan", es: "Catalán", pt: "Catalão", it: "Catalano", fr: "Catalan" } },
  { code: "cs", names: { en: "Czech", es: "Checo", pt: "Checo", it: "Ceco", fr: "Tchèque" } },
  { code: "cy", names: { en: "Welsh", es: "Galés", pt: "Galês", it: "Gallese", fr: "Gallois" } },
  { code: "da", names: { en: "Danish", es: "Danés", pt: "Dinamarquês", it: "Danese", fr: "Danois" } },
  { code: "de", names: { en: "German", es: "Alemán", pt: "Alemão", it: "Tedesco", fr: "Allemand" } },
  { code: "dz", names: { en: "Dzongkha", es: "Dzongkha", pt: "Dzongkha", it: "Dzongkha", fr: "Dzongkha" } },
  { code: "el", names: { en: "Greek", es: "Griego", pt: "Grego", it: "Greco", fr: "Grec" } },
  { code: "en", names: { en: "English", es: "Inglés", pt: "Inglês", it: "Inglese", fr: "Anglais" } },
  { code: "eo", names: { en: "Esperanto", es: "Esperanto", pt: "Esperanto", it: "Esperanto", fr: "Espéranto" } },
  { code: "es", names: { en: "Spanish", es: "Español", pt: "Espanhol", it: "Spagnolo", fr: "Espagnol" } },
  { code: "et", names: { en: "Estonian", es: "Estonio", pt: "Estoniano", it: "Estone", fr: "Estonien" } },
  { code: "fa", names: { en: "Persian", es: "Persa", pt: "Persa", it: "Persiano", fr: "Persan" } },
  { code: "fi", names: { en: "Finnish", es: "Finlandés", pt: "Finlandês", it: "Finlandese", fr: "Finnois" } },
  { code: "fr", names: { en: "French", es: "Francés", pt: "Francês", it: "Francese", fr: "Français" } },
  { code: "ga", names: { en: "Irish", es: "Irlandés", pt: "Irlandês", it: "Irlandese", fr: "Irlandais" } },
  { code: "he", names: { en: "Hebrew", es: "Hebreo", pt: "Hebraico", it: "Ebraico", fr: "Hébreu" } },
  { code: "hi", names: { en: "Hindi", es: "Hindi", pt: "Hindi", it: "Hindi", fr: "Hindi" } },
  { code: "hr", names: { en: "Croatian", es: "Croata", pt: "Croata", it: "Croato", fr: "Croate" } },
  { code: "hu", names: { en: "Hungarian", es: "Húngaro", pt: "Húngaro", it: "Ungherese", fr: "Hongrois" } },
  { code: "id", names: { en: "Indonesian", es: "Indonesio", pt: "Indonésio", it: "Indonesiano", fr: "Indonésien" } },
  { code: "it", names: { en: "Italian", es: "Italiano", pt: "Italiano", it: "Italiano", fr: "Italien" } },
  { code: "ja", names: { en: "Japanese", es: "Japonés", pt: "Japonês", it: "Giapponese", fr: "Japonais" } },
  { code: "ko", names: { en: "Korean", es: "Coreano", pt: "Coreano", it: "Coreano", fr: "Coréen" } },
  { code: "nl", names: { en: "Dutch", es: "Holandés", pt: "Holandês", it: "Olandese", fr: "Néerlandais" } },
  { code: "no", names: { en: "Norwegian", es: "Noruego", pt: "Norueguês", it: "Norvegese", fr: "Norvégien" } },
  { code: "pl", names: { en: "Polish", es: "Polaco", pt: "Polonês", it: "Polacco", fr: "Polonais" } },
  { code: "pt", names: { en: "Portuguese", es: "Portugués", pt: "Português", it: "Portoghese", fr: "Portugais" } },
  { code: "ru", names: { en: "Russian", es: "Ruso", pt: "Russo", it: "Russo", fr: "Russe" } },
  { code: "zh", names: { en: "Chinese", es: "Chino", pt: "Chinês", it: "Cinese", fr: "Chinois" } },
];

export default allowedLocales;
