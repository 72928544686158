import { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import AvailabilityContext from '../../../../../../../contexts/AvailabilityContext';
import { LanguageContext } from '../../../../../../../../../locale/contexts/Language';
import { 
  VIRTUALIZER_HEIGHT_DESKTOP,
  VIRTUALIZER_SIZE_DESKTOP,
  VIRTUALIZER_HEIGHT_MOBILE,
  VIRTUALIZER_SIZE_MOBILE,
  VIRTUALIZER_HEIGHT_XS_MOBILE,
  VIRTUALIZER_SIZE_XS_MOBILE,
  VIRTUALIZER_HEIGHT_LG_MOBILE,
  VIRTUALIZER_SIZE_LG_MOBILE,
  VIRTUALIZER_HEIGHT_SM_TABLET,
  VIRTUALIZER_SIZE_SM_TABLET,
  VIRTUALIZER_HEIGHT_LG_TABLET,
  VIRTUALIZER_SIZE_LG_TABLET,
  VIRTUALIZER_HEIGHT_LAPTOP,
  VIRTUALIZER_SIZE_LAPTOP,
  VIRTUALIZER_HEIGHT_LG_DESKTOP,
  VIRTUALIZER_SIZE_LG_DESKTOP,
  buildWeeksFromRange
} from '../utils/calendarUtils';

const useCalendarState = (startDateSelected, endDateSelected, days) => {
  const messagesContainerRef = useRef();
  const { selectedUnit, filteredReferences, setIsLoadingReferenceRates } = useContext(AvailabilityContext);
  const { currentLanguage } = useContext(LanguageContext);

  const [startDate, setStartDate] = useState(startDateSelected);
  const [endDate, setEndDate] = useState(endDateSelected);
  const [startCalendarDate, setStartCalendarDate] = useState(moment().format('YYYY-MM-DD'));
  const [daysArray, setDays] = useState(days);
  const [isSelectingStartDate, setIsSelectingStartDate] = useState(true);
  const [virtualizerHeight, setVirtualizerHeight] = useState(VIRTUALIZER_HEIGHT_DESKTOP);
  const [virtualizerItemSize, setVirtualizerItemSize] = useState(VIRTUALIZER_SIZE_DESKTOP);
  const [referenceRates, setReferenceRates] = useState([]);

  useEffect(() => {
    const updateVirtualizerSizes = () => {
      const width = window.innerWidth;
  
      if (width < 400) {
        // Móviles muy pequeños (ej: iPhone SE)
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_XS_MOBILE);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_XS_MOBILE);
      } else if (width < 600) {
        // Móviles estándar
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_MOBILE);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_MOBILE);
      } else if (width < 768) {
        // Móviles grandes
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_LG_MOBILE);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_LG_MOBILE);
      } else if (width < 900) {
        // Tablets pequeñas
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_SM_TABLET);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_SM_TABLET);
      } else if (width < 1200) {
        // Tablets grandes
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_LG_TABLET);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_LG_TABLET);
      } else if (width < 1440) {
        // Laptops pequeñas
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_LAPTOP);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_LAPTOP);
      } else if (width < 1920) {
        // Escritorios estándar
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_DESKTOP);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_DESKTOP);
      } else {
        // Monitores grandes (1440p o más)
        setVirtualizerHeight(VIRTUALIZER_HEIGHT_LG_DESKTOP);
        setVirtualizerItemSize(VIRTUALIZER_SIZE_LG_DESKTOP);
      }
    };
  
    updateVirtualizerSizes();
    window.addEventListener("resize", updateVirtualizerSizes);
    return () => {
      window.removeEventListener("resize", updateVirtualizerSizes);
    };
  }, []);

  useEffect(() => {
    if (filteredReferences) {
      setReferenceRates(filteredReferences);
      if (filteredReferences.length > 0) {
        setIsLoadingReferenceRates(false);
      }
    }
  }, [selectedUnit, filteredReferences, setIsLoadingReferenceRates]);

  useEffect(() => {
    setStartDate(startDateSelected);
    setEndDate(endDateSelected);
  }, [startDateSelected, endDateSelected]);



  // --------------------------------------------------------------------------
  // Generar el calendario a pintar: desde HOY hasta el último día presente en filteredReferences
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (filteredReferences && filteredReferences.length > 0) {
      const startForCalendar = moment(); // Desde HOY
      const maxRefDate = moment(
        Math.max(...filteredReferences.map((ref) => moment(ref.date).valueOf()))
      );
      const newDaysArray = buildWeeksFromRange(startForCalendar, maxRefDate);
      setDays(newDaysArray);
    }
  }, [filteredReferences]);

  return {
    messagesContainerRef,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startCalendarDate,
    setStartCalendarDate,
    daysArray,
    setDays,
    isSelectingStartDate,
    setIsSelectingStartDate,
    virtualizerHeight,
    virtualizerItemSize,
    referenceRates,
    currentLanguage,
  };
};

export default useCalendarState;