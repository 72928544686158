import { useContext } from 'react';
import moment from 'moment';
import AvailabilityContext from '../../../../../../../contexts/AvailabilityContext';
import { hasReachedTimeLimit } from '../utils/calendarUtils';

const useHandleDayClick = ({
  startCalendarDate,
  setStartCalendarDate,
  setStartDate,
  setStartDateAvailable,
  setEndDate,
  setEndDateAvailable,
  startDate,
  endDate,
  applyRestrictions,
  isSelectingStartDate,
  setIsSelectingStartDate,
  setIsFirstRender,
}) => {
  const { setAreAnyError, resetErrors } = useContext(AvailabilityContext);

  return (day, rate) => {
    if (day.isSameOrBefore(startCalendarDate)) {
      setStartCalendarDate(day.format('YYYY-MM-DD'));
    }
    if (day.isSameOrAfter(moment(), 'day') && hasReachedTimeLimit()) {
      applyRestrictions(day, rate, startDate, endDate);
      resetErrors();
      setAreAnyError(false);
    } else if (day.isAfter(moment(), 'day')) {
      applyRestrictions(day, rate, startDate, endDate);
    } else {
      setAreAnyError(true);
    }
    if (!rate || rate.amountAfterTax <= 0 || !rate.quantityAvailable) {
      return;
    }
    if (isSelectingStartDate) {
      setStartDate(day.clone().add(12, 'hours'));
      setStartDateAvailable(day.clone().add(12, 'hours'));
      setEndDate(null);
      setEndDateAvailable(null);
      setIsSelectingStartDate(false)
      setIsFirstRender(false);
    } else if (startDate && day.isAfter(startDate, 'day')) {
      setEndDate(day.clone().add(12, 'hours'));
      setEndDateAvailable(day.clone().add(12, 'hours'));
      setIsSelectingStartDate(true)
    } else if (startDate && day.isBefore(startDate, 'day')) {
      setStartDate(day.clone().add(12, 'hours'));
      setStartDateAvailable(day.clone().add(12, 'hours'));
      setEndDate(null);
      setEndDateAvailable(null);
      setIsSelectingStartDate(false)
      setIsFirstRender(false);
    }
  };
};

export default useHandleDayClick;