import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, Select } from 'antd';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import FormikForm from './components/FormikForm';
import styles from './index.module.scss';
import { validationSchema } from './validation';
import { useCountries } from '../../../../utils/useCountries';
import { ReactComponent as BabieIcon } from '../../../../assets/icons/icon_babie.svg';
import { ReactComponent as ChildrenIcon } from '../../../../assets/icons/icon_children.svg';
import { ReactComponent as AdultIcon } from '../../../../assets/icons/icon_adult.svg';
import makeRequestToApCheckinServer from '../../../../services/ApServer/askCheckinToApServer';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import routes from '../../../../constants/ApServer/apRoutes';

const { Panel } = Collapse;
const { Option } = Select;

const buildRoute = (route, params) => {
  let finalRoute = route;
  Object.keys(params).forEach((key) => {
    finalRoute = finalRoute.replace(`:${key}`, params[key]);
  });
  return finalRoute;
};

const getGuestIcon = (type) => {
  switch (type) {
    case 'Adult':
      return <AdultIcon />;
    case 'Child':
      return <ChildrenIcon />;
    case 'Other':
      return <BabieIcon />;
    default:
      return <AdultIcon />;
  }
};

const GuestList = ({
  guests,
  onGuestUpdate,
  countries,
  documentTypes,
  familyRelationships,
  genderTypes,
  languages,
  municipalities,
  stateProvs,
}) => {
  const intl = useIntl();
  const [activeKey, setActiveKey] = useState([]);
  const [formError, setFormError] = useState(null);
  const countriesArray = useCountries();

  const sortedCountriesList = useMemo(
    () =>
      countriesArray
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((country) => (
          <Option
            key={country.value}
            value={country.value}
            label={country.label}
          >
            {country.label}
          </Option>
        )),
    [countriesArray]
  );

  const closePanel = () => setActiveKey([]);

  const handleGuestSubmit = async (updatedGuest) => {
    const { guestId, reservationId } = updatedGuest;
    const route = buildRoute(routes.bookingsUpdateGuest, {
      id: reservationId,
      guestId,
    });

    try {
      setFormError(null);
      await makeRequestToApCheckinServer(
        allowedMethods.post,
        route,
        updatedGuest,
        ({ statusCode }) => {
          if (statusCode === 200) {
            closePanel();
          }
        },
        () => {}
      );

      const updatedGuestWithStatus = {
        ...updatedGuest,
        status: updatedGuest.isCheckinOnline,
      };

      onGuestUpdate(updatedGuestWithStatus);
    } catch (error) {
      console.error('Error al enviar datos del huésped:', error);
      setFormError(`${intl.formatMessage({ id: 'Checkin.GuestList.Request.Error' })} ${error?.response?.data?.message}`);
    }
  };

  const hasChildrenOrBabies = guests.some(
    (elm) => elm.role === 'Children' || elm.role === 'Babie'
  );

  return (
    <Collapse
      activeKey={activeKey}
      onChange={setActiveKey}
      accordion
      className={styles.guestList}
      expandIcon={() => null}
    >
      {guests.map((guest) => (
        <Panel
          header={
            <div className={styles.guestHeader}>
              <span className={styles.guestType}>
                {getGuestIcon(guest.role)}
                {!guest.isCheckinOnline
                  ? `${intl.formatMessage({ id: 'Checkin.GuestList.Register' })} ${intl.formatMessage({ id: `Checkin.GuestList.Register.${guest.role}` })} - ${guest.tent}`
                  : `${guest.givenName || ''} ${guest.surName || ''} ${guest.secondSurName || ''}`}
              </span>
              {!guest.isCheckinOnline ? (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  className={styles.pendingButton}
                  onClick={setActiveKey}
                >
                  {intl.formatMessage({ id: 'Checkin.GuestList.AddButton' })}
                </Button>
              ) : (
                <Button
                  icon={<CheckOutlined />}
                  type="default"
                  className={styles.completeButton}
                />
              )}
            </div>
          }
          key={guest.id}
          className={styles.panel}
        >
          <FormikForm
            guest={guest}
            guests={guests}
            onSubmit={handleGuestSubmit}
            validationSchema={validationSchema(intl)}
            sortedCountriesList={sortedCountriesList}
            countries={countries}
            documentTypes={documentTypes}
            familyRelationships={familyRelationships}
            genderTypes={genderTypes}
            languages={languages}
            municipalities={municipalities}
            stateProvs={stateProvs}
            error={formError}
            validate={(values) => validationSchema.validate(values, { context: { hasChildrenOrBabies } })}
          />
        </Panel>
      ))}
    </Collapse>
  );
};

GuestList.propTypes = {
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      givenName: PropTypes.string,
      surName: PropTypes.string,
      secondSurName: PropTypes.string,
      role: PropTypes.oneOf(['Adult', 'Child', 'Other']),
      tent: PropTypes.string,
      isCheckinOnline: PropTypes.bool,
      status: PropTypes.string,
    })
  ).isRequired,
  onGuestUpdate: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string.isRequired,
    })
  ).isRequired,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string.isRequired,
    })
  ).isRequired,
  familyRelationships: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string.isRequired,
    })
  ).isRequired,
  genderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string.isRequired,
    })
  ).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      nameI18n: PropTypes.string.isRequired,
    })
  ).isRequired,
  municipalities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stateProv: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  stateProvs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default GuestList;