import { Form, Select, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import rules from '../validationRules';
import {
  formItemStyle,
  genreFieldWrapper,
  selectDisabled,
  selectStyle,
} from './index.module.scss';

const GenreField = ({ field, disabled, "data-cy": dataCy }) => {
  const intl = useIntl();
  const fieldTitle = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Title`,
    defaultMessage: field,
  });
  const fieldPlaceholder = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Placeholder`,
    defaultMessage: field,
  });

  const fieldLabel = useMemo(
    () => <Typography.Text strong>{fieldTitle}</Typography.Text>,
    [fieldTitle],
  );

  return (
    <div className={genreFieldWrapper}>
      <Form.Item
        className={formItemStyle}
        name={field}
        rules={rules[field]}
        label={fieldLabel}
      >
        <Select
          className={`${selectStyle} ${disabled && selectDisabled}`}
          bordered
          placeholder={fieldPlaceholder}
          disabled={disabled}
          data-cy={dataCy}
        >
          <Select.Option value="M">
            {intl.formatMessage({
              id: 'UserForm.Validation.Genre.Man',
              defaultMessage: 'Masculino',
            })}
          </Select.Option>
          <Select.Option value="F">
            {intl.formatMessage({
              id: 'UserForm.Validation.Genre.Woman',
              defaultMessage: 'Femenino',
            })}
          </Select.Option>
          <Select.Option value="A">
            {intl.formatMessage({
              id: 'UserForm.Validation.Genre.Agender',
              defaultMessage: 'Indeterminado',
            })}
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );
};

GenreField.defaultProps = {
  disabled: false,
  "data-cy": undefined
};
GenreField.propTypes = {
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  "data-cy": PropTypes.string
};

export default GenreField;
