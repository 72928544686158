/* eslint-disable react/forbid-prop-types */
import React, { useRef, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import isMobile from '../../../../../../../../../../utils/isMobile';
// import * as Styles from './index.module.scss';

const CustomTooltip = ({
  daysBetweenCheckInAndCheckOut, containerRef, children, visible, width, startDateRatePlan,
}) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={() => {
        if (startDateRatePlan && startDateRatePlan.minStay <= daysBetweenCheckInAndCheckOut) {
          return intl.formatMessage(
            { id: 'AvailabilityDrawer.AvailabilityCalendar.Night' },
            { count: daysBetweenCheckInAndCheckOut },
          );
        }
        if (startDateRatePlan) {
          return intl.formatMessage(
            { id: 'AvailabilityDrawer.AvailabilityCalendar.MinNight' },
            { count: startDateRatePlan.minStay, br: <br /> },
          );
        }
        return null;
      }}
      destroyTooltipOnHide
      defaultVisible
      visible={visible}
      placement="bottom"
      color="#000000"
      overlayInnerStyle={{
        fontWeight: 700, borderRadius: '10px', textAlign: 'center', maxWidth: `${width}px`, fontSize: `${isMobile() ? '0.6em' : '10px'}`,
      }}
      getPopupContainer={() => containerRef.current}
    >
      {children}
    </Tooltip>
  );
};


const NightTotalNumberTooltip = ({
  children, visible, width, ...props
}) => {

  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef.current) {
      setIsMounted(true);
    }
  }, []);
  
  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      {visible
      && isMounted
        ? (
          <CustomTooltip
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            containerRef={containerRef}
            visible={visible}
            width={width}
          >
            {children}
          </CustomTooltip>
        ) : children}
    </div>
  );
};

NightTotalNumberTooltip.propTypes = {
  startDateRatePlan: PropTypes.shape({
    minStay: PropTypes.number,
  }),
  daysBetweenCheckInAndCheckOut: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  children: PropTypes.element.isRequired,
  width: PropTypes.number,
};

NightTotalNumberTooltip.defaultProps = {
  startDateRatePlan: null,
  visible: false,
  width: undefined,
};

CustomTooltip.propTypes = {
  startDateRatePlan: PropTypes.shape({
    minStay: PropTypes.number,
  }),
  daysBetweenCheckInAndCheckOut: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  children: PropTypes.element.isRequired,
  containerRef: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  width: PropTypes.number,
};

CustomTooltip.defaultProps = {
  startDateRatePlan: null,
  visible: false,
  width: undefined,
};
export default NightTotalNumberTooltip;
